import React from "react";
import { useSelector, useDispatch } from "react-redux";
import HeaderNavigation from "../../../presentational/components/admin-view/header-navigation-admin";
import { logout } from "../../../store/adminState/actions";
import { selectFirstName, selectLastName, selectRole, selectIsAuthenticated } from "../../../store/adminState/selector";

interface Props {}

export interface HeaderNavigationAdminProps extends Props {
  firstName: string;
  lastName: string;
  role: string;
  isAuthenticated: boolean;
  onLogoutClick: () => void;
}

const HeaderNavigationAdminContainer: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const headerNavProps: HeaderNavigationAdminProps = {
    firstName: useSelector(selectFirstName),
    lastName: useSelector(selectLastName),
    role: useSelector(selectRole),
    isAuthenticated: useSelector(selectIsAuthenticated),
    onLogoutClick: () => { dispatch(logout()); }
  };

  return <HeaderNavigation {...headerNavProps} />;
};

export default HeaderNavigationAdminContainer;
