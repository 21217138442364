import React from "react";
import { Flex } from "@rebass/grid";
import styled from "styled-components";

export interface ProminentLinkProps {
  title: string;
  linkTo: string;
  blank?: boolean;
}

export const StyledLink = styled.a`
  display: block;
  position: relative;
  width: 100%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  color: #0077b4;
  background-color: rgb(242, 246, 248);
  padding-bottom: 13px;
  padding-left: 30px;
  padding-right: 70px;
  padding-top: 13px;
  text-decoration: none;
  &:after {
    font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
    font-weight: 700;
    font-style: normal;
    content: "»";
    font-size: 48px;
    position: absolute;
    bottom: 19px;
    right: 40px;
  }
`;

const ProminentLink: React.FC<ProminentLinkProps> = ({ title, linkTo, blank = false }) => {
  return (
    <Flex>
      <StyledLink href={linkTo} target="_blank">
        {title}
      </StyledLink>
    </Flex>
  );
};

export default ProminentLink;
