import React, { useEffect } from "react";
import { RedirectLoginProps } from "../../../container/user-view/redirect-login";
import { REDIRECT_URL } from "../../../config";

import MainLayout from "../../layouts/main-admin-layout";
const RedirectLoginPage: React.FC<RedirectLoginProps> = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = REDIRECT_URL;
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <MainLayout>
      <p> Der Redirect vom Vertragspartnerweb wird gerade überprüft.</p>
    </MainLayout>
  );
};
export default RedirectLoginPage;
