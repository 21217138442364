import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Flex } from "@rebass/grid";
import { Link } from "react-router-dom";
import dateFormat from 'dateformat';

import MainLayout from "../../layouts/main-admin-layout";
import { EditRegistrationFormProps } from "../../../container/admin-view/page-edit-registration-form";
import CreateForm from "../../components/admin-view/create-event/create-form";
import Button from "../../components/elements/button";
import { PageHeadline, NormalTextBlack } from "../../components/identity/styled-text";
import { Box } from "@material-ui/core";

const StyledFormattedDate = styled(Box)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #0077b4;
`;

const EditRegistrationForm: React.FC<EditRegistrationFormProps> = ({
  currentEvent,
  changedFormSuccessfull,
  setChangedFormSuccessfull,
  editForm,
}) => {
  const [newForm, setNewForm] = useState(Array.from(currentEvent?.formular?.felder || []));

  useEffect(() => {
    if (changedFormSuccessfull === true) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      return;
    }
  }, [changedFormSuccessfull]);

  useEffect(() => {
    setChangedFormSuccessfull(false);
  }, [setChangedFormSuccessfull]);

  return (
    <MainLayout>
      <Flex flexDirection="column" width="100%">
        {changedFormSuccessfull ? (
          <Flex flexDirection="column" alignItems="center" justifyContent="center" mt="65px" mb="100px">
            <PageHeadline style={{ marginBottom: "30px", textAlign: "center" }}>
              Das Formular wurde erfolgreich geändert
            </PageHeadline>
            <Link to={{ pathname: `/event-overview` }}>
              <Button buttonText="Zurück zur Übersicht" arrow iconLeft variant="light" />
            </Link>
          </Flex>
        ) : (
          <>
            <PageHeadline style={{ marginTop: 30 }}>Formular bearbeiten</PageHeadline>
            <NormalTextBlack style={{ marginTop: "50px" }}>{currentEvent?.name}</NormalTextBlack>
            <Flex mb="30px">
              <StyledFormattedDate>
                { dateFormat(currentEvent?.startTagStartZeit, 'dd.mm.yyyy',  true) }
                {" "}
                -
                {" "}
                { dateFormat(currentEvent?.startTagEndZeit, 'dd.mm.yyyy',  true) }
              </StyledFormattedDate>
            </Flex>
            <CreateForm fields={newForm || []} setFormFields={setNewForm} />
            <Flex width="100%" justifyContent="flex-end" mt="25px">
              <Link to={{ pathname: "/event-overview" }} style={{ marginRight: "40px" }}>
                <Button buttonText="Abbrechen" variant="light" />
              </Link>
              <Button
                buttonText="Formular speichern"
                variant="dark"
                arrow
                onClick={() => editForm(currentEvent?.veranstaltungId || "", newForm || [])}
              />
            </Flex>
          </>
        )}
      </Flex>
    </MainLayout>
  );
};

export default EditRegistrationForm;
