import eventService from "../../services/events";
import authService from "../../services/auth";
import { AppThunk } from "../configureStore";

import {
  selectPage,
  selectPageSize,
  selectFilterValues,
  selectEvents
} from "./selector";
import { adminSlice } from "./reducer";
import { Event, LoginValues } from "../types";
import { formatISO } from "date-fns";
import { toast } from "react-toastify";
import {
  SortingDirection,
  SortingOptions
} from "../../container/admin-view/page-event-overview-container";

const { actions } = adminSlice;

export const {
  getAllEventsSuccess,
  getAllEventsError,
  setFilterValues,
  setPlace,
  setSpeaker,
  setCurrentId,
  loginStart,
  loginSuccess,
  loginError,
  resetPasswordStart,
  resetPasswordToggle,
  viewArchivedToggle,
  resetPasswordSuccess,
  resetPasswordError,
  getUserDetailsSuccess,
  getUserDetailsError,
  setEvents,
  reset
} = actions;

export const getAllEvents = (): AppThunk => async (dispatch, getState) => {
  try {
    const page = selectPage(getState());
    const size = selectPageSize(getState());
    const filterValues = selectFilterValues(getState());

    const { period, speaker, place, name, zip, type, city, searchKeywords } =
      filterValues;

    const startDatum =
      period.from !== "" && period.from !== null
        ? formatISO(new Date(period.from))
        : "";
    const endDatum =
      period.to !== "" && period.to !== null
        ? formatISO(new Date(period.to))
        : "";

    const response = await eventService.all({
      page,
      size,
      speaker,
      startDatum,
      endDatum,
      place,
      name,
      zip,
      category: type,
      city,
      searchKeywords
    });

    const { data } = response;

    if (data === null) {
      return dispatch(
        getAllEventsSuccess({
          content: [],
          currentPage: 1,
          size: 10,
          total: 0,
          totalPages: 0
        })
      );
    }

    const { currentPage, size: responseSize, total, totalPages } = data;

    const sortedContent = data.content.sort(
      (a: Event, b: Event) =>
        new Date(b.startTagStartZeit).getTime() -
        new Date(a.startTagStartZeit).getTime()
    );

    const finalData = {
      content: sortedContent,
      currentPage,
      size: responseSize,
      total,
      totalPages
    };

    dispatch(getAllEventsSuccess(finalData));
  } catch (err) {
    dispatch(getAllEventsError());
    return toast.error(`Es konnte keine Veranstaltung gefunden werden: ${err}`);
  }
};
export const togglePasswordReset = (): AppThunk => dispatch => {
  dispatch(resetPasswordToggle());
};
export const toggleViewArchived = (): AppThunk => dispatch => {
  dispatch(viewArchivedToggle());
};
export const getEvent =
  (eventId: string): AppThunk =>
  async dispatch => {
    try {
      const response = await eventService.one(eventId);
      const { data } = response;
      dispatch(setCurrentId(data.veranstaltungId));
    } catch (err) {
      return toast.error(
        `Die Veranstaltung konnte nicht gefunden werden: ${err}`
      );
    }
  };

export const sortEvents =
  (sortBy: SortingOptions, sortDirection: SortingDirection): AppThunk =>
  async (dispatch, getState) => {
    try {
      const eventState = selectEvents(getState());
      const events = [...eventState.content];

      const sortedEvents = events.sort((a: Event, b: Event) => {
        if (sortBy === SortingOptions.TITLE) {
          if (
            a[SortingOptions.TITLE].toLowerCase() <
            b[SortingOptions.TITLE].toLowerCase()
          ) {
            return sortDirection === SortingDirection.ASCENDING ? 1 : -1;
          }
          if (
            a[SortingOptions.TITLE].toLowerCase() >
            b[SortingOptions.TITLE].toLowerCase()
          ) {
            return sortDirection === SortingDirection.ASCENDING ? -1 : 1;
          }
          return 0;
        } else if (sortBy === SortingOptions.DATE) {
          return sortDirection === SortingDirection.DESCENDING
            ? new Date(b[SortingOptions.DATE]).getTime() -
                new Date(a[SortingOptions.DATE]).getTime()
            : new Date(a[SortingOptions.DATE]).getTime() -
                new Date(b[SortingOptions.DATE]).getTime();
        } else if (sortBy === SortingOptions.NONE) {
          return (
            new Date(b.startTagStartZeit).getTime() -
            new Date(a.startTagStartZeit).getTime()
          );
        }

        return 0;
      });

      dispatch(setEvents(sortedEvents));
    } catch (err) {
      return toast.error(`Ein Fehler ist aufgetreten: ${err}`);
    }
  };

export const login =
  (params: LoginValues): AppThunk =>
  async dispatch => {
    try {
      dispatch(loginStart());

      const response = await authService.login({
        username: params.username,
        password: params.password
      });

      const { data } = response;

      const userDetails = await authService.getUserDetails(data);
      dispatch(loginSuccess(data));
      dispatch(getUserDetailsSuccess(userDetails.data));
    } catch (err) {
      dispatch(loginError());
      dispatch(getUserDetailsError);
      return toast.error(`Anmeldung fehlgeschlagen`);
    }
  };

export const resetPassword =
  (username: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(resetPasswordStart());

      await authService.resetPassword(username);

      dispatch(resetPasswordSuccess());
      toast.success("Zurücksetzen des Passwortes erfolgreich");
    } catch (err) {
      dispatch(resetPasswordError());
      return toast.error(`Ein Fehler ist aufgetreten: ${err}`);
    }
  };

export const logout = (): AppThunk => async dispatch => {
  try {
    dispatch(reset());
  } catch (err) {
    return toast.error(`Ein Fehler ist aufgetreten: ${err}`);
  }
};
