import React, { useState } from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import Popover from "@material-ui/core/Popover";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";

import Checkbox from "../../components/elements/checkbox";
import CheckIcon from "../../assets/check-icon.svg";
import CloseIcon from "../../assets/close-icon-white.svg";
import MoreIcon from "../identity/more-icon";
import EditIcon from "../../assets/edit.svg";
import DeleteIcon from "../../assets/delete.svg";
import { RegistrationCardProps } from "../../../container/admin-view/components/registration-card-container";
import { SmallText } from "../identity/styled-text";
import { Field } from "../../../store/types";

const CardContainer = styled(Flex)`
  background: rgba(242, 246, 248, 1);
  margin-bottom: 10px;
  border-radius: 4px;
  height: 60px;
  transition: background 0.1s linear;

  &:hover {
    background: rgba(242, 246, 248, 0.5);
    cursor: pointer;
  }
`;

const MenuContainer = styled(Box)`
  border-radius: 4px;
  &:before {
    content: "";
    position: absolute;
    right: -15px;
    top: 50%;
    margin-top: -15px;
    border-top: 15px solid transparent;
    border-right: none;
    border-left: 15px solid #0077b4;
    border-bottom: 15px solid transparent;
  }
`;

const MenuIcon = styled.img`
  margin-right: 15px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledIconButton = withStyles(() => ({
  root: {
    padding: "10px 18px",
    "&:hover": {
      backgroundColor: "#00487A",
    },
    "&:hover > span > svg > circle": {
      fill: "white",
    },
  },
}))(IconButton);

const StyledPopover = withStyles(() => ({
  root: {
    "& .MuiPaper-root": {
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.16)",
      overflow: "visible",
    },
  },
}))(Popover);

const MenuText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 30px;
  color: #ffffff;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const RegistrationCard: React.FC<RegistrationCardProps> = ({
  participant,
  selected,
  addParticipant,
  removeParticipant,
  onDeleteParticipant,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const companyFiltered: Field[] | undefined = participant?.zusaetzlicheFelder?.filter((feld) => feld.name === "Firma");
  const company: string = companyFiltered && companyFiltered.length > 0 ? companyFiltered[0].information : "-";

  return (
    <CardContainer
      padding="12px 30px 12px 10px"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      onClick={() => {
        if (!open) {
          selected ? removeParticipant() : addParticipant();
        } else {
          handleClose();
        }
      }}
    >
      <Flex alignItems="center">
        <Box mr="10px">
          <Checkbox style={{ width: 20, height: 18 }} checked={selected} />
        </Box>
        <SmallText style={{ width: "150px" }}>
          <strong>
            {participant.vorname} {participant.nachname}
          </strong>
        </SmallText>
        <SmallText style={{ width: "150px", marginLeft: "30px" }}>{company}</SmallText>
        <SmallText style={{ width: "150px", marginLeft: "30px" }}>{participant.email}</SmallText>
      </Flex>
      <Flex alignItems="center" width="13%" justifyContent="space-between">
        <Flex
          alignItems="center"
          justifyContent="center"
          backgroundColor={participant.anmeldestatus === "ANGEMELDET" ? "#00B200" : "#E51717"}
          width="28px"
          height="28px"
          style={{ borderRadius: "2px" }}
        >
          <img
            style={{
              width: "14px",
              marginBottom: participant.anmeldestatus === "ANGEMELDET" ? "3px" : "0px",
            }}
            src={participant.anmeldestatus === "ANGEMELDET" ? CheckIcon : CloseIcon}
            alt="status-icon"
          />
        </Flex>
        <Box>
          <StyledIconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
            <MoreIcon />
          </StyledIconButton>
          <StyledPopover
            id="popover-menu"
            open={open}
            anchorEl={anchorEl}
            className="popover_class"
            onClose={handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
          >
            <MenuContainer width="350px" height="50px" backgroundColor="#0077B4" padding="10px 30px">
              <Flex width="90%" height="100%" justifyContent="space-between" alignItems="center">
                <Flex flexDirection="column">
                  <Flex>
                    <MenuIcon src={EditIcon} alt="edit-icon" />
                    <StyledLink
                      to={{
                        pathname: `/${participant.veranstaltungId}/${participant.anmeldungId}/edit-participant`,
                      }}
                    >
                      <MenuText>Teilnehmer bearbeiten</MenuText>
                    </StyledLink>
                  </Flex>
                </Flex>
                <Flex flexDirection="column">
                  <Flex>
                    <MenuIcon src={DeleteIcon} alt="delete-icon" />
                    <MenuText
                      onClick={() => {
                        addParticipant();
                        onDeleteParticipant();
                        handleClose();
                      }}
                    >
                      Löschen
                    </MenuText>
                  </Flex>
                </Flex>
              </Flex>
            </MenuContainer>
          </StyledPopover>
        </Box>
      </Flex>
    </CardContainer>
  );
};

export default RegistrationCard;
