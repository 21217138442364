import { createSlice } from "@reduxjs/toolkit";
import { NewEventState } from "../../types/index";

const initialState: NewEventState = {
  createEventSuccessfull: false,
  eventTypes: [],
  newEventValues: {
    name: "",
    referent: "",
    bild: [
      {
        size: 0,
        fileName: "",
        type: "",
        base64Content: "",
        preview: ""
      }
    ],
    kurzbeschreibung: "",
    beschreibung: "",
    ort: {
      gebaeudename: "",
      postleitzahl: "",
      strasse: "",
      ortsname: "",
      zusatzinfo: "",
    },
    veranstaltungsTag: {
      datum: "",
      startZeit: "BITTE_WÄHLEN",
      endZeit: "BITTE_WÄHLEN"
    },
    zusaetzlicheTage: [],
    formular: {
      felder: [
        {
          args: ["HERR", "FRAU", "DIVERS"],
          name: "Anrede",
          typ: "EINZELNE_AUSWAHL",
          pflichtfeld: false
        },
        { name: "Vorname", typ: "TEXTLINIE", pflichtfeld: true },
        { name: "Nachname", typ: "TEXTLINIE", pflichtfeld: true },
        { name: "E-Mail", typ: "TEXTLINIE", pflichtfeld: true },
        { name: "Straße", typ: "TEXTLINIE", pflichtfeld: false },
        { name: "Postleitzahl", typ: "NUMMER", pflichtfeld: false },
        { name: "Ort", typ: "TEXTLINIE", pflichtfeld: false }
      ]
    },
    anmeldeschluss: "",
    typ: "",
    suchbegriffe: [],
    maxTeilnehmerAnzahl: "",
    anmeldeformular: true,
    mitFotoUpload: false
  }
};

export const createEventSlice = createSlice({
  name: "createEvent",
  initialState,
  reducers: {
    setDescriptionValues(state, action) {
      state.newEventValues.name = action.payload.name;
      state.newEventValues.kurzbeschreibung = action.payload.shortDescription;
      state.newEventValues.referent = action.payload.speaker;
    },
    setDescription(state, action) {
      state.newEventValues.beschreibung = action.payload;
    },
    setNewEventImage(state, action) {
      state.newEventValues.bild = action.payload;
    },
    setLocationData(state, action) {
      state.newEventValues.ort.gebaeudename = action.payload.name;
      state.newEventValues.ort.postleitzahl = action.payload.zip;
      state.newEventValues.ort.strasse = action.payload.street;
      state.newEventValues.ort.ortsname = action.payload.place;
      state.newEventValues.ort.zusatzinfo = action.payload.additionalInfo;
    },
    setDateDetailsData(state, action) {
      state.newEventValues.anmeldeschluss = action.payload.registrationDeadline;
      state.newEventValues.typ = action.payload.eventType;
      state.newEventValues.maxTeilnehmerAnzahl =
        action.payload.maxNumberOfParticipants;
      state.newEventValues.anmeldeformular = action.payload.registrationForm;
      state.newEventValues.veranstaltungsTag = action.payload.eventDay;
      state.newEventValues.zusaetzlicheTage = action.payload.additionalDays;
      state.newEventValues.mitFotoUpload = action.payload.forceImageUpload;
    },
    addNewEventDay(state, action) {
      state.newEventValues.zusaetzlicheTage = action.payload;
    },
    removeEventDay(state, action) {
      state.newEventValues.zusaetzlicheTage = action.payload;
    },
    setNewEventsDate(state, action) {
      state.newEventValues.veranstaltungsTag = action.payload;
    },
    setNewEventDateDetailsSearchKeywords(state, action) {
      state.newEventValues.suchbegriffe = action.payload;
    },
    setNewEventsFormFields(state, action) {
      state.newEventValues.formular.felder = action.payload;
    },
    setNewEventsEventDay(state, action) {
      state.newEventValues.veranstaltungsTag.datum = action.payload;
    },
    setNewEventsAdditionalDays(state, action) {
      state.newEventValues.zusaetzlicheTage = action.payload;
    },
    setNewEventsRegistrationDeadline(state, action) {
      state.newEventValues.anmeldeschluss = action.payload;
    },
    syncNewEventValues(state, action) {
      state.newEventValues = action.payload;
    },
    setCreateEventSuccessfull(state, action) {
      state.createEventSuccessfull = action.payload;
    },
    setEventTypes(state, action) {
      state.eventTypes = action.payload;
    },
    reset() {
      return initialState;
    }
  }
});

const { reducer } = createEventSlice;

export default reducer;
