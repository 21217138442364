export const app_stage = process.env.REACT_APP_ENV;

export const REDIRECT_URL =
  app_stage === "production"
    ? "https://www.ewe-netz.de/vertragspartner/anmeldung?returnUrl=https://veranstaltungen.ewe-netz.de/token/"
    : "http://vpw.cmntk.ewenet.ewe.de/vertragspartner/anmeldung?returnUrl=https://veranstaltungen-stage.ewe-netz.de/token/";

export const LEGAL = {
  imprint: "https://www.ewe-netz.de/meta/impressum",
  contact: "https://www.ewe-netz.de/vertragspartner/meta/kontakt",
  privacy: "https://www.ewe-netz.de/meta/datenschutz",
};
