import React, { useState } from "react";
import { useDropzone, FileWithPath } from "react-dropzone";
import { Flex } from "@rebass/grid";
import styled from "styled-components";

import CloseIcon from "../identity/close-icon";
import handleFileSelect from "../../../utils/handle-file-select";
import { ProcessedImage } from "../../../store/types";
import { NormalText as DropzoneHeadline, SmallText, ErrorText, LabelText } from "../identity/styled-text";

const Container = styled(Flex)`
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  height: 100%;
`;

const DropzoneContainer = styled(Flex)`
  background: linear-gradient(0deg, rgba(0, 72, 122, 0.1), rgba(0, 72, 122, 0.1)), #ffffff;
  border: 2px dashed rgba(0, 119, 180, 0.8);
  box-sizing: border-box;
  border-radius: 2px;
  height: 100%;
  width: 60%;
  justify-content: space-between;
  align-items: center;
`;

const StyledPreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

interface FileUploadProps {
  setImage: (values: ProcessedImage[]) => void;
  image: ProcessedImage[];
  setFieldValue: (fieldName: string, value: string) => void;
  error?: string;
}

const FileUpload: React.FC<FileUploadProps> = ({ setImage, image, setFieldValue, error }) => {
  const [errorType, setErrorType] = useState("");

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "image/png, image/jpeg",
    onDrop: async (acceptedFiles: FileWithPath[]) => {
      const newFile: ProcessedImage[] = await handleFileSelect(acceptedFiles);

      newFile.map((file: ProcessedImage) => {
        if (file.size > 500000) {
          return setErrorType("Das ausgewählte Bild ist zu groß");
        }
        setErrorType("");

        setFieldValue("descriptionValues.image", file.base64Content);
        return setImage([file]);
      });
    },
    noClick: true,
    noKeyboard: true
  });

  return (
    <Flex flexDirection="column" height="100%" width="100%">
      <LabelText>Bild*</LabelText>
      <Container
        className="container"
        style={{ border: error ? "1px solid rgb(247,96,96)" : "1px solid rgba(0, 119, 180, 0.8)" }}
      >
        <DropzoneContainer
          {...getRootProps({ className: "dropzone" })}
          flexDirection="column"
          padding={image.length > 0 && image[0].size > 0 ? "" : "15px 10px"}
        >
          <input {...getInputProps()} />
          {image.length > 0 && image[0].size > 0 ? (
            image.map((file: ProcessedImage, index: number) => (
              <Flex
                justifyContent="center"
                alignItems="center"
                height="100%"
                key={index}
                style={{ position: "relative", maxWidth: 225, maxHeight: 120 }}
              >
                <CloseIcon
                  onClick={() => {
                    setImage([{ size: 0, fileName: "", type: "", base64Content: "", preview: "" }]);
                    setFieldValue("descriptionValues.image", "");
                  }}
                />
                <StyledPreviewImage src={file.preview} alt="img-preview" />
              </Flex>
            ))
          ) : (
            <>
              <DropzoneHeadline>Drag&Drop</DropzoneHeadline>
              <SmallText style={{ textAlign: "center" }}>Bild hier hereinziehen oder</SmallText>
              <SmallText
                onClick={open}
                style={{ textDecoration: "underline", color: "#0077B4", fontWeight: 600, cursor: "pointer" }}
              >
                Bild auswählen
              </SmallText>
            </>
          )}
          {errorType ? <ErrorText>{errorType}</ErrorText> : ""}
        </DropzoneContainer>
        <Flex alignItems="center" justifyContent="center" flexDirection="column" width="40%" pl="10px">
          <SmallText style={{ color: "#0077B4", textAlign: "center" }}>Nur JPG oder PNG möglich (500kb max)</SmallText>
        </Flex>
      </Container>
      {error && <ErrorText style={{ marginTop: "4px", textAlign: "center" }}>{error}</ErrorText>}
    </Flex>
  );
};

export default FileUpload;
