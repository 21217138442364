import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import EventCard from "../../../presentational/components/admin-view/event-card";
import {
  eventActionController,
  addToSelectedEvents,
  removeFromSelectedEvents
} from "../../../store/adminState/edit-event/actions";
import { selectIsEventSelected } from "../../../store/adminState/edit-event/selector";
import { Event } from "../../../store/types";

interface Props {
  eventValues: Event;
  allEventsSelected: boolean;
}

export interface EventCardProps extends Props {
  addToSelectedEvents: (eventId: string) => void;
  removeFromSelectedEvents: (eventId: string) => void;
  isEventSelected: boolean;
  eventActionController: (eventIds: string[], action: string) => void;
}

const EventCardContainer: React.FC<Props> = ({ eventValues, allEventsSelected }) => {
  const dispatch = useDispatch();

  const isEventSelected = useSelector(selectIsEventSelected(eventValues.veranstaltungId));

  const eventCardProps: EventCardProps = {
    addToSelectedEvents: useCallback((eventId: string) => dispatch(addToSelectedEvents(eventId)), [dispatch]),
    removeFromSelectedEvents: useCallback((eventId: string) => dispatch(removeFromSelectedEvents(eventId)), [dispatch]),
    eventValues,
    allEventsSelected,
    isEventSelected: isEventSelected,
    eventActionController: useCallback(
      (eventIds: string[], action: string) => dispatch(eventActionController(eventIds, action)),
      [dispatch]
    )
  };

  return <EventCard {...eventCardProps} />;
};

export default EventCardContainer;
