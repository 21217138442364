import React from "react";
import styled from "styled-components";

const ArrowContainer = styled.div`
  position: absolute;
  right: 20px;
  pointer-events: none;
`;

export interface SelectArrowProps {}

const SelectArrow: React.FC<SelectArrowProps> = () => {
  return (
    <ArrowContainer>
      <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L8 8L15 1" stroke="#0077B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </ArrowContainer>
  );
};

export default SelectArrow;
