import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import { FormikErrors } from "formik";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    color: "#3392C3",
  },
});

export interface StyledRadioProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value?: string;
  checked?: boolean;
  style?: any;
  inputProps?: any;
  readOnly?: boolean;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
}

const StyledRadio: React.FC<StyledRadioProps> = ({ onChange, value, name, readOnly, style, checked }) => {
  const classes = useStyles();

  return (
    <Radio
      color="default"
      className={classes.root}
      onChange={onChange}
      checked={checked}
      readOnly={readOnly}
      value={value}
      name={name}
      inputProps={{ "aria-label": name }}
      style={style}
    />
  );
};

export default StyledRadio;
