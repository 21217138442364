import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import RegistrationCard from "../../../presentational/components/admin-view/registration-card";
import {
  selectIsParticipantSelected,
  selectIsParticipantFromWaitingListSelected,
} from "../../../store/adminState/edit-participants/selector";
import {
  addToSelectedParticipants,
  removeFromSelectedParticipants,
  addToSelectedFromWaitingList,
  removeSelectedFromWaitingList,
} from "../../../store/adminState/edit-participants/actions";
import { Participant } from "../../../store/types";

interface Props {
  participant: Participant;
  onWaitingList?: boolean;
  selected: boolean;
  onDeleteParticipant: () => void;
}

export interface RegistrationCardProps extends Props {
  isParticipantSelected: boolean;
  addParticipant: () => void;
  removeParticipant: () => void;
  onDeleteParticipant: () => void;
}

const RegistrationCardContainer: React.FC<Props> = ({ participant, selected, onWaitingList, onDeleteParticipant }) => {
  const dispatch = useDispatch();

  const isParticipantSelected = useSelector(
    onWaitingList
      ? selectIsParticipantFromWaitingListSelected(participant.anmeldungId)
      : selectIsParticipantSelected(participant.anmeldungId)
  );

  const addParticipant = useCallback(() => {
    onWaitingList
      ? dispatch(addToSelectedFromWaitingList(participant.anmeldungId))
      : dispatch(addToSelectedParticipants(participant.anmeldungId));
  }, [onWaitingList, dispatch, participant.anmeldungId]);

  const removeParticipant = useCallback(() => {
    onWaitingList
      ? dispatch(removeSelectedFromWaitingList(participant.anmeldungId))
      : dispatch(removeFromSelectedParticipants(participant.anmeldungId));
  }, [onWaitingList, dispatch, participant.anmeldungId]);

  const registrationCardProps: RegistrationCardProps = {
    participant,
    selected,
    onWaitingList: onWaitingList || false,
    isParticipantSelected,
    addParticipant,
    removeParticipant,
    onDeleteParticipant,
  };

  return <RegistrationCard {...registrationCardProps} />;
};

export default RegistrationCardContainer;
