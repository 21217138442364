import React, { useState, ChangeEvent } from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import { MenuItem } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from "react-beautiful-dnd";
import Popover from "@material-ui/core/Popover";
import { withStyles } from "@material-ui/core/styles";

import Input from "../../elements/input";
import Button from "../../elements/button";
import Select from "../../elements/select";
import SelectOptions from "../../elements/select-options";
import Checkbox from "../../elements/checkbox";
import DraggableIcon from "../../../assets/burger-menu.svg";
import RemoveIcon from "../../../assets/close-no-background.svg";
import {
  Felder,
  CreateFormValues,
  FormularFeld
} from "../../../../store/types";
import {
  Heading,
  NormalText,
  InputText,
  LabelText
} from "../../identity/styled-text";
import FormPreview from "./form-preview";

const NewFieldContainer = styled(Flex)`
  background: linear-gradient(
      0deg,
      rgba(0, 119, 180, 0.05),
      rgba(0, 119, 180, 0.05)
    ),
    #ffffff;
  mix-blend-mode: normal;
  border: 1px solid rgba(0, 119, 180, 0.8);
  box-sizing: border-box;
  border-radius: 4px;
`;

const Field = styled(Flex)`
  background: #ffffff;
  border: 1px dashed rgba(0, 119, 180, 0.4);
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 12px 15px;
  position: relative;
`;

const StyledPopover = withStyles(() => ({
  root: {
    "& .MuiPaper-root": {
      boxShadow: "none",
      backgroundColor: "transparent",
      overflow: "scroll",
      position: "unset",
      maxWidth: "unset"
    },
    backgroundColor: "rgba(0, 0, 0, 0.51)"
  }
}))(Popover);

export interface CreateFormProps {
  fields: Felder[];
  setFormFields: (values: Felder[]) => void;
}

const CreateForm: React.FC<CreateFormProps> = ({ fields, setFormFields }) => {
  const [designation, setDesignation] = useState("");
  const [type, setType] = useState("BITTE_WÄHLEN");
  const [selectOptions, setSelectOptions] = useState([
    "Option 01",
    "Option 02"
  ]);
  const [required, setRequired] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  const handleClickPreview = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePreview = () => {
    setAnchorEl(null);
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newFields = [...fields];
    newFields.splice(source.index, 1);
    newFields.splice(destination.index, 0, fields[Number(draggableId)]);

    setFormFields(newFields);
  };

  const removeField = (field: Felder) => {
    const newFields = [...fields];
    const fieldIndex = newFields.findIndex(
      (formField: Felder) => field.name === formField.name
    );
    newFields.splice(fieldIndex, 1);

    setFormFields(newFields);
  };

  const addField = (fieldValues: CreateFormValues) => {
    let newField: FormularFeld = {
      name: fieldValues.designation,
      typ: fieldValues.type,
      pflichtfeld: fieldValues.required
    };

    if (fieldValues.args) {
      newField.args = fieldValues.args;
    }

    const newFields = [...fields];
    newFields.push(newField);

    setDesignation("");
    setSelectOptions(["Option 01", "Option 02"]);
    setType("BITTE_WÄHLEN");
    setRequired(false);

    setFormFields(newFields);
  };

  return (
    <Flex
      width="100%"
      backgroundColor="#F2F6F8"
      padding="20px 30px"
      flexDirection="column"
      mt="15px"
      style={{ borderRadius: 4 }}
    >
      <Heading>Anmeldeformular Aufbau</Heading>
      <Flex width="100%" mt="30px" justifyContent="space-between">
        <Flex flexDirection="column" width="47.5%">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"testid"}>
              {provided => (
                <Flex
                  flexDirection="column"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {fields.length > 0 &&
                    fields.map((field: Felder, index: number) => (
                      <Draggable
                        draggableId={index.toString()}
                        index={index}
                        key={index}
                      >
                        {provided => (
                          <Field
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Flex alignItems="center">
                              <img
                                src={DraggableIcon}
                                alt="draggable-icon"
                                style={{ marginRight: "20px" }}
                              />
                              <InputText>
                                {field.name}
                                {field.pflichtfeld ? "*" : ""}
                              </InputText>
                            </Flex>
                            {field.name !== "Vorname" &&
                              field.name !== "Nachname" &&
                              field.name !== "E-Mail" && (
                                <img
                                  src={RemoveIcon}
                                  style={{
                                    width: "12px",
                                    height: "12px",
                                    padding: "12px 15px",
                                    position: "absolute",
                                    right: 0
                                  }}
                                  alt="remove-icon"
                                  onClick={() => removeField(field)}
                                />
                              )}
                          </Field>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </Flex>
              )}
            </Droppable>
          </DragDropContext>
        </Flex>
        <Flex flexDirection="column" width="47.5%">
          <NewFieldContainer
            flexDirection="column"
            width="100%"
            padding="15px 25px"
          >
            <NormalText>Neues Feld</NormalText>
            <Box mt="25px">
              <Input
                label="Bezeichnung*"
                onChange={(e: ChangeEvent<{ value: unknown }>) =>
                  setDesignation(e.target.value as string)
                }
                value={designation}
              />
            </Box>
            <Box mt="25px">
              <Select
                label="Feldtyp*"
                onChange={(e: ChangeEvent<{ value: unknown }>) =>
                  setType(e.target.value as string)
                }
                value={type}
              >
                <MenuItem value="BITTE_WÄHLEN">Bitte wählen</MenuItem>
                <MenuItem value="TEXTLINIE">Textlinie</MenuItem>
                <MenuItem value="TEXTFELD">Textfeld</MenuItem>
                <MenuItem value="KONTROLLKAESTCHEN">Kontrollkästchen</MenuItem>
                <MenuItem value="EINZELNE_AUSWAHL">Einzelne Auswahl</MenuItem>
                <MenuItem value="MEHRERE_AUSWAHLEN">Mehrere Auswahlen</MenuItem>
                <MenuItem value="NUMMER">Nummer</MenuItem>
                <MenuItem value="DATUM">Datum</MenuItem>
              </Select>
            </Box>
            {type === "EINZELNE_AUSWAHL" || type === "MEHRERE_AUSWAHLEN" ? (
              <SelectOptions
                values={selectOptions}
                setSelectOptions={setSelectOptions}
              />
            ) : (
              ""
            )}
            <Box mt="30px">
              <FormControlLabel
                onChange={() => setRequired(!required)}
                value={required}
                checked={required}
                control={<Checkbox style={{ width: 30, height: 28 }} />}
                label={
                  <LabelText style={{ marginBottom: "0px" }}>
                    Soll dieses Feld ein Pflichtfeld sein?
                  </LabelText>
                }
              />
            </Box>
          </NewFieldContainer>
          <Flex width="80%" justifyContent="space-between" mt="30px">
            <Button
              buttonText="Feld hinzufügen"
              arrow
              iconLeft
              variant="dark"
              onClick={() => {
                if (designation !== "" && type !== "BITTE_WÄHLEN") {
                  if (
                    type === "EINZELNE_AUSWAHL" ||
                    type === "MEHRERE_AUSWAHLEN"
                  ) {
                    addField({
                      designation,
                      type,
                      required,
                      args: selectOptions
                    });
                  } else {
                    addField({ designation, type, required });
                  }
                } else {
                  return;
                }
              }}
            />
            <Button
              buttonText="Vorschau"
              variant="light"
              onButtonClick={handleClickPreview}
            />
            <StyledPopover
              id="searche-menu"
              open={open}
              anchorEl={anchorEl}
              className="popover_class"
              onClose={handleClosePreview}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <FormPreview felder={fields} onClose={handleClosePreview} />
            </StyledPopover>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CreateForm;
