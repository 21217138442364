import React from "react";

export interface FileIconProps {}

const FileIcon: React.FC<FileIconProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 9H4V11H12V9Z" fill="white" />
      <path d="M10 13H4V15H10V13Z" fill="white" />
      <path
        d="M16 6V12H18V4.586L13.414 0H2C0.897 0 0 0.898 0 2V20C0 21.103 0.897 22 2 22H12V20H2V2H12V6H16Z"
        fill="white"
      />
      <path
        d="M22.293 17.293L20 19.586V14H18V19.586L15.707 17.293L14.293 18.707L19 23.414L23.707 18.707L22.293 17.293Z"
        fill="white"
      />
    </svg>
  );
};

export default FileIcon;
