import { createSlice } from "@reduxjs/toolkit";
import { EditParticipantsState } from "../../types/index";

const initialState: EditParticipantsState = {
  selectedParticipants: [],
  selectedFromWaitingList: [],
  signupSuccessfull: false,
  reassignParticipantsSuccess: false,
  participants: []
};

export const editParticipantsSlice = createSlice({
  name: "editParticipants",
  initialState,
  reducers: {
    getParticipantsSuccess(state, action) {
      state.participants = action.payload;
    },
    editSelectedParticipants(state, action) {
      state.selectedParticipants = action.payload;
    },
    editSelectedFromWaitingList(state, action) {
      state.selectedFromWaitingList = action.payload;
    },
    signupForEventSuccess(state) {
      state.signupSuccessfull = true;
    },
    setSignupSuccessfull(state, action) {
      state.signupSuccessfull = action.payload;
    },
    reset() {
      return initialState;
    }
  }
});

const { reducer } = editParticipantsSlice;

export default reducer;
