import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../../configureStore";
import { CreateEventValues } from "../../types";

export const selectNewEventsSearchKeywords = (state: AppState) =>
  state?.adminState?.createEventState?.newEventValues?.suchbegriffe;
export const selectEventAdditionalDays = (state: AppState) =>
  state?.adminState?.createEventState?.newEventValues?.zusaetzlicheTage;
export const selectNewEventsImage = (state: AppState) => state?.adminState?.createEventState?.newEventValues?.bild;
export const selectNewEventsFields = (state: AppState) =>
  state?.adminState?.createEventState?.newEventValues?.formular?.felder;
export const selectNewEventValues = (state: AppState) => state?.adminState?.createEventState?.newEventValues;
export const selectNewEventsDescription = (state: AppState) =>
  state?.adminState?.createEventState?.newEventValues?.beschreibung;
export const selectCreateEventSuccessfull = (state: AppState) =>
  state?.adminState?.createEventState?.createEventSuccessfull;
export const selectEventTypes = (state: AppState) => state?.adminState?.createEventState?.eventTypes;

export const selectCreateEventInitialValues = createSelector(selectNewEventValues, (eventValues: CreateEventValues) => {
  if (eventValues) {
    const initialValues = {
      descriptionValues: {
        name: eventValues.name,
        speaker: eventValues.referent,
        shortDescription: eventValues.kurzbeschreibung,
        image: eventValues.bild[0].preview,
      },
      eventLocationValues: {
        name: eventValues.ort.gebaeudename,
        zip: eventValues.ort.postleitzahl,
        street: eventValues.ort.strasse,
        place: eventValues.ort.ortsname,
        additionalInfo: eventValues.ort.zusatzinfo,
      },
      dateDetailsValues: {
        eventDay: eventValues.veranstaltungsTag,
        additionalDays: eventValues.zusaetzlicheTage,
        registrationDeadline: eventValues.anmeldeschluss,
        eventType: eventValues.typ,
        maxNumberOfParticipants: eventValues.maxTeilnehmerAnzahl,
        registrationForm: eventValues.anmeldeformular,
        forceImageUpload: eventValues.mitFotoUpload,
      },
    };

    return initialValues;
  }

  const initialValues = {
    descriptionValues: {
      name: "",
      speaker: "",
      shortDescription: "",
      image: "",
    },
    eventLocationValues: {
      name: "",
      zip: "",
      street: "",
      place: "",
      additionalInfo: "",
    },
    dateDetailsValues: {
      eventDay: { datum: "", startZeit: "", endZeit: "" },
      additionalDays: [{ datum: "", startZeit: "", endZeit: "" }],
      registrationDeadline: "",
      eventType: "",
      maxNumberOfParticipants: "",
      registrationForm: true,
      forceImageUpload: true,
    },
    createFormValues: {
      designation: "",
      type: "",
      required: false,
    },
  };

  return initialValues;
});
