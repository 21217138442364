import eventService from "../../../services/events";
import { AppThunk } from "../../configureStore";
import { omit } from "lodash";

import { selectSelectedParticipants, selectSelectedFromWaitingList } from "./selector";
import { editParticipantsSlice } from "./reducer";
import { FormValues, EditParticipant, ReassignParticipantsProps } from "../../types";
import { toast } from "react-toastify";

const { actions } = editParticipantsSlice;

export const {
  getParticipantsSuccess,
  editSelectedParticipants,
  editSelectedFromWaitingList,
  signupForEventSuccess,
  setSignupSuccessfull,
} = actions;

export const addToSelectedParticipants =
  (participantId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const selectedParticipants = selectSelectedParticipants(getState());
      const newSelectedParticipants = [...selectedParticipants];
      if (newSelectedParticipants.length === 0) {
        dispatch(editSelectedParticipants([participantId]));
      } else {
        const duplicate = newSelectedParticipants.some((id) => (id === participantId ? true : false));

        if (duplicate) {
          return;
        } else {
          newSelectedParticipants.push(participantId);
          dispatch(editSelectedParticipants(newSelectedParticipants));
        }
      }
    } catch (err) {
      return toast.error(`Ein Fehler ist aufgetreten: ${err}`);
    }
  };

export const clearSelectedParticipants = (): AppThunk => (dispatch) => {
  dispatch(editSelectedParticipants([]));
};

export const clearSelectedWaitingParticipants = (): AppThunk => (dispatch) => {
  dispatch(editSelectedFromWaitingList([]));
};

export const removeFromSelectedParticipants =
  (participantId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const selectedParticipants = selectSelectedParticipants(getState());
      const newSelectedParticipants = [...selectedParticipants];

      const index = newSelectedParticipants
        .map((id) => {
          return id;
        })
        .indexOf(participantId);

      if (index !== -1) {
        newSelectedParticipants.splice(index, 1);

        dispatch(editSelectedParticipants(newSelectedParticipants));
      }
    } catch (err) {
      return toast.error(`Ein Fehler ist aufgetreten: ${err}`);
    }
  };
export const addToSelectedFromWaitingList =
  (participantId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const selectedFromWaitingList = selectSelectedFromWaitingList(getState());
      const newSelectedFromWaitingList = [...selectedFromWaitingList];

      if (newSelectedFromWaitingList.length === 0) {
        dispatch(editSelectedFromWaitingList([participantId]));
      } else {
        const duplicate = newSelectedFromWaitingList.some((id) => (id === participantId ? true : false));

        if (duplicate) {
          return;
        } else {
          newSelectedFromWaitingList.push(participantId);
          dispatch(editSelectedFromWaitingList(newSelectedFromWaitingList));
        }
      }
    } catch (err) {
      return toast.error(`Ein Fehler ist aufgetreten: ${err}`);
    }
  };

export const removeSelectedFromWaitingList =
  (participantId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const selectedFromWatingList = selectSelectedFromWaitingList(getState());
      const newSelectedFromWatingList = [...selectedFromWatingList];

      const index = newSelectedFromWatingList
        .map((id) => {
          return id;
        })
        .indexOf(participantId);

      if (index !== -1) {
        newSelectedFromWatingList.splice(index, 1);

        dispatch(editSelectedFromWaitingList(newSelectedFromWatingList));
      }
    } catch (err) {
      return toast.error(`Ein Fehler ist aufgetreten: ${err}`);
    }
  };

export const getEventParticipants =
  (eventId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await eventService.getAllEventRegistrations(eventId);

      const { data } = response;

      if (data === null) {
        return dispatch(getParticipantsSuccess([]));
      }

      return dispatch(getParticipantsSuccess(data));
    } catch (err) {
      return toast.error(`Die Teilnehmer konnten nicht gefunden werden: ${err}`);
    }
  };

export const editCurrentParticipant =
  (participantId: string, values: FormValues): AppThunk =>
  async (dispatch) => {
    try {
      const zusaetzlicheFelder = omit(values, [
        "Vorname",
        "Nachname",
        "E-Mail",
        "bild",
        "policy",
        "sendeErinnerungsmail",
      ]);

      const newParticipant: EditParticipant = {
        vorname: values.Vorname,
        nachname: values.Nachname,
        email: values["E-Mail"],
        bild: values.bild,
        sendeErinnerungsmail: values.SendeErinnerungsMail,
        zusaetzlicheFelder: [],
      };
      Object.entries(zusaetzlicheFelder).map((value: any) =>
        newParticipant.zusaetzlicheFelder.push({ name: value[0], information: value[1] })
      );

      if (values.bild === "") {
        delete newParticipant.bild;
      }

      await eventService.editParticipant(participantId, newParticipant);
      dispatch(signupForEventSuccess());
    } catch (err) {
      return toast.error(`Die Anmeldung konnte nicht gespeichert werden: ${err}`);
    }
  };

export const reassignParticipants =
  ({ participantIds, eventNew, eventCurrent }: ReassignParticipantsProps): AppThunk =>
  async (dispatch) => {
    try {
      await eventService.reassignParticipants({ participantIds, eventNew });
      dispatch(getEventParticipants(eventCurrent));
      return toast.success(
        `${participantIds.length > 1 ? "Die Teilnehmer konnten" : "Der Teilnehmer konnte"} umgebucht werden.`
      );
    } catch (err: any) {
      return toast.error(`Eine Umbuchung ist nicht möglich. ${err}`);
    }
  };

export const deleteParticipant =
  (participantIds: string[], eventId: string, deleteReason: string): AppThunk =>
  async (dispatch) => {
    try {
      await Promise.all(
        participantIds.map(async (id: string) => {
          await eventService.deleteParticipant(id, deleteReason);
          dispatch(removeFromSelectedParticipants(id));
          dispatch(removeSelectedFromWaitingList(id));
        })
      ).then(() => dispatch(getEventParticipants(eventId)));

      return toast.success(
        `${participantIds.length > 1 ? "Die Teilnehmer wurden" : "Der Teilnehmer wurde"} erfolgreich gelöscht.`
      );
    } catch (err) {
      dispatch(getEventParticipants(eventId));
      return toast.error(`Der Teilnehmer konnte nicht gelöscht werden: ${err}`);
    }
  };

export const downloadParticipantImages =
  (participantIds: string[]): AppThunk =>
  async (dispatch) => {
    participantIds.map(async (id: string) => {
      try {
        const { data } = await eventService.downloadParticipantImage(id);

        const a = document.createElement("a");
        a.setAttribute("href", data!);
        a.setAttribute("target", "_blank");
        a.setAttribute("download", "");
        a.click();

        dispatch(clearSelectedParticipants());
      } catch (err: any) {
        return toast.error(err, {
          autoClose: 10000,
          closeButton: true,
        });
      }
    });
  };

export const getParticipantExcel =
  (eventId: string): AppThunk =>
  async () => {
    try {
      const { data } = await eventService.getParticipantExcel(eventId);

      window.open(data, "blank");
    } catch (err) {
      return toast.error(`Ein Fehler ist aufgetreten: ${err}`);
    }
  };

export const searchParticipant =
  (eventId: string, name: string): AppThunk =>
  async (dispatch) => {
    try {
      const { data } = await eventService.searchParticipant(eventId, name);

      if (data.length === 0) {
        return dispatch(getParticipantsSuccess([]));
      } else if (name === "") {
        return dispatch(getEventParticipants(eventId));
      }

      return dispatch(getParticipantsSuccess(data));
    } catch (err) {
      return toast.error(`Ein Fehler ist aufgetreten: ${err}`);
    }
  };

export const signupForEvent =
  (formValues: FormValues, eventId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const additionalFieldValues = omit(formValues, [
        "Vorname",
        "Nachname",
        "E-Mail",
        "bild",
        "reminderMail",
        "policy",
      ]);
      const additionalFields = Object.entries(additionalFieldValues).map((entry) => ({
        name: entry[0],
        information: entry[1],
      }));
      const requestParams = {
        vorname: formValues.Vorname,
        nachname: formValues.Nachname,
        email: formValues["E-Mail"],
        bild: formValues.bild,
        zusaetzlicheFelder: additionalFields,
        sendeErinnerungsmail: formValues.reminderMail,
      };

      if (formValues.bild === "") {
        delete requestParams.bild;
      }

      await eventService.signUp(eventId, requestParams);

      dispatch(signupForEventSuccess());
    } catch (err) {
      return toast.error(`Die Anmeldung konnte nicht gespeichert werden: ${err}`);
    }
  };
