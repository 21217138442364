import { createSlice } from "@reduxjs/toolkit";
import { AdminState } from "../types/index";

const initialState: AdminState = {
  availableEvents: {
    content: [],
    currentPage: 1,
    size: 10,
    total: 0,
    totalPages: 0,
  },
  token: "",
  currentId: "",
  filters: {
    name: "",
    zip: "",
    place: "",
    type: "",
    period: { from: "", to: "" },
    speaker: "",
    city: "",
    searchKeywords: "",
  },
  loading: false,
  isReset: false,
  viewOnlyArchived: false,
  firstName: "",
  lastName: "",
  role: "",
};

export const adminSlice = createSlice({
  name: "adminState",
  initialState,
  reducers: {
    getEventSuccess(state) {
      state.loading = false;
    },
    getAllEventsSuccess(state, action) {
      state.availableEvents = action.payload;
      state.loading = false;
    },
    getAllEventsError(state) {
      state.availableEvents = {
        content: [],
        currentPage: 1,
        size: 10,
        total: 0,
        totalPages: 0,
      };
      state.loading = false;
    },
    setEvents(state, action) {
      state.availableEvents.content = action.payload;
    },
    setFilterValues(state, action) {
      state.filters = action.payload;
    },
    setPlace(state, action) {
      state.filters.place = action.payload;
    },
    setSpeaker(state, action) {
      state.filters.speaker = action.payload;
    },
    setCurrentId(state, action) {
      state.currentId = action.payload;
    },
    loginStart(state) {
      state.loading = true;
    },
    loginSuccess(state, action) {
      state.loading = false;
      state.token = action.payload;
    },
    loginError(state) {
      state.loading = false;
    },
    resetPasswordStart(state) {
      state.loading = true;
    },
    resetPasswordSuccess(state) {
      state.loading = false;
      state.isReset = false;
    },
    resetPasswordError(state) {
      state.loading = false;
    },
    getUserDetailsSuccess(state, action) {
      state.loading = false;
      state.firstName = action.payload.vorname;
      state.lastName = action.payload.nachname;
      state.role = action.payload.rolle;
    },
    getUserDetailsError(state) {
      state.loading = false;
    },
    resetPasswordToggle(state) {
      state.isReset = !state.isReset;
    },
    viewArchivedToggle(state) {
      state.viewOnlyArchived = !state.viewOnlyArchived;
    },
    reset() {
      return { ...initialState };
    },
  },
});

const { reducer } = adminSlice;

export default reducer;
