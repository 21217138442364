import { set, formatISO } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

const getEndOfDayTime = (date: string) => formatISO(
    set(zonedTimeToUtc(new Date(date).toISOString(), "Europe/Berlin"), {
      hours: 23,
      minutes: 59,
      seconds: 59
    })
  );

export default getEndOfDayTime;
