import React from "react";
import styled from "styled-components";
import { Flex } from "@rebass/grid";

import HeaderNavigation from "../components/user-view/header-navigation";
import Footer from "../components/footer";
import ServiceBox from "../components/user-view/service-box";
import HeaderImg from "../assets/header-image.png";

const Root = styled.div`
  height: 100%;
`;

const Container = styled(Flex)`
  width: 80%;
  margin-top: 20px;
  max-width: 1150px;

  @media (max-width: 1100px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 0 20px;
  }
`;
const ImageContainer = styled(Flex)`
  width: 90%;

  @media (max-width: 1100px) {
    width: 100%;
  }
`;

const LeftColumnContainer = styled(Flex)`
  order: 0;
  width: 35%;
  @media (max-width: 1100px) {
    order: 1;
    margin: 40px 0 20px 0;
    width: 100%;
  }
`;
const RightColumnContainer = styled(Flex)`
  @media (max-width: 1100px) {
    margin-left: 0;
    width: 100%;
    justify-content: center;
  }
`;

const HeaderImage = styled.img`
  height: 420px;
  object-fit: cover;
  object-position: right;
  margin-top: 120px;
  width: 100%;
`;

const LocationInfo = styled(Flex)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
`;

interface Props {
  children: JSX.Element;
}

const MainLayout: React.FC<Props> = ({ children }) => {
  return (
    <Root>
      <HeaderNavigation />
      <ImageContainer alignItems="center" margin="0 auto">
        <HeaderImage src={HeaderImg} />
      </ImageContainer>
      <Container
        flexDirection={["column", "row"]}
        justifyContent="center"
        margin="0 auto"
      >
        <LeftColumnContainer
          alignItems="center"
          flexDirection="column"
          order={[1, 0]}
        >
          <LocationInfo alignItems="center" height="50px">
            Service &gt; Seminare / Schulungen
          </LocationInfo>
          <ServiceBox />
        </LeftColumnContainer>
        <RightColumnContainer width="65%" margin="50px 0 0 60px">
          <div>{children}</div>
        </RightColumnContainer>
      </Container>
      <Footer />
    </Root>
  );
};

export default MainLayout;
