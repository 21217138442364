import React, { useEffect } from "react";
import { Flex } from "@rebass/grid";
import { useFormik } from "formik";
import { Link } from "react-router-dom";

import MainLayout from "../../layouts/main-admin-layout";
import { CreateEventProps } from "../../../container/admin-view/page-create-event-container";
import Button from "../../components/elements/button";
import Description from "../../../container/admin-view/components/description-container";
import EventLocation from "../../../container/admin-view/components/event-location-container";
import DateDetails from "../../../container/admin-view/components/date-details-container";
import CreateForm from "../../components/admin-view/create-event/create-form";
import createEventValidationSchema from "../../../utils/create-event-validation";
import { PageHeadline } from "../../components/identity/styled-text";

const CreateEvent: React.FC<CreateEventProps> = ({
  initialValues,
  createNewEvent,
  editCurrentEvent,
  fields,
  setNewEventsFormFields,
  creatEventSuccessfull,
  setCreateEventSuccessfull,
  editEvent,
  eventTypes,
}) => {
  useEffect(() => {
    setCreateEventSuccessfull(false);
  }, [setCreateEventSuccessfull]);
  useEffect(() => {
    if (creatEventSuccessfull === true) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      return;
    }
  }, [creatEventSuccessfull]);
  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    validationSchema: createEventValidationSchema,
    validateOnBlur: true,
    onSubmit: editEvent ? editCurrentEvent : createNewEvent,
    enableReinitialize: true,
  });
  return (
    <MainLayout>
      <Flex flexDirection="column" width="100%">
        {creatEventSuccessfull ? (
          <Flex flexDirection="column" alignItems="center" justifyContent="center" mt="100px" mb="100px">
            <PageHeadline style={{ marginBottom: "30px", textAlign: "center" }}>
              {editEvent
                ? "Die Veranstaltung wurde erfolgreich bearbeitet."
                : "Die Veranstaltung wurde erfolgreich erstellt."}
            </PageHeadline>
            <Link to={{ pathname: `/event-overview` }}>
              <Button buttonText="Zurück zur Übersicht" arrow iconLeft variant="light" />
            </Link>
          </Flex>
        ) : (
          <>
            <Flex justifyContent="space-between" margin="30px 0 60px 0">
              <PageHeadline>{editEvent ? "Veranstaltung bearbeiten" : "Veranstaltung anlegen"}</PageHeadline>
              <Link to={{ pathname: `/event-overview` }}>
                <Button buttonText="Zurück zur Übersicht" arrow iconLeft variant="light" />
              </Link>
            </Flex>
            <Description
              values={formik.values.descriptionValues}
              errors={formik.errors.descriptionValues}
              setFieldValue={formik.setFieldValue}
              onChange={formik.handleChange}
            />
            <EventLocation
              values={formik.values.eventLocationValues}
              errors={formik.errors.eventLocationValues}
              onChange={formik.handleChange}
            />
            <DateDetails
              values={formik.values.dateDetailsValues}
              onChange={formik.handleChange}
              errors={formik.errors.dateDetailsValues}
              setFieldValue={formik.setFieldValue}
              eventTypes={eventTypes}
            />
            <CreateForm fields={fields} setFormFields={setNewEventsFormFields} />
            <Flex alignSelf="flex-end" width="40%" justifyContent="space-between" mt="35px">
              <Link to={{ pathname: `/event-overview` }}>
                <Button buttonText="Abbrechen" variant="light" />
              </Link>
              <Button buttonText="Veranstaltung speichern" variant="dark" arrow onClick={formik.handleSubmit} />
            </Flex>
          </>
        )}
      </Flex>
    </MainLayout>
  );
};

export default CreateEvent;
