import React from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";

import EweLogo from "../assets/ewe-netz-logo-white.svg";
import ArrowUp from "../assets/arrow-up.svg";
import { Link as RouterLink } from "react-router-dom";
import { LEGAL } from "../../config";

const FooterWrapper = styled(Flex)`
  position: relative;
  background: radial-gradient(90.99% 90.99% at 50% 0%, #338ac2 28.12%, #00487a 100%);
`;

const ArrowContainer = styled.div`
  position: absolute;
  margin: 0 auto;
  top: 55px;
  left: 50%;
  margin-left: -18px;
  cursor: pointer;

  @media (max-width: 799px) {
    right: 50px;
    left: unset;
  }
`;

const StyledRouterLink = styled(RouterLink)`
  width: max-content;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;

  border-bottom: 1px solid transparent;
  transition: border-bottom-color 0.2s ease-out;

  &:hover {
    border-bottom: 1px solid #ffffff;
  }
`;

const Link = styled.a`
  width: max-content;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;

  border-bottom: 1px solid transparent;
  transition: border-bottom-color 0.2s ease-out;

  &:hover {
    border-bottom: 1px solid #ffffff;
  }
`;

const LinkBlockContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  width: 250px;
  margin: 0 5px 20px 5px;
`;

const LinkWithBorder = styled(Box)`
  border-left: 1px solid white;
  border-right: 1px solid white;
`;

const Footer: React.FC = () => {
  return (
    <FooterWrapper flexDirection="column" width="100%" marginTop="50px">
      <ArrowContainer onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
        <img src={ArrowUp} alt="arrow-up" height="36px" width="36px" />
      </ArrowContainer>
      <Flex
        width="80%"
        height="100%"
        margin="0 auto"
        marginTop="55px"
        marginBottom="40px"
        flexDirection="column"
        style={{ maxWidth: "1450px" }}
      >
        <img src={EweLogo} alt="ewe-logo" height="33px" width="180px" />
        <Flex marginTop="75px" marginBottom="75px" justifyContent="space-between" flexWrap="wrap">
          <LinkBlockContainer>
            <Link href="https://www.ewe-netz.de/vertragspartner/strom/anweisungswerke">STROM</Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/strom/anweisungswerke">Anweisungswerke</Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/strom/formulare">Formulare</Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/strom/archiv">Archiv</Link>
          </LinkBlockContainer>
          <LinkBlockContainer>
            <Link href="https://www.ewe-netz.de/vertragspartner/telekommunikation/anweisungswerke">GAS</Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/telekommunikation/anweisungswerke">
              Anweisungswerke
            </Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/telekommunikation/formulare">Formulare</Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/telekommunikation/archiv">Archiv</Link>
          </LinkBlockContainer>
          <LinkBlockContainer>
            <Link href="https://www.ewe-netz.de/vertragspartner/wasser/anweisungswerke">WASSER</Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/wasser/anweisungswerke">Anweisungswerke</Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/wasser/formulare">Formulare</Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/wasser/archiv">Archiv</Link>
          </LinkBlockContainer>
          <LinkBlockContainer>
            <Link href="https://www.ewe-netz.de/vertragspartner/telekommunikation/anweisungswerke">
              TELEKOMMUNIKATION
            </Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/telekommunikation/anweisungswerke">
              Anweisungswerke
            </Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/telekommunikation/formulare">Formulare</Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/telekommunikation/archiv">Archiv</Link>
          </LinkBlockContainer>
          <LinkBlockContainer>
            <Link href="https://www.ewe-netz.de/vertragspartner/service/ausschreibungskalender">SERVICE</Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/service/ausschreibungskalender">
              Ausschreibungskalender
            </Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/service/material">Material</Link>
            <Link href={LEGAL.privacy}>Datenschutz</Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/service/handbuecher">Handbücher</Link>
            <StyledRouterLink to={{ pathname: "/" }}>Seminare / Schulungen</StyledRouterLink>
            <Link href="https://www.ewe-netz.de/vertragspartner/service/hse-arbeitssicherheit">
              HSE / Arbeitssicherheit
            </Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/service/glossar">Glossar</Link>
          </LinkBlockContainer>
          <LinkBlockContainer>
            <Link href="https://www.ewe-netz.de/vertragspartner/mehr/anweisungswerke">MEHR</Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/mehr/anweisungswerke">Anweisungswerke</Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/mehr/formulare">Formulare</Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/mehr/leistungsverzeichnisse">
              Leistungsverzeichnisse
            </Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/mehr/bauleiterinformationen">
              Bauleiterinformationen
            </Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/mehr/flaechen-der-preiszone-a">
              Flächen der Preiszone A
            </Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/mehr/archiv">Archiv</Link>
            <Link href="https://www.ewe-netz.de/vertragspartner/mehr/mehr">Mehr</Link>
          </LinkBlockContainer>
        </Flex>
        <Flex width="100%" justifyContent="center">
          <Box padding="0 10px">
            <Link href={LEGAL.contact}>KONTAKT</Link>
          </Box>
          <LinkWithBorder padding="0 10px">
            <Link href={LEGAL.privacy} target="blank">
              DATENSCHUTZ
            </Link>
          </LinkWithBorder>
          <Box padding="0 10px">
            <Link href={LEGAL.imprint} target="blank">
              IMPRESSUM
            </Link>
          </Box>
        </Flex>
      </Flex>
    </FooterWrapper>
  );
};

export default Footer;
