import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import FilterComponent from "../../presentational/components/user-view/filter";
import {
  setCategory,
  setPlace,
  setSpeaker,
  setPeriodStart,
  setPeriodEnd,
  getEventTypes,
  resetFilter,
  getPlaces
} from "../../store/userState/actions";
import {
  selectFilters,
  selectEventTypes,
  selectPlaces,
  selectNumOfAvailableEvents
} from "../../store/userState/selector";
import { Filter, EventTypes, Place } from "../../store/types";

interface Props {}

export interface FilterProps {
  setCategory: (value: string) => void;
  setPlace: (value: string) => void;
  setSpeaker: (value: string) => void;
  setPeriodStart: (value: string) => void;
  setPeriodEnd: (value: string) => void;
  filterValues: Filter;
  numOfAvailableEvents: number;
  categories: EventTypes[];
  places: Place[];
}

const FilterContainer: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const filter = useSelector(selectFilters);

  useEffect(() => {
    dispatch(getEventTypes());
    dispatch(getPlaces());

    return () => {
      dispatch(resetFilter());
    };
  }, [dispatch]);

  const filterProps: FilterProps = {
    setCategory: (value: string) => dispatch(setCategory(value)),
    setPlace: (value: string) => dispatch(setPlace(value)),
    setSpeaker: (value: string) => dispatch(setSpeaker(value)),
    setPeriodStart: useCallback(
      (value: string) => dispatch(setPeriodStart(value)),
      [dispatch]
    ),
    setPeriodEnd: useCallback(
      (value: string) => dispatch(setPeriodEnd(value)),
      [dispatch]
    ),
    categories: useSelector(selectEventTypes),
    places: useSelector(selectPlaces),
    filterValues: filter,
    numOfAvailableEvents: useSelector(selectNumOfAvailableEvents)
  };

  return <FilterComponent {...filterProps} />;
};

export default FilterContainer;
