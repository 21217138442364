import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import EditRegistrationForm from "../../presentational/pages/admin-view/edit-registration-form";
import { setChangedFormSuccessfull, editEventsForm } from "../../store/adminState/edit-event/actions";
import { selectChangedFormSuccessfull, selectEventById } from "../../store/adminState/edit-event/selector";
import { Felder, Event } from "../../store/types";
import { AppState } from "../../store/configureStore";

interface Props {}

export interface EditRegistrationFormProps {
  currentEvent: Event | undefined;
  changedFormSuccessfull: boolean;
  setChangedFormSuccessfull: (value: boolean) => void;
  editForm: (eventId: string, values: Felder[]) => void;
}

const EditRegistrationFormContainer: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const editRegistrationFormProps: EditRegistrationFormProps = {
    currentEvent: useSelector((state: AppState) => selectEventById(state, id)),
    changedFormSuccessfull: useSelector(selectChangedFormSuccessfull),
    setChangedFormSuccessfull: useCallback((value: boolean) => dispatch(setChangedFormSuccessfull(value)), [dispatch]),
    editForm: (eventId: string, values: Felder[]) => dispatch(editEventsForm(eventId, values))
  };

  return <EditRegistrationForm {...editRegistrationFormProps} />;
};

export default EditRegistrationFormContainer;
