import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  &:hover > path {
    transition: fill linear 0.1s;
    cursor: pointer;
    fill: ${({ color }) => (color ? color : "#585858")};
  }
`;

interface CloseIconProps {
  onClick?: (() => void) | ((e: React.MouseEvent<HTMLDivElement>) => void);
  backgroundColor?: string;
  top?: string;
  hoverColor?: string;
  margin?: string;
}

const CloseIcon: React.FC<CloseIconProps> = ({ onClick, backgroundColor, top, hoverColor, margin }) => {
  return (
    <div
      onClick={onClick}
      style={{
        position: "absolute",
        top: top ? top : 5,
        right: 5,
        display: "flex",
        cursor: "pointer",
        margin: margin || ""
      }}
    >
      <StyledSvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" color={hoverColor}>
        <path
          fill={backgroundColor ? backgroundColor : `#2E2E2E`}
          fillRule="nonzero"
          d="M8.032.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15zm3.53 9.97l-1.06 1.06-2.47-2.469-2.47 2.47-1.06-1.061L6.97 8 4.5 5.53l1.061-1.06 2.47 2.47 2.47-2.47 1.06 1.06L9.092 8l2.47 2.47z"
        />
      </StyledSvg>
    </div>
  );
};

export default CloseIcon;
