import eventService from "../../services/events";
import authService from "../../services/auth";
import { AppThunk } from "../configureStore";
import {
  selectPage,
  selectFiltersCategory,
  selectFiltersSpeaker,
  selectPageSize,
  selectFiltersPeriod,
  selectFiltersPlace,
} from "./selector";
import { userSlice } from "./reducer";
import { FormValues, RedirectValue } from "../types";
import { omit } from "lodash";
import { formatISO } from "date-fns";
import { toast } from "react-toastify";
import { checkForDate } from "../types/actions";

const { actions } = userSlice;

export const {
  getAllEventsSuccess,
  getAllEventsError,
  setFilter,
  setCategory,
  setPlace,
  setSpeaker,
  setPeriodStart,
  setPeriodEnd,
  setCurrentId,
  setCurrentForm,
  signupForEventSuccess,
  signupForEventError,
  signoffFromEventSuccess,
  setSignupSuccessfull,
  setCurrentEvent,
  setEventTypes,
  setPlaces,
  resetFilter,
  redirectStart,
  redirectSuccess,
  redirectError,
  reset,
} = actions;

export const getAllEvents = (): AppThunk => async (dispatch, getState) => {
  try {
    const page = selectPage(getState());
    const size = selectPageSize(getState());
    const speaker = selectFiltersSpeaker(getState());
    const category = selectFiltersCategory(getState());
    const place = selectFiltersPlace(getState());
    const period = selectFiltersPeriod(getState());

    const startDatum = formatISO(new Date(period.from || ""));
    const endDatum = formatISO(new Date(period.to || ""));

    const response = await eventService.all({
      page: page || 1,
      size,
      speaker,
      startDatum,
      endDatum,
      place: place === "ALL_PLACES" ? "" : place,
      category: category === "ALL_CATEGORIES" ? "" : category,
    });

    const { data } = response;

    if (data === null) {
      return dispatch(
        getAllEventsSuccess({
          content: [],
          currentPage: 1,
          size: 10,
          total: 0,
          totalPages: 0,
        })
      );
    }

    dispatch(getAllEventsSuccess(data));
  } catch (err) {
    dispatch(getAllEventsError());
    if (err instanceof RangeError) {
      return;
    }
    return toast.error(`Es konnte keine Veranstaltung gefunden werden: ${err}`);
  }
};

export const getEventTypes = (): AppThunk => async (dispatch) => {
  try {
    const { data } = await eventService.getEventTypes();

    return dispatch(setEventTypes(data));
  } catch (err) {
    return toast.error(`Beim laden der Veranstaltungstypen ist ein Fehler aufgetreten: ${err}`);
  }
};

export const getPlaces = (): AppThunk => async (dispatch) => {
  try {
    const { data } = await eventService.getPlaces();

    return dispatch(setPlaces(data));
  } catch (err) {
    return toast.error(`Beim laden der Veranstaltungsorte ist ein Fehler aufgetreten: ${err}`);
  }
};

export const signupForEvent =
  (formValues: FormValues, eventId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const additionalFieldValues = omit(formValues, [
        "Vorname",
        "Nachname",
        "E-Mail",
        "bild",
        "reminderMail",
        "policy",
      ]);
      const additionalFields = Object.entries(additionalFieldValues).map((entry) => ({
        name: entry[0],
        information: checkForDate(entry[1])
          ? new Date(entry[1]).toLocaleDateString("de-DE", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
          : entry[1],
        //information: entry[1],
      }));

      let requestParams = {
        vorname: formValues.Vorname,
        nachname: formValues.Nachname,
        email: formValues["E-Mail"],
        bild: formValues.bild,
        zusaetzlicheFelder: additionalFields,
        sendeErinnerungsmail: formValues.reminderMail,
      };

      if (formValues.bild === "") {
        delete requestParams.bild;
      }

      await eventService.signUp(eventId, requestParams);

      dispatch(signupForEventSuccess());
    } catch (err) {
      dispatch(signupForEventError());
      return toast.error(`Die Anmeldung konnte nicht gespeichert werden: ${err}`);
    }
  };

export const signoffFromEvent =
  (signupId: string): AppThunk =>
  async (dispatch) => {
    try {
      await eventService.signOff(signupId);
      dispatch(signoffFromEventSuccess(true));
    } catch (err) {
      return toast.error(`Die Abmeldung ist fehlgeschlagen: ${err}`);
    }
  };

export const getEvent =
  (eventId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await eventService.one(eventId);
      const { data } = response;
      dispatch(setCurrentId(data.veranstaltungId));
      dispatch(setCurrentEvent(data));
    } catch (err) {
      return toast.error(`Die Veranstaltung konnte nicht gefunden werden: ${err}`);
    }
  };

export const verifyRedirect =
  (params: RedirectValue): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(redirectStart());

      const response = await authService.verifyRedirect(params.token);

      const { data } = response;

      dispatch(redirectSuccess(data));
    } catch (err) {
      dispatch(redirectError());
    }
  };

export const removeUserToken = (): AppThunk => async (dispatch) => {
  dispatch(reset());
};
