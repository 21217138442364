import React, { useEffect, MouseEvent } from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import { MenuItem } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import subDays from "date-fns/subDays";

import CloseIcon from "../../identity/close-icon";
import Input from "../../elements/input";
import SelectInput from "../../elements/select-input";
import TagInput from "../../elements/tag-input";
import Select from "../../elements/select";
import DatePicker from "../../elements/date-picker";
import Button from "../../elements/button";
import Checkbox from "../../elements/checkbox";
import { DateDetailsProps } from "../../../../container/admin-view/components/date-details-container";
import { EventDay, EventTypes } from "../../../../store/types";
import { Heading } from "../../identity/styled-text";

const CheckboxText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
`;

const EventType = styled.div`
  text-transform: capitalize;
`;

const timeValues: string[] = [
  "07:00:00",
  "07:30:00",
  "08:00:00",
  "08:30:00",
  "09:00:00",
  "09:30:00",
  "10:00:00",
  "10:30:00",
  "11:00:00",
  "11:30:00",
  "12:00:00",
  "12:30:00",
  "13:00:00",
  "13:30:00",
  "14:00:00",
  "14:30:00",
  "15:00:00",
  "15:30:00",
  "16:00:00",
  "16:30:00",
  "17:00:00",
  "17:30:00",
  "18:00:00",
  "18:30:00",
  "19:00:00",
  "19:30:00",
  "20:00:00",
  "20:30:00",
  "21:00:00",
  "21:30:00",
  "22:00:00",
];

const DateDetails: React.FC<DateDetailsProps> = ({
  values,
  onChange,
  setFieldValue,
  setDateDetailsData,
  setSearchKeywords,
  setNewEventsEventDay,
  editAdditionalDays,
  setNewEventsRegistrationDeadline,
  searchKeywords,
  additionalDays,
  addEventDay,
  removeLastDay,
  addEventType,
  deleteEventType,
  eventTypes,
  errors,
}) => {
  useEffect(() => {
    if (values.eventDay.datum && !values.registrationDeadline) {
      setFieldValue("dateDetailsValues.registrationDeadline", subDays(new Date(values.eventDay.datum), 1).toString());
      setNewEventsRegistrationDeadline(subDays(new Date(values.eventDay.datum), 1).toISOString());
    }
  }, [values.eventDay.datum, values.registrationDeadline, setFieldValue, setNewEventsRegistrationDeadline]);

  useEffect(() => {
    if (!values.eventDay.datum) {
      setFieldValue("eventDay.datum", new Date().toString());
      setNewEventsEventDay(new Date().toISOString());
    }

    if (!values.registrationDeadline) {
      setFieldValue("dateDetailsValues.registrationDeadline", subDays(new Date(), 1).toString());
      setNewEventsRegistrationDeadline(subDays(new Date(), 1).toISOString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      width="100%"
      backgroundColor="#F2F6F8"
      padding="20px 30px"
      flexDirection="column"
      mt="15px"
      style={{ borderRadius: 4 }}
    >
      <Heading>Datum & Details</Heading>
      <Flex width="100%" mt="25px" justifyContent="space-between">
        <Box width="30%">
          <DatePicker
            label="Datum*"
            name={`dateDetailsValues.eventDay.datum`}
            error={errors?.eventDay?.datum}
            value={values.eventDay.datum || ""}
            onChange={(value) => {
              setFieldValue(`dateDetailsValues.eventDay.datum`, value?.toString() || "");
              setNewEventsEventDay(value?.toString() || "");
            }}
          />
        </Box>
        <Box width="30%">
          <Select
            label="Startzeit*"
            name={`dateDetailsValues.eventDay.startZeit`}
            value={values.eventDay.startZeit}
            onChange={onChange}
            error={errors?.eventDay?.startZeit}
            onBlur={() => setDateDetailsData(values)}
          >
            <MenuItem value="BITTE_WÄHLEN">Bitte wählen</MenuItem>
            {timeValues &&
              timeValues.map((time: string, index: number) => (
                <MenuItem key={index} value={`${time}`}>
                  {time.slice(0, -3)}
                </MenuItem>
              ))}
          </Select>
        </Box>
        <Box width="30%">
          <Select
            label="Endzeit*"
            name={`dateDetailsValues.eventDay.endZeit`}
            value={values.eventDay.endZeit}
            onChange={onChange}
            error={errors?.eventDay?.endZeit}
            onBlur={() => setDateDetailsData(values)}
          >
            <MenuItem value="BITTE_WÄHLEN">Bitte wählen</MenuItem>
            {timeValues &&
              timeValues.map((time: string, index: number) => (
                <MenuItem key={index} value={`${time}`}>
                  {time.slice(0, -3)}
                </MenuItem>
              ))}
          </Select>
        </Box>
      </Flex>

      {additionalDays &&
        additionalDays.length > 0 &&
        additionalDays.map((eventDay: EventDay, i: number) => {
          return (
            <Flex width="100%" mt="25px" justifyContent="space-between" key={i}>
              <Box width="30%">
                <DatePicker
                  label="Datum"
                  name={`dateDetailsValues.additionalDays[${i}].datum`}
                  value={values?.additionalDays[i]?.datum || ""}
                  onChange={(value) => {
                    setFieldValue(`dateDetailsValues.additionalDays[${i}].datum`, value?.toString() || "");
                    editAdditionalDays(value?.toString() || "", i);
                  }}
                />
              </Box>
              <Box width="30%">
                <Select
                  label="Startzeit"
                  name={`dateDetailsValues.additionalDays[${i}].startZeit`}
                  value={values?.additionalDays[i]?.startZeit}
                  onChange={onChange}
                  onBlur={() => setDateDetailsData(values)}
                >
                  <MenuItem value="BITTE_WÄHLEN">Bitte wählen</MenuItem>
                  {timeValues &&
                    timeValues.map((time: string, index: number) => (
                      <MenuItem key={index} value={`${time}`}>
                        {time.slice(0, -3)}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
              <Box width="30%">
                <Select
                  label="Endzeit"
                  name={`dateDetailsValues.additionalDays[${i}].endZeit`}
                  value={values?.additionalDays[i]?.endZeit}
                  onChange={onChange}
                  onBlur={() => setDateDetailsData(values)}
                >
                  <MenuItem value="BITTE_WÄHLEN">Bitte wählen</MenuItem>
                  {timeValues &&
                    timeValues.map((time: string, index: number) => (
                      <MenuItem key={index} value={`${time}`}>
                        {time.slice(0, -3)}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            </Flex>
          );
        })}
      <Flex mt="15px">
        <div style={{ marginRight: "20px" }}>
          <Button buttonText="Tag hinzufügen" variant="dark" onClick={addEventDay} plusIcon iconLeft />
        </div>
        {additionalDays && additionalDays.length > 0 && (
          <Button buttonText="Tag entfernen" variant="light" onClick={removeLastDay} />
        )}
      </Flex>
      <Flex width="100%" mt="25px" justifyContent="space-between">
        <Box width="30%">
          <DatePicker
            label="Anmeldeschluss*"
            name="dateDetailsValues.registrationDeadline"
            value={values.registrationDeadline || ""}
            error={errors?.registrationDeadline}
            onChange={(value) => {
              setFieldValue("dateDetailsValues.registrationDeadline", value?.toString() || "");
              setNewEventsRegistrationDeadline(value?.toISOString() || "");
            }}
          />
        </Box>
        <Box width="30%">
          <Select
            label="Veranstaltungstyp*"
            name="dateDetailsValues.eventType"
            value={eventTypes && eventTypes.length > 0 ? values.eventType : "BITTE_WÄHLEN"}
            error={errors?.eventType}
            onChange={onChange}
            onBlur={() => setDateDetailsData(values)}
          >
            <MenuItem value="BITTE_WÄHLEN">Bitte wählen</MenuItem>
            {eventTypes &&
              eventTypes.length > 0 &&
              eventTypes.map((type: EventTypes, index: number) => (
                <MenuItem key={index} value={`${type.veranstaltungsTyp}`}>
                  <EventType>{type.veranstaltungsTyp}</EventType>
                  {values.eventType.toLowerCase() !== type.veranstaltungsTyp.toLowerCase() && (
                    <CloseIcon
                      onClick={(e: MouseEvent) => {
                        e?.stopPropagation();
                        deleteEventType(type.veranstaltungsTyp);
                      }}
                      top="10px"
                      margin="0 13px 0 0"
                    />
                  )}
                </MenuItem>
              ))}
            <SelectInput onSubmit={addEventType} />
          </Select>
        </Box>
        <Box width="30%">
          <Input
            label="Maximale Anzahl der Teilnehmer"
            name="dateDetailsValues.maxNumberOfParticipants"
            value={values.maxNumberOfParticipants}
            error={errors?.maxNumberOfParticipants}
            onChange={onChange}
            onBlur={() => setDateDetailsData(values)}
          />
        </Box>
      </Flex>
      <Box mt="25px">
        <TagInput setSearchKeywords={setSearchKeywords} searchKeywords={searchKeywords} />
      </Box>
      <Flex>
        <Box mt="30px">
          <FormControlLabel
            control={
              <Checkbox
                style={{ width: 30, height: 28 }}
                name="dateDetailsValues.registrationForm"
                value={values.registrationForm}
                onChange={(e) => {
                  const dateDetails = { ...values };
                  dateDetails.registrationForm = !dateDetails.registrationForm;

                  setDateDetailsData(dateDetails);
                  onChange(e);
                }}
                checked={values.registrationForm}
              />
            }
            label={<CheckboxText>Anmeldeformular</CheckboxText>}
          />
        </Box>
        <Box mt="30px">
          <FormControlLabel
            control={
              <Checkbox
                style={{ width: 30, height: 28, marginLeft: 40 }}
                name="dateDetailsValues.forceImageUpload"
                value={values.forceImageUpload}
                onChange={(e) => {
                  const dateDetails = { ...values };
                  dateDetails.forceImageUpload = !dateDetails.forceImageUpload;

                  setDateDetailsData(dateDetails);
                  onChange(e);
                }}
                checked={values.forceImageUpload}
              />
            }
            label={<CheckboxText>Teilnehmer muss Bild hochladen</CheckboxText>}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default DateDetails;
