import React, { useEffect, useCallback } from "react";
import { getEvent, setCurrentId, signoffFromEvent, signoffFromEventSuccess } from "../../store/userState/actions";
import Cancellation from "../../presentational/pages/user-view/cancellation";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectCurrentEvent, selectSignoffSuccessful } from "../../store/userState/selector";

interface UrlParams {
  eventId: string;
  signupId: string;
}

export interface CancellationProps {
  signupId: string;
  eventName?: string;
  eventShortDesc?: string;
  eventDate?: {
    startZeit: string | undefined;
    endZeit: string | undefined;
  };
  signoffFromEvent: (signupId: string) => void;
  setSignoffSuccessful: (value: boolean) => void;
  signoffSuccessful?: boolean;
}

interface Props {}

const CancellationContainer: React.FC<Props> = () => {
  const { eventId, signupId } = useParams<UrlParams>();
  const dispatch = useDispatch();
  const signoffSuccessful = useSelector(selectSignoffSuccessful);
  useEffect(() => {
    if (eventId) {
      dispatch(getEvent(eventId));
    }

    return () => {
      dispatch(setCurrentId(""));
    };
  }, [eventId, dispatch]);

  const event = useSelector(selectCurrentEvent);

  const cancellationProps: CancellationProps = {
    signupId: signupId,
    eventName: event?.name,
    eventShortDesc: event?.kurzbeschreibung,
    eventDate: { startZeit: event?.startTagStartZeit, endZeit: event?.startTagEndZeit },
    signoffFromEvent: (signupId: string) => dispatch(signoffFromEvent(signupId)),
    setSignoffSuccessful: useCallback((value: boolean) => dispatch(signoffFromEventSuccess(value)), [dispatch]),
    signoffSuccessful: signoffSuccessful
  };

  return <Cancellation {...cancellationProps} />;
};
export default CancellationContainer;
