import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../../configureStore";
import { Event } from "../../types";

export const selectCurrentId = (state: AppState) =>
  state?.adminState?.editEventState.currentId;
export const selectSelectedEvents = (state: AppState) =>
  state?.adminState?.editEventState.selectedEvents;
export const selectChangedFormSuccessfull = (state: AppState) =>
  state?.adminState?.editEventState.changedFormSuccessfull;

export const selectEventById = (state: AppState, eventId: string) => {
  const events = state?.adminState?.events.availableEvents;
  return events.content.find(
    (event: Event) => event.veranstaltungId === eventId
  );
};

export const selectIsEventSelected = (eventId: string) => {
  return createSelector(selectSelectedEvents, (selectedEvents: string[]) =>
    selectedEvents.length > 0
      ? selectedEvents.some(id => (id === eventId ? true : false))
      : false
  );
};
