import styled from "styled-components";

export const PageHeadline = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  color: #0077b4;
  padding-bottom: 10px;
`;

export const Heading = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: #0077b4;
`;

export const NormalText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #0077b4;
`;

export const NormalTextBlack = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  color: #333333;
`;

export const SmallText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
`;

export const SmallTextBlue = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #0077b4;
`;

export const InputText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #0077b4;
`;

export const LabelText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 5px;
`;

export const ErrorText = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: rgb(247, 96, 96);
  margin-top: 2px;
  min-height: 12px;
`;
