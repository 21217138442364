import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import CreateEventPage from "../../presentational/pages/admin-view/create-event";
import {
  selectCreateEventInitialValues,
  selectNewEventsFields,
  selectCreateEventSuccessfull,
  selectEventTypes
} from "../../store/adminState/create-event/selector";
import {
  createNewEvent,
  setNewEventsFormFields,
  setCreateEventSuccessfull,
  getEventTypes
} from "../../store/adminState/create-event/actions";
import { editCurrentEvent } from "../../store/adminState/edit-event/actions";
import { syncEventValues } from "../../store/adminState/edit-event/actions";
import {
  DescriptionValues,
  LocationData,
  DateDetailValues,
  Felder,
  EventTypes
} from "../../store/types";

interface Props {
  editEvent?: boolean;
}

interface CreateEventInitialValues {
  descriptionValues: DescriptionValues;
  eventLocationValues: LocationData;
  dateDetailsValues: DateDetailValues;
}

export interface CreateEventProps extends Props {
  initialValues: CreateEventInitialValues;
  createNewEvent: () => void;
  editCurrentEvent: () => void;
  setNewEventsFormFields: (values: Felder[]) => void;
  fields: Felder[];
  creatEventSuccessfull: boolean;
  setCreateEventSuccessfull: (value: boolean) => void;
  eventTypes: EventTypes[];
}

const CreateEventContainer: React.FC<Props> = ({ editEvent }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const syncNewEventValues = useCallback(() => dispatch(syncEventValues(id)), [
    dispatch,
    id
  ]);

  useEffect(() => {
    if (editEvent) {
      dispatch(syncNewEventValues);
    }
  }, [dispatch, editEvent, syncNewEventValues]);

  useEffect(() => {
    dispatch(getEventTypes());
  }, [dispatch]);
  const initialValues = useSelector(selectCreateEventInitialValues);
  const createEventProps: CreateEventProps = {
    initialValues,
    fields: useSelector(selectNewEventsFields),
    createNewEvent: () => dispatch(createNewEvent()),
    editCurrentEvent: () => dispatch(editCurrentEvent(id)),
    setNewEventsFormFields: (values: Felder[]) =>
      dispatch(setNewEventsFormFields(values)),
    creatEventSuccessfull: useSelector(selectCreateEventSuccessfull),
    setCreateEventSuccessfull: useCallback(
      (value: boolean) => dispatch(setCreateEventSuccessfull(value)),
      [dispatch]
    ),
    editEvent,
    eventTypes: useSelector(selectEventTypes)
  };

  return <CreateEventPage {...createEventProps} />;
};

export default CreateEventContainer;
