import eventService from "../../../services/events";
import { AppThunk } from "../../configureStore";

import { selectSelectedEvents, selectEventById } from "./selector";
import { selectNewEventValues } from "../create-event/selector";
import {
  syncNewEventValues,
  setCreateEventSuccessfull
} from "../create-event/actions";
import { getAllEvents } from "../actions";
import { editSlice } from "./reducer";
import {
  Felder,
  EventDayValues,
  EventDay,
  CreateEventParams
} from "../../types";
import { toast } from "react-toastify";

import getEndOfDayTime from "../../../utils/get-end-of-day-time";

const { actions } = editSlice;

export const { setCurrentId, editSelectedEvents, setChangedFormSuccessfull } =
  actions;

export const getEvent =
  (eventId: string): AppThunk =>
  async dispatch => {
    try {
      const response = await eventService.one(eventId);
      const { data } = response;
      dispatch(setCurrentId(data.veranstaltungId));
    } catch (err) {
      return toast.error(
        `Die Veranstaltung konnte nicht gefunden werden: ${err}`
      );
    }
  };

export const eventActionController =
  (eventIds: string[], action: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      await Promise.all(
        eventIds.map(async id => {
          switch (action) {
            case "DUPLICATE":
              return await eventService.duplicate(id);
            case "DELETE":
              await eventService.delete(id);
              return dispatch(removeFromSelectedEvents(id));
            case "CHANGE_STATUS":
              const selectedEvent = selectEventById(getState(), id);
              if (selectedEvent) {
                const newStatus =
                  selectedEvent.sichtbarkeit === "ONLINE"
                    ? "OFFLINE"
                    : selectedEvent.sichtbarkeit === "OFFLINE"
                    ? "ONLINE"
                    : "OFFLINE";

                return await eventService.changeStatus(id, newStatus);
              } else {
                return;
              }
            case "ARCHIVE":
              return await eventService.changeStatus(id, "ARCHIVIERT");
            default:
              return;
          }
        })
      )
        .then(() => {
          eventIds.map(id => {
            return dispatch(removeFromSelectedEvents(id));
          });
        })
        .then(() => dispatch(getAllEvents()));

      const actionType =
        action === "DUPLICATE"
          ? "dupliziert"
          : action === "DELETE"
          ? "gelöscht"
          : action === "ARCHIVE"
          ? "archiviert"
          : "";

      return toast.success(
        action === "CHANGE_STATUS"
          ? `Der Status der ${
              eventIds.length > 1 ? "Veranstaltungen" : "Veranstaltung"
            } wurde erfolgreich geändert.`
          : `Die ${
              eventIds.length > 1
                ? "Veranstaltungen wurden"
                : "Veranstaltung wurde"
            } erfolgreich ${actionType}.`
      );
    } catch (err) {
      return toast.error(
        `Die Aktion ${action} konnte nicht ausgeführt werden: ${err}`
      );
    }
  };

export const addToSelectedEvents =
  (eventId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const selectedEvents = selectSelectedEvents(getState());
      const newSelectedEvents = [...selectedEvents];

      if (newSelectedEvents.length === 0) {
        dispatch(editSelectedEvents([eventId]));
      } else {
        const duplicate = newSelectedEvents.some(id =>
          id === eventId ? true : false
        );

        if (duplicate) {
          return;
        } else {
          newSelectedEvents.push(eventId);
          dispatch(editSelectedEvents(newSelectedEvents));
        }
      }
    } catch (err) {
      return toast.error(`Ein Fehler ist aufgetreten: ${err}`);
    }
  };

export const removeFromSelectedEvents =
  (eventId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const selectedEvents = selectSelectedEvents(getState());
      const newSelectedEvents = [...selectedEvents];

      const index = newSelectedEvents
        .map(id => {
          return id;
        })
        .indexOf(eventId);

      if (index !== -1) {
        newSelectedEvents.splice(index, 1);

        dispatch(editSelectedEvents(newSelectedEvents));
      }
    } catch (err) {
      return toast.error(`Ein Fehler ist aufgetreten: ${err}`);
    }
  };

export const editEventsForm =
  (eventId: string, params: Felder[]): AppThunk =>
  async (dispatch, getState) => {
    try {
      const newForm = {
        formular: { felder: [...params] }
      };

      await eventService.editEventsForm(eventId, newForm);

      dispatch(setChangedFormSuccessfull(true));
    } catch (err) {
      dispatch(setChangedFormSuccessfull(false));
      return toast.error(
        `Das Formular konnte nicht gespeichert werden: ${err}`
      );
    }
  };

export const syncEventValues =
  (eventId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const event = selectEventById(getState(), eventId);
      const timeRegex = /\d\d:[0-9]\d:[0-9]\d(?:\.\d1)?/gm;
      const startZeit = event?.startTagStartZeit.match(timeRegex);
      const endZeit = event?.startTagEndZeit.match(timeRegex);
      const zusaetzlicheTage = Array.from(event?.zusaetzlicheTage || []);

      const formatierteZusaetzlicheTage: EventDayValues[] = [];

      if (zusaetzlicheTage) {
        zusaetzlicheTage.map((tag: EventDay, index: number) => {
          const startZeit = tag?.startZeit?.match(timeRegex);
          const endZeit = tag?.endZeit?.match(timeRegex);

          if (
            startZeit &&
            endZeit !== null &&
            startZeit &&
            endZeit !== undefined
          ) {
            return (formatierteZusaetzlicheTage[index] = {
              datum: tag.startZeit,
              startZeit: startZeit[0],
              endZeit: endZeit[0]
            });
          }
          return null;
        });
      }
      /*
    let imageBlob;
    if (event) {
      imageBlob = base64ToBlob(event?.bild?.base64Content, event?.bild?.type);
    }
    const blobUrl = URL.createObjectURL(imageBlob);
*/
      const newEventValues = {
        name: event?.name,
        referent: event?.referent,
        bild: [
          {
            size: 999,
            fileName: "",
            type: "_current_file",
            base64Content: "",
            preview: event?.bildLink
          }
        ],
        kurzbeschreibung: event?.kurzbeschreibung,
        beschreibung: event?.beschreibung,
        ort: {
          gebaeudename: event?.ort.gebaeudename,
          postleitzahl: event?.ort.postleitzahl,
          strasse: event?.ort.strasse,
          ortsname: event?.ort.ortsname,
          zusatzinfo: event?.ort.zusatzinfo
        },
        veranstaltungsTag: {
          datum: event?.startTagStartZeit,
          startZeit: startZeit ? startZeit[0] : "",
          endZeit: endZeit ? endZeit[0] : ""
        },
        zusaetzlicheTage: formatierteZusaetzlicheTage || [],
        formular: event?.formular,
        anmeldeschluss: getEndOfDayTime(event?.anmeldeschluss || ""),
        typ: event?.typ,
        suchbegriffe: event?.suchbegriffe,
        maxTeilnehmerAnzahl: event?.teilnehmerkapazitaet,
        anmeldeformular: true,
        mitFotoUpload: event?.mitFotoUpload
      };
      return dispatch(syncNewEventValues(newEventValues));
    } catch (err) {
      return toast.error(
        `Beim synchronisieren der Daten ist ein Fehler aufgetreten: ${err}`
      );
    }
  };

export const editCurrentEvent =
  (eventId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const newEventValues = selectNewEventValues(getState());
      const replaceTimeRegex = /\d\d:[0-9]\d:[0-9]\d(?:\.\d+)?/gm;

      const startTagStartZeit = new Date(newEventValues.veranstaltungsTag.datum)
        .toISOString()
        .replace(replaceTimeRegex, newEventValues.veranstaltungsTag.startZeit);

      const startTagEndZeit = new Date(newEventValues.veranstaltungsTag.datum)
        .toISOString()
        .replace(replaceTimeRegex, newEventValues.veranstaltungsTag.endZeit);

      const zusaetzlicheTage = [...newEventValues.zusaetzlicheTage];
      zusaetzlicheTage.map((additionalDay: EventDayValues, index: number) => {
        return (zusaetzlicheTage[index] = {
          startZeit: new Date(additionalDay.datum || "")
            .toISOString()
            .replace(replaceTimeRegex, additionalDay.startZeit || ""),
          endZeit: new Date(additionalDay.datum || "")
            .toISOString()
            .replace(replaceTimeRegex, additionalDay.endZeit || "")
        });
      });

      const newEvent: CreateEventParams = {
        ort: {
          gebaeudename: newEventValues.ort.gebaeudename,
          strasse: newEventValues.ort.strasse,
          ortsname: newEventValues.ort.ortsname,
          postleitzahl: Number(newEventValues.ort.postleitzahl),
          zusatzinfo: newEventValues.ort.zusatzinfo,
        },
        intern: true,
        referent: newEventValues.referent,
        teilnehmerkapazitaet: Number(newEventValues.maxTeilnehmerAnzahl) || 0,
        startTagStartZeit,
        startTagEndZeit,
        formular: newEventValues.anmeldeformular
          ? newEventValues.formular
          : null,
        anmeldeschluss: getEndOfDayTime(newEventValues.anmeldeschluss),
        typ: newEventValues.typ,
        suchbegriffe: newEventValues.suchbegriffe,
        zusaetzlicheTage,
        name: newEventValues.name,
        kurzbeschreibung: newEventValues.kurzbeschreibung,
        beschreibung: newEventValues.beschreibung,
        mitFotoUpload: newEventValues.mitFotoUpload
      };

      if (
        newEventValues.bild[0] &&
        newEventValues.bild[0].type !== "_current_file"
      ) {
        newEvent["bild"] = newEventValues.bild[0];
      }

      if (newEventValues.ort.zusatzinfo === "") {
        delete newEvent.ort.zusatzinfo;
      }

      await eventService.editEvent(eventId, newEvent);

      dispatch(setCreateEventSuccessfull(true));
    } catch (err) {
      return toast.error(
        `Die Veranstaltung konnte nicht gespeichert werden: ${err}`
      );
    }
  };
