import React from "react";
import { Flex } from "@rebass/grid";
import styled from "styled-components";

import { SortingDirection } from "../../../container/admin-view/page-event-overview-container";

interface ArrowProps {
  active: boolean;
}
const ArrowContainer = styled(Flex)`
  width: 25px;
  height: 20px;
  justify-content: center;
  cursor: pointer;
`;
const ArrowUp = styled.div<ArrowProps>`
  display: inline-block;
  height: 0;
  width: 0;
  border-style: solid;
  border-color: transparent;
  border-width: 0 4px 6px;
  border-bottom-color: ${props =>
    props.active ? "#0077B4" : "rgba(0, 0, 0, 0.26)"};
`;
const ArrowDown = styled.div<ArrowProps>`
  display: inline-block;
  height: 0;
  width: 0;
  border-style: solid;
  border-color: transparent;
  border-width: 6px 4px 0;
  border-top-color: ${props =>
    props.active ? "#0077B4" : "rgba(0, 0, 0, 0.26)"};
`;

interface SortButtonProps {
  activeButton?: SortingDirection;
  onClickUp: () => void;
  onClickDown: () => void;
  resetSorting: () => void;
}

const SortButtons: React.FC<SortButtonProps> = ({
  activeButton,
  onClickUp,
  onClickDown,
  resetSorting
}) => {
  return (
    <Flex flexDirection="column">
      <ArrowContainer
        mb="3px"
        alignItems="flex-end"
        onClick={
          activeButton && activeButton === SortingDirection.ASCENDING
            ? () => resetSorting()
            : () => onClickUp()
        }
      >
        <ArrowUp active={activeButton === SortingDirection.ASCENDING} />
      </ArrowContainer>
      <ArrowContainer
        alignItems="flex-start"
        onClick={
          activeButton && activeButton === SortingDirection.DESCENDING
            ? () => resetSorting()
            : () => onClickDown()
        }
      >
        <ArrowDown active={activeButton === SortingDirection.DESCENDING} />
      </ArrowContainer>
    </Flex>
  );
};

export default SortButtons;
