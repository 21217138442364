import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../../configureStore";
import { Participant } from "../../types";

export const selectParticipants = (state: AppState) =>
  state?.adminState?.editParticipantsState?.participants;
export const selectSelectedParticipants = (state: AppState) =>
  state?.adminState?.editParticipantsState?.selectedParticipants;
export const selectSelectedFromWaitingList = (state: AppState) =>
  state?.adminState?.editParticipantsState?.selectedFromWaitingList;
export const selectSignupSuccessfull = (state: AppState) =>
  state?.adminState?.editParticipantsState?.signupSuccessfull;

export const selectParticipantById = (
  state: AppState,
  participantId: string
) => {
  const participants = state?.adminState?.editParticipantsState?.participants;
  return participants.find(
    (participant: Participant) => participant.anmeldungId === participantId
  );
};
export const selectIsParticipantSelected = (participantId: string) => {
  return createSelector(
    selectSelectedParticipants,
    (selectedparticipants: string[]) =>
      selectedparticipants.length > 0
        ? selectedparticipants.some(id => (id === participantId ? true : false))
        : false
  );
};
export const selectIsParticipantFromWaitingListSelected = (
  participantId: string
) => {
  return createSelector(
    selectSelectedFromWaitingList,
    (selectedparticipants: string[]) =>
      selectedparticipants.length > 0
        ? selectedparticipants.some(id => (id === participantId ? true : false))
        : false
  );
};
export const selectRegisteredParticipants = (state: AppState) => {
  const participants: Participant[] =
    state.adminState.editParticipantsState.participants;
  const registeredParticiants: Participant[] = [];
  participants.map((participant: Participant) =>
    participant.anmeldestatus === "ANGEMELDET"
      ? registeredParticiants.push(participant)
      : ""
  );

  return registeredParticiants;
};
export const selectWaitingParticipants = (state: AppState) => {
  const participants: Participant[] =
    state.adminState.editParticipantsState.participants;
  const waitingParticipants: Participant[] = [];
  participants.map((participant: Participant) =>
    participant.anmeldestatus === "WARTELISTE"
      ? waitingParticipants.push(participant)
      : ""
  );

  return waitingParticipants;
};
