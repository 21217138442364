import React from "react";

export interface PluxIconProps {
  color: string;
  margin?: string;
  onClick?: () => void;
}

const PlusIcon: React.FC<PluxIconProps> = ({ color, margin, onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: margin ? margin : 0, cursor: "pointer" }}
    >
      <rect x="7" width="2" height="16" fill={color} />
      <rect y="7" width="16" height="2" fill={color} />
    </svg>
  );
};

export default PlusIcon;
