import React from "react";

export interface PluxIconProps {
  color: string;
}

const PlusIcon: React.FC<PluxIconProps> = ({ color }) => {
  return (
    <div>
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.41 16.8395L14.6584 12.0971C15.4138 10.8841 15.8556 9.45891 15.8556 7.92755C15.8556 3.55711 12.2996 0 7.92755 0C3.55765 0 0 3.55657 0 7.92755C0 12.2985 3.55711 15.8556 7.92755 15.8556C9.31324 15.8556 10.6134 15.4972 11.7467 14.8712L16.5704 19.6846C16.963 20.0777 17.4758 20.2726 17.9897 20.2726C18.5047 20.2726 19.0196 20.075 19.4122 19.6824C20.1979 18.8967 20.1947 17.6231 19.41 16.8395ZM2.01004 7.92755C2.01004 4.66447 4.6661 2.0095 7.92701 2.0095C11.1912 2.0095 13.8451 4.66393 13.8451 7.92755C13.8451 11.1901 11.1917 13.8456 7.92701 13.8456C4.66664 13.8456 2.01004 11.1906 2.01004 7.92755Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default PlusIcon;
