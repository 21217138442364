import React, { useState, useCallback } from "react";
import { Flex, Box } from "@rebass/grid";
import { useFormik } from "formik";
import Input from "../../components/elements/input";

import MainLayout from "../../layouts/main-admin-layout";
import { LoginProps } from "../../../container/admin-view/login";
import Button from "../../components/elements/button";
import { ErrorText, Heading } from "../../components/identity/styled-text";

const Login: React.FC<LoginProps> = ({
  initialValues,
  onSubmit,
  onPasswordReset,
  loading,
  isReset,
  togglePasswordReset,
}) => {
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      !isReset ? onSubmit(values) : onPasswordReset(values.username);
    },
  });
  const [nameHasError, setNameHasError] = useState(false);
  const handleSubmit = useCallback(() => formik.handleSubmit(), [formik]);

  return (
    <MainLayout>
      <Flex justifyContent="center" mb="25px" width="100%">
        <Box width="40%" mt="25px">
          <Heading>{!isReset ? "Login" : "Passwort zurücksetzen"}</Heading>
          <Box mt="10px">
            <Input label="Username" name="username" value={formik.values.username} onChange={(event) => {
                formik.handleChange(event);
                const value = event.target.value as string;
                value.indexOf(' ') >= 0 ? setNameHasError(true) : setNameHasError(false);
              }} />
              {nameHasError && (
                <ErrorText style={{marginTop: '10px'}}>Ihr Login enthält nicht erlaubte Leerzeichen.</ErrorText>
              )}
          </Box>
          {!isReset && (
            <Box mt="10px">
              <Input
                type="password"
                label="Passwort"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
            </Box>
          )}

          <Flex justifyContent="space-between">
            {!isReset ? (
              <Box mt="20px">
                <Button
                  buttonText="Passwort vergessen?"
                  variant="dark"
                  onClick={() => {
                    togglePasswordReset();
                  }}
                />
              </Box>
            ) : (
              <Box mt="20px">
                <Button
                  buttonText="Zurück zum Login"
                  variant="dark"
                  onClick={() => {
                    togglePasswordReset();
                  }}
                />
              </Box>
            )}
            {!isReset ? (
              <Box mt="20px">
                <Button buttonText="Login" isLoading={loading} variant="dark" onClick={handleSubmit} />
              </Box>
            ) : (
              <Box mt="20px">
                <Button buttonText="Passwort zurücksetzen" isLoading={loading} variant="dark" onClick={handleSubmit} />
              </Box>
            )}
          </Flex>
        </Box>
      </Flex>
    </MainLayout>
  );
};
export default Login;
