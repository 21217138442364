import React from "react";

export interface AddUserIconProps {}

const AddUserIcon: React.FC<AddUserIconProps> = () => {
  return (
    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.82022 8.72727C7.42386 8.72727 8.72931 7.42181 8.72931 5.81818C8.72931 4.21454 7.42386 2.90909 5.82022 2.90909C4.21659 2.90909 2.91113 4.21454 2.91113 5.81818C2.91113 7.42181 4.21659 8.72727 5.82022 8.72727Z"
        fill="white"
      />
      <path
        d="M5.82014 9.45454C2.39395 9.45454 0.00195312 11.2487 0.00195312 13.8182V14.5455H11.6383V13.8182C11.6383 11.2487 9.24632 9.45454 5.82014 9.45454Z"
        fill="white"
      />
      <path
        d="M16.0018 2.18182H13.82V0H12.3654V2.18182H10.1836V3.63636H12.3654V5.81818H13.82V3.63636H16.0018V2.18182Z"
        fill="white"
      />
    </svg>
  );
};

export default AddUserIcon;
