import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { Flex } from "@rebass/grid";

import PlusIcon from "../identity/plus-icon";
import { EventTypes } from "../../../store/types";

const StyledTextField = withStyles(() => ({
  root: {
    margin: "0 10px",
    "& > div": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
      borderRadius: 0
    },
    "& .Mui-error": {
      borderBottom: "1px solid rgba(247, 96, 96, 1)",
      borderRadius: 0
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: 0
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0
    },
    "& input": {
      borderRadius: 0,
      background: "white",
      padding: "0 5px",
      height: "36px",
      display: "flex",
      alignItems: "center",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "22px",
      color: "rgba(0, 0, 0, 0.87)"
    }
  }
}))(TextField);

export interface SelectInputProps {
  placeholder?: string;
  readOnly?: boolean;
  onSubmit: (newEventType: EventTypes) => void;
}

const SelectInput: React.FC<SelectInputProps> = ({ placeholder, readOnly, onSubmit }) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <Flex width="100%" flexDirection="column">
      <StyledTextField
        onChange={(e: React.ChangeEvent<{ value: string }>) => setInputValue(e.target.value)}
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.keyCode === 13) {
            onSubmit({ veranstaltungsTyp: inputValue });
            setInputValue("");
          } else {
            return;
          }
        }}
        value={inputValue}
        InputProps={{
          readOnly,
          placeholder: placeholder || "Neuer Typ...",
          endAdornment: (
            <PlusIcon
              color="rgba(0, 0, 0, 0.87)"
              margin="0 10px 0 0"
              onClick={() => {
                onSubmit({ veranstaltungsTyp: inputValue });
                setInputValue("");
              }}
            />
          )
        }}
        placeholder={placeholder}
      />
    </Flex>
  );
};

export default SelectInput;
