import React from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";

import Arrow from "../identity/double-arrow-icon";
import PlusIcon from "../identity/plus-icon";
import LineIcon from "../identity/line-icon";
import AddUserIcon from "../identity/add-user-icon";
import { CircularProgress } from "@material-ui/core";

const ButtonContainer = styled.button`
  position: relative;
  outline: none;
  min-width: 100px;
  height: 48px;
  border-radius: 4px;
  background: ${({ color }) => (color === "dark" ? "#0077B4" : "#ffffff")};
  border: ${({ color }) => (color === "dark" ? "none" : "2px solid rgba(0, 119, 180, 0.4)")};
  transition: filter 0.2s linear, transform 0.1s linear;
  -webkit-transition: -webkit-filter 0.2s linear, transform 0.1s linear;
  &:hover {
    cursor: pointer;
    filter: brightness(80%);
  }
  &:active {
    transform: scale(0.99);
  }
`;

interface ButtonTextProps {
  buttonText: string | Element;
  color: string;
}

const ButtonText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: ${(props: ButtonTextProps) => (props.buttonText === "ANMELDEN" ? "16px" : "14px")};
  line-height: ${(props: ButtonTextProps) => (props.buttonText === "ANMELDEN" ? "22px" : "16px")};
  color: ${(props: ButtonTextProps) => (props.color === "dark" ? "#ffffff" : "#0077B4")};
`;

export interface ButtonProps {
  onClick?: (e: any) => void;
  buttonText: string;
  variant: string;
  disabled?: boolean;
  arrow?: boolean;
  plusIcon?: boolean;
  lineIcon?: boolean;
  addUserIcon?: boolean;
  iconLeft?: boolean;
  onButtonClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  style?: any;
  isLoading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  onButtonClick,
  buttonText,
  variant,
  disabled,
  arrow,
  plusIcon,
  addUserIcon,
  iconLeft,
  lineIcon,
  style,
  isLoading,
}) => {
  return (
    <ButtonContainer type="button" onClick={onClick || onButtonClick} color={variant} disabled={disabled} style={style}>
      <Flex
        padding="0 10px"
        width="100%"
        height="100%"
        justifyContent={arrow || plusIcon || lineIcon ? "space-between" : "center"}
        alignItems="center"
      >
        <ButtonText color={variant} buttonText={buttonText}>
          {buttonText}
        </ButtonText>
        {isLoading && <CircularProgress size="1rem" style={{ color: "#ffffff", marginLeft: "10px" }} />}

        {arrow || plusIcon || addUserIcon || lineIcon ? (
          <Box order={iconLeft ? -1 : 0} margin={iconLeft ? "0 10px 0 0" : "0 0 0 10px"}>
            {arrow && <Arrow color={variant === "dark" ? "#ffffff" : "#0077B4"} left={iconLeft} />}
            {plusIcon && <PlusIcon color={variant === "dark" ? "#ffffff" : "#0077B4"} />}
            {lineIcon && <LineIcon color={variant === "dark" ? "#ffffff" : "#0077B4"} />}
            {addUserIcon && <AddUserIcon />}
          </Box>
        ) : (
          ""
        )}
      </Flex>
    </ButtonContainer>
  );
};

export default Button;
