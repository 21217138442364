import { AppThunk } from "../configureStore";

import { globalSlice } from "./reducer";

const { actions } = globalSlice;

export const { logPreviousLocation, reset } = actions;

export const setPreviousLocation = (location: any): AppThunk => async (dispatch) => {
  try {
    dispatch(logPreviousLocation(location));
  } catch (err) {
    console.log(err);
  }
};
