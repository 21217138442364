import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import AddParticipant from "../../presentational/pages/admin-view/add-participant";
import { selectParticipantById, selectSignupSuccessfull } from "../../store/adminState/edit-participants/selector";
import { selectEventById } from "../../store/adminState/edit-event/selector";
import {
  signupForEvent,
  setSignupSuccessfull,
  editCurrentParticipant
} from "../../store/adminState/edit-participants/actions";
import { Event, FormValues, Participant } from "../../store/types";
import { AppState } from "../../store/configureStore";

interface Props {
  editParticipant?: boolean;
}

export interface AddParticipantProps extends Props {
  currentEvent: Event | undefined;
  signupForEvent: (formValues: FormValues, eventId: string) => void;
  signupSuccessfull: boolean;
  setSignupSuccessfull: (value: boolean) => void;
  participant?: Participant;
  editCurrentParticipant?: (participantId: string, values: FormValues) => void;
}

const AddParticipantContainer: React.FC<Props> = ({ editParticipant }) => {
  const dispatch = useDispatch();
  const urlParams: { id: string; participantId?: string } = useParams();

  const participant = useSelector((state: AppState) => selectParticipantById(state, urlParams.participantId || ""));

  const addParticipantProps: AddParticipantProps = {
    currentEvent: useSelector((state: AppState) => selectEventById(state, urlParams.id)),
    signupForEvent: (formValues: FormValues, eventId: string) => dispatch(signupForEvent(formValues, eventId)),
    signupSuccessfull: useSelector(selectSignupSuccessfull),
    setSignupSuccessfull: useCallback((value: boolean) => dispatch(setSignupSuccessfull(value)), [dispatch]),
    editParticipant,
    participant,
    editCurrentParticipant: (participantId: string, values: FormValues) =>
      dispatch(editCurrentParticipant(participantId, values))
  };
  return <AddParticipant {...addParticipantProps} />;
};

export default AddParticipantContainer;
