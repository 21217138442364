import React from "react";

export interface LineIconProps {
  color: string;
}

const LineIcon: React.FC<LineIconProps> = ({ color }) => {
  return (
    <>
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17 5.99899C18.654 5.99899 20 4.65299 20 2.99899C20 1.34499 18.654 -0.00100708 17 -0.00100708C15.698 -0.00100708 14.599 0.836993 14.185 1.99899H0V3.99899H14.185C14.599 5.16099 15.698 5.99899 17 5.99899ZM17 1.99899C17.551 1.99899 18 2.44799 18 2.99899C18 3.55199 17.551 3.99899 17 3.99899C16.449 3.99899 16 3.55199 16 2.99899C16 2.44799 16.449 1.99899 17 1.99899Z"
          fill={color}
        />
        <path
          d="M7 5.99899C5.698 5.99899 4.599 6.83699 4.185 7.99899H0V9.99899H4.185C4.599 11.161 5.699 11.999 7 11.999C8.301 11.999 9.401 11.161 9.815 9.99899H20V7.99899H9.815C9.401 6.83699 8.302 5.99899 7 5.99899ZM7 9.99899C6.449 9.99899 6 9.55199 6 8.99899C6 8.44799 6.449 7.99899 7 7.99899C7.551 7.99899 8 8.44799 8 8.99899C8 9.55199 7.551 9.99899 7 9.99899Z"
          fill={color}
        />
        <path
          d="M17 11.999C15.698 11.999 14.599 12.837 14.185 13.999H0V15.999H14.185C14.599 17.161 15.699 17.999 17 17.999C18.654 17.999 20 16.653 20 14.999C20 13.345 18.654 11.999 17 11.999ZM17 15.999C16.449 15.999 16 15.552 16 14.999C16 14.448 16.449 13.999 17 13.999C17.551 13.999 18 14.448 18 14.999C18 15.552 17.551 15.999 17 15.999Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default LineIcon;
