import React from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import { useFormik } from "formik";

import DatePicker from "../elements/date-picker";
import Input from "../elements/input";
import Button from "../elements/button";
import closeIcon from "../../assets/close.svg";
import { FilterPropsAdmin } from "../../../container/admin-view/components/filter-container";
import { Heading } from "../identity/styled-text";

const StyledButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

const StyledInputContainer = styled(Box)`
  margin: 10px 0;
`;

const FilterComponent: React.FC<FilterPropsAdmin> = ({ filterValues, setFilter, onClose }) => {
  const formik = useFormik({
    initialValues: filterValues,
    onSubmit: values => {
      setFilter(values);
      onClose();
    },
    onReset: () => {
      setFilter({
        name: "",
        zip: "",
        place: "",
        type: "",
        period: { from: "", to: "" },
        city: "",
        speaker: "",
        searchKeywords: ""
      });
    },
    validateOnChange: false
  });

  return (
    <Flex width="100vw" justifyContent="center" backgroundColor="transparent">
      <Flex
        width="80%"
        backgroundColor="white"
        flexDirection="column"
        height="585px"
        style={{ borderRadius: 4, maxWidth: "1100px" }}
      >
        <Flex padding="30px" alignItems="center" justifyContent="space-between">
          <Heading>Suche</Heading>
          <StyledButton onClick={onClose}>
            <img src={closeIcon} alt="close-icon" />
          </StyledButton>
        </Flex>
        <>
          <Flex width="100%" backgroundColor="#F2F6F8" padding="30px" justifyContent="space-between">
            <Flex flexDirection="column" width="47.5%">
              <StyledInputContainer>
                <Input label="Name" name="name" value={formik.values.name} onChange={formik.handleChange} />
              </StyledInputContainer>
              <StyledInputContainer>
                <Input label="Postleitzahl" name="zip" value={formik.values.zip} onChange={formik.handleChange} />
              </StyledInputContainer>
              <StyledInputContainer>
                <Input
                  label="Veranstaltungsort"
                  name="place"
                  value={formik.values.place}
                  onChange={formik.handleChange}
                />
              </StyledInputContainer>
              <StyledInputContainer>
                <Input
                  label="Veranstaltungstyp"
                  name="type"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                />
              </StyledInputContainer>
            </Flex>
            <Flex flexDirection="column" width="47.5%">
              <Flex justifyContent="space-between" margin="10px 0">
                <Box width="45%">
                  <DatePicker
                    label="von:"
                    name="period.from"
                    value={formik.values.period.from || null}
                    onChange={value => {
                      value?.setHours(0,0,0,0);
                      formik.setFieldValue("period.from", value?.toString() ||  "");
                  }}
                  />
                </Box>
                <Box width="45%">
                  <DatePicker
                    label="bis:"
                    name="period.to"
                    value={formik.values.period.to || null}
                    onChange={value => {
                      value?.setHours(0,0,0,0);
                      formik.setFieldValue("period.to", value?.toString() || "")
                    }}
                  />
                </Box>
              </Flex>
              <StyledInputContainer>
                <Input label="Stadt" name="city" value={formik.values.city} onChange={formik.handleChange} />
              </StyledInputContainer>
              <StyledInputContainer>
                <Input label="Referent" name="speaker" value={formik.values.speaker} onChange={formik.handleChange} />
              </StyledInputContainer>
              <StyledInputContainer>
                <Input
                  label="Suchbegriff"
                  name="searchKeywords"
                  value={formik.values.searchKeywords}
                  onChange={formik.handleChange}
                />
              </StyledInputContainer>
            </Flex>
          </Flex>
          <Flex backgroundColor="#F2F6F8" padding="0 30px 35px 0" width="100%" justifyContent="flex-end">
            <Box mr="25px">
              <Button
                buttonText="Einträge löschen"
                variant="light"
                onClick={(e: MouseEvent) => {
                  formik.handleReset(e);
                  formik.setValues({
                    name: "",
                    zip: "",
                    place: "",
                    type: "",
                    period: { from: null, to: null },
                    city: "",
                    speaker: "",
                    searchKeywords: ""
                  });
                }}
              />
            </Box>
            <Button buttonText="Suchen" variant="dark" arrow onClick={formik.handleSubmit} />
          </Flex>
        </>
      </Flex>
    </Flex>
  );
};

export default FilterComponent;
