import React from "react";

export interface DoubleArrowProps {
  color: string;
  left?: boolean;
}

const DoubleArrow: React.FC<DoubleArrowProps> = ({ color, left }) => {
  return (
    <div
      style={{
        transform: left ? `rotate(180deg)` : "",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 0L0 2L4 6L0 10L2 12L8 6L2 0Z" fill={color} />
        <path d="M7.65686 0L5.65686 2L9.65686 6L5.65686 10L7.65686 12L13.6569 6L7.65686 0Z" fill={color} />
      </svg>
    </div>
  );
};

export default DoubleArrow;
