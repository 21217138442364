import React, { useEffect } from "react";
import styled from "styled-components";
import { Flex } from "@rebass/grid";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

import MainLayout from "../../layouts/main-admin-layout";
import SignupFormComponent from "../../components/user-view/signup-form";
import { AddParticipantProps } from "../../../container/admin-view/page-add-participant";
import Button from "../../components/elements/button";
import { PageHeadline, NormalTextBlack } from "../../components/identity/styled-text";
import { Box } from "@material-ui/core";

const StyledFormattedDate = styled(Box)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #0077b4;
`;

const AddParticipant: React.FC<AddParticipantProps> = ({
  currentEvent,
  signupForEvent,
  signupSuccessfull,
  setSignupSuccessfull,
  editParticipant,
  participant,
  editCurrentParticipant,
}) => {
  useEffect(() => {
    if (signupSuccessfull === true) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      return;
    }
  }, [signupSuccessfull]);

  useEffect(() => {
    setSignupSuccessfull(false);
  }, [setSignupSuccessfull]);

  return (
    <MainLayout>
      <Flex flexDirection="column" width="100%">
        {signupSuccessfull ? (
          <Flex flexDirection="column" alignItems="center" justifyContent="center" mt="100px" mb="100px">
            <PageHeadline style={{ marginBottom: "20px", textAlign: "center" }}>
              {editParticipant
                ? "Das Bearbeiten des Teilnehmers war erfolgreich"
                : currentEvent?.teilnehmerkapazitaet === currentEvent?.teilnehmerzahl
                ? "Der Teilnehmer wurde erfolgreich auf die Warteliste gesetzt. Sie erhalten von uns eine E-Mail mit weiteren Informationen."
                : "Ihre Anmeldung war erfolgreich. Sie erhalten von uns eine E-Mail mit weiteren Informationen."}
            </PageHeadline>
            <Link to={{ pathname: `/${currentEvent?.veranstaltungId}/manage-participants` }}>
              <Button buttonText="Zurück zur Übersicht" arrow iconLeft variant="light" />
            </Link>
          </Flex>
        ) : (
          <Flex margin="0 auto" flexDirection="column" style={{ maxWidth: "900px" }}>
            <Flex justifyContent="space-between" margin="30px 0 45px 0">
              <PageHeadline>
                {editParticipant
                  ? "Teilnehmer bearbeiten"
                  : currentEvent?.teilnehmerkapazitaet === currentEvent?.teilnehmerzahl
                  ? "Teilnehmer auf die Warteliste setzen"
                  : "Teilnehmer anmelden"}
              </PageHeadline>
              <Link to={{ pathname: `/${currentEvent?.veranstaltungId}/manage-participants` }}>
                <Button buttonText="Zurück zur Übersicht" arrow iconLeft variant="light" />
              </Link>
            </Flex>
            <NormalTextBlack>{currentEvent?.name}</NormalTextBlack>
            <Flex>
              <StyledFormattedDate>
                {dateFormat(currentEvent?.startTagStartZeit, "dd.MM.yyyy", true)} -{" "}
                {dateFormat(currentEvent?.startTagEndZeit, "dd.MM.yyyy", true)}
              </StyledFormattedDate>
            </Flex>

            <SignupFormComponent
              inAdminView
              participant={participant}
              editCurrentParticipant={editCurrentParticipant}
              editParticipant={editParticipant}
              eventId={currentEvent?.veranstaltungId || ""}
              withImageUpload={currentEvent?.mitFotoUpload || false}
              isRegistrable={true}
              signupForEvent={signupForEvent}
              felder={currentEvent?.formular ? currentEvent?.formular.felder : []}
            />
          </Flex>
        )}
      </Flex>
    </MainLayout>
  );
};

export default AddParticipant;
