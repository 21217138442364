import React, { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

import DateDetails from "../../../presentational/components/admin-view/create-event/date-details";
import {
  setDateDetailsData,
  setNewEventDateDetailsSearchKeywords,
  addEventDay,
  removeLastEventDay,
  addEventType,
  deleteEventType,
  setNewEventsEventDay,
  editAdditionalDays,
  setNewEventsRegistrationDeadline,
} from "../../../store/adminState/create-event/actions";
import {
  selectNewEventsSearchKeywords,
  selectEventAdditionalDays,
} from "../../../store/adminState/create-event/selector";
import { DateDetailValues, EventTypes } from "../../../store/types";
import { FormikErrors } from "formik";

interface Props {
  values: {
    eventDay: { datum: string; startZeit: string; endZeit: string };
    additionalDays: { datum?: string; startZeit?: string; endZeit?: string }[];
    registrationDeadline: string;
    eventType: string;
    maxNumberOfParticipants: string;
    registrationForm: boolean;
    forceImageUpload: boolean;
  };
  onChange: (eventOrPath: string | ChangeEvent<any>) => void | ((eventOrTextValue: string | ChangeEvent<any>) => void);
  setFieldValue: (fieldName: string, value: string | boolean) => void;
  errors?: FormikErrors<DateDetailValues>;
  eventTypes: EventTypes[];
}

export interface DateDetailsProps extends Props {
  setDateDetailsData: (values: DateDetailValues) => void;
  setSearchKeywords: (value: string[]) => void;
  addEventDay: () => void;
  removeLastDay: () => void;
  additionalDays: { datum?: string; startZeit?: string; endZeit?: string }[];
  searchKeywords: string[];
  addEventType: (newEventType: EventTypes) => void;
  deleteEventType: (nameOfEventType: string) => void;
  setNewEventsEventDay: (date: string) => void;
  editAdditionalDays: (value: string, index: number) => void;
  setNewEventsRegistrationDeadline: (value: string) => void;
}

const DateDetailsContainer: React.FC<Props> = ({ values, onChange, setFieldValue, errors, eventTypes }) => {
  const dispatch = useDispatch();
  const dateDetailsProps: DateDetailsProps = {
    values,
    onChange,
    setFieldValue,
    setDateDetailsData: (values: DateDetailValues) => dispatch(setDateDetailsData(values)),
    setNewEventsEventDay: (value: string) => dispatch(setNewEventsEventDay(value)),
    editAdditionalDays: (value: string, index: number) => dispatch(editAdditionalDays(value, index)),
    setNewEventsRegistrationDeadline: (value: string) => dispatch(setNewEventsRegistrationDeadline(value)),
    setSearchKeywords: (value: string[]) => dispatch(setNewEventDateDetailsSearchKeywords(value)),
    addEventDay: () => dispatch(addEventDay()),
    removeLastDay: () => dispatch(removeLastEventDay()),
    addEventType: (newEventType: EventTypes) => dispatch(addEventType(newEventType)),
    deleteEventType: (nameOfEventType: string) => dispatch(deleteEventType(nameOfEventType)),
    searchKeywords: useSelector(selectNewEventsSearchKeywords),
    additionalDays: useSelector(selectEventAdditionalDays),
    eventTypes,
    errors,
  };

  return <DateDetails {...dateDetailsProps} />;
};

export default DateDetailsContainer;
