import React from "react";
import styled from "styled-components";
import { Flex } from "@rebass/grid";

import PlusIcon from "../identity/plus-icon";
import LineIcon from "../identity/line-icon";
import ArrowIcon from "../identity/double-arrow-icon";
import SearchIcon from "../identity/search-icon";
import FileIcon from "../identity/file-icon";

const ButtonContainer = styled.button`
  position: relative;
  outline: none;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  background: ${({ color }) => (color === "dark" ? "#0077B4" : "#ffffff")};
  border: ${({ color }) => (color === "dark" ? "none" : "2px solid rgba(0, 119, 180, 0.4)")};
  transition: filter 0.2s linear, transform 0.1s linear;
  -webkit-transition: -webkit-filter 0.2s linear, transform 0.1s linear;
  &:hover {
    cursor: pointer;
    filter: brightness(80%);
  }
  &:active {
    transform: scale(0.99);
  }
`;

export interface ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant: string;
  plusIcon?: boolean;
  lineIcon?: boolean;
  searchIcon?: boolean;
  arrowIcon?: boolean;
  fileIcon?: boolean;
  left?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  variant,
  plusIcon,
  lineIcon,
  searchIcon,
  arrowIcon,
  fileIcon,
  left
}) => {
  return (
    <ButtonContainer type="button" onClick={onClick} color={variant}>
      <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
        {plusIcon && <PlusIcon color={variant === "dark" ? "#ffffff" : "#0077B4"} />}
        {lineIcon && <LineIcon color={variant === "dark" ? "#ffffff" : "#0077B4"} />}
        {searchIcon && <SearchIcon color={variant === "dark" ? "#ffffff" : "#0077B4"} />}
        {arrowIcon && <ArrowIcon color={variant === "dark" ? "#ffffff" : "#0077B4"} left={left} />}
        {fileIcon && <FileIcon />}
      </Flex>
    </ButtonContainer>
  );
};

export default Button;
