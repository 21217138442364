import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import styled from "styled-components";
import { Flex } from "@rebass/grid";
import { FormikErrors } from "formik";
import { ErrorText } from "../identity/styled-text";

const StyledTextField = withStyles(() => ({
  root: {
    width: "100%",
    "& > div": {
      border: "1px solid rgba(0, 119, 180, 0.8)",
      borderRadius: 4
    },
    "& .Mui-error": {
      border: "1px solid rgba(247, 96, 96, 1)",
      borderRadius: 4
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: 0
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0
    },
    "& input": {
      borderRadius: 4,
      background: "white",
      padding: "0 20px",
      height: "46px",
      display: "flex",
      alignItems: "center",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "22px",
      color: "#0077B4"
    }
  }
}))(TextField);

const LabelText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 4px;
`;

export interface InputProps {
  type?: string;
  label?: string;
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  placeholder?: string;
  name?: string;
  value?: string;
  onBlur?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  readOnly?: boolean;
}

const Input: React.FC<InputProps> = ({ type, label, onChange, placeholder, name, value, onBlur, error, readOnly }) => {
  return (
    <Flex width="100%" flexDirection="column">
      {label && <LabelText>{label}</LabelText>}
      <StyledTextField
        type={type}
        InputProps={{
          readOnly
        }}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        name={name}
        value={value}
        error={!!error}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </Flex>
  );
};

export default Input;
