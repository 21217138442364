import React, { useEffect, useRef, useLayoutEffect, useCallback } from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import dateFormat from "dateformat";
import { Link, useHistory } from "react-router-dom";
import Button from "./../../components/elements/button";

import MainLayout from "../../layouts/main-user-layout";
import SignupFormComponent from "../../components/user-view/signup-form";
import PrintIcon from "../../assets/print.svg";
import { EventDetailsProps } from "../../../container/user-view/page-event-details";
import { PageHeadline, NormalTextBlack, NormalText } from "../../components/identity/styled-text";

const InfoTextSmall = styled(Box)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
`;

const InfoTextSmallBlue = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #0077b4;
`;

const EventCardContainer = styled(Flex)`
  border-bottom: 1px solid rgba(0, 119, 180, 0.5);
`;

const PrintIconContainer = styled.div`
  align-self: flex-end;
  margin-top: 60px;
`;

const EventDetails: React.FC<EventDetailsProps> = ({
  veranstaltungId,
  name,
  bildLink,
  ort,
  beschreibung,
  eventFullyBooked,
  startTagStartZeit,
  startTagEndZeit,
  anmeldeSchlussZeitpunkt,
  referent,
  formular,
  signupSuccessfull,
  verfuegbarePlaetze,
  setSignupSuccessfull,
  signupForEvent,
  reloadCurrentEvent,
  teilnehmerkapazitaet,
  withImageUpload,
  zusaetzlicheTage,
}) => {
  const signupForm = useRef<HTMLDivElement>(null);
  const history = useHistory();

  useEffect(() => {
    if (signupSuccessfull === true) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      return;
    }
  }, [signupSuccessfull]);

  useLayoutEffect(() => {
    if (history.location.state === "toSignup") {
      window.scrollTo(0, 0);
      setTimeout(() => {
        if (signupForm.current !== null) {
          window.scrollTo({
            top: signupForm.current.offsetTop - 60,
            behavior: "smooth",
          });
        } else {
          return;
        }
      }, 500);
    } else {
      window.scrollTo(0, 0);
    }
  }, [history, signupForm]);

  useEffect(() => {
    setSignupSuccessfull(false);
  }, [setSignupSuccessfull]);

  const printPage = useCallback(() => {
    window.print();
  }, []);

  return (
    <MainLayout>
      <Flex flexDirection="column">
        {signupSuccessfull ? (
          <>
            <PageHeadline style={{ textAlign: "center", marginBottom: "20px" }}>
              {eventFullyBooked
                ? "Sie wurden auf die Warteliste gesetzt. Wir schicken Ihnen eine E-Mail, sobald ein Platz frei geworden ist."
                : "Ihre Anmeldung war erfolgreich. Sie erhalten von uns eine E-Mail mit weiteren Informationen."}
            </PageHeadline>

            <Flex marginTop="20px" justifyContent="center">
              <Button
                buttonText="Weiteren Teilnehmer anmelden"
                variant="dark"
                onClick={() => {
                  setSignupSuccessfull(false);
                  reloadCurrentEvent();
                }}
              />
              <Link to={{ pathname: `/` }}>
                <Button style={{ marginLeft: "20px" }} buttonText="Zurück zur Veranstaltungsübersicht" variant="dark" />
              </Link>
            </Flex>
          </>
        ) : (
          <>
            <PageHeadline style={{ marginBottom: "20px" }}>{name}</PageHeadline>
            <NormalTextBlack dangerouslySetInnerHTML={{ __html: beschreibung }} />
            {referent && <Box style={{ marginTop: "20px" }}></Box>}
            <EventCardContainer flexDirection="row" marginTop="50px" paddingBottom="30px">
              <Box width="255px">
                <img
                  src={bildLink}
                  alt="eventImage"
                  style={{
                    width: "100%",
                    maxWidth: "255px",
                    maxHeight: "170px",
                  }}
                />
              </Box>
              <Box width={1 / 3} marginLeft="30px">
                <NormalText>Veranstaltungsinfos</NormalText>
                <InfoTextSmall mt="10px">
                  <strong>{dateFormat(startTagStartZeit, "dd.mm.yyyy", true)}</strong> |{" "}
                  {dateFormat(startTagStartZeit, "HH:MM", true)}-{dateFormat(startTagEndZeit, "HH:MM", true)}
                </InfoTextSmall>
                {zusaetzlicheTage &&
                  zusaetzlicheTage.length > 0 &&
                  zusaetzlicheTage.map((day) => (
                    <InfoTextSmall key={day.startZeit}>
                      <strong>{dateFormat(day.startZeit, "dd.mm.yyyy", true)}</strong> |{" "}
                      {dateFormat(day.startZeit, "HH:MM", true)}-{dateFormat(day.endZeit, "HH:MM", true)}
                    </InfoTextSmall>
                  ))}
                {ort && <InfoTextSmall mt="10px">{ort.gebaeudename}</InfoTextSmall>}

                <Box mt="12px">
                  <Box>
                    <InfoTextSmallBlue>
                      {teilnehmerkapazitaet && teilnehmerkapazitaet !== 0 && verfuegbarePlaetze !== undefined
                        ? `${verfuegbarePlaetze >= 0 ? verfuegbarePlaetze : 0} verfügbare Plätze`
                        : "Keine Teilnehmerbegrenzung"}
                    </InfoTextSmallBlue>
                  </Box>

                  <Box>
                    <InfoTextSmallBlue>
                      Anmeldeschluss: {dateFormat(anmeldeSchlussZeitpunkt, "dd.mm.yyyy", true)}
                    </InfoTextSmallBlue>
                  </Box>
                </Box>
              </Box>
            </EventCardContainer>
            <div ref={signupForm} />

            <SignupFormComponent
              availableSeats={verfuegbarePlaetze}
              signupForEvent={signupForEvent}
              eventId={veranstaltungId}
              withImageUpload={withImageUpload}
              isRegistrable={new Date(anmeldeSchlussZeitpunkt) > new Date()}
              felder={formular ? formular.felder : []}
            />
            <PrintIconContainer>
              <img
                style={{ cursor: "pointer" }}
                src={PrintIcon}
                onClick={printPage}
                alt="print-icon"
                height="33px"
                width="26px"
              />
            </PrintIconContainer>
          </>
        )}
      </Flex>
    </MainLayout>
  );
};
export default EventDetails;
