import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import styled from "styled-components";
import { Flex } from "@rebass/grid";

import closeIcon from "../../assets/close.svg";
import { LabelText } from "../identity/styled-text";

const StyledTextField = withStyles(() => ({
  root: {
    width: "100%",
    "& > div": {
      border: "none",
      borderRadius: 4
    },
    "& .Mui-error": {
      border: "none",
      borderRadius: 4
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: 0
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0
    },
    "& input": {
      borderRadius: 4,
      background: "white",
      padding: "0 10px",
      height: "46px",
      display: "flex",
      alignItems: "center",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "22px",
      color: "#0077B4"
    }
  }
}))(TextField);

const ItemContainer = styled.div`
  display: inline-flex;
  align-items: center;
  background: #0077b4;
  color: white;
  margin: 0 3px;
  padding: 4px;
  height: 20px;
  border: 1px solid rgba(0, 119, 180, 0.8);
  font-size: 14px;
  font-family: Open Sans;
  border-radius: 2px;
  cursor: pointer;
`;

const StyledInputContainer = styled(Flex)`
  align-items: center;
  border: 1px solid rgba(0, 119, 180, 0.8);
  background: white;
  border-radius: 4px;
  padding: 0 10px;
`;

interface TagInputProps {
  setSearchKeywords: (value: string[]) => void;
  searchKeywords: string[];
}

const TagInput: React.FC<TagInputProps> = ({ setSearchKeywords, searchKeywords }) => {
  const [input, setInput] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      const { value } = e.target as HTMLInputElement;

      setSearchKeywords([...searchKeywords, value]);
      setInput("");
    }

    if (searchKeywords.length && e.keyCode === 8 && !input.length) {
      setSearchKeywords(searchKeywords.slice(0, searchKeywords.length - 1));
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (input) {
      const { value } = e.target as HTMLInputElement;

      setSearchKeywords([...searchKeywords, value]);
      setInput("");
    }
  };

  const handleRemoveItem = (index: number) => {
    setSearchKeywords(searchKeywords.filter((item, i) => i !== index));
  };

  return (
    <Flex width="100%" flexDirection="column">
      <LabelText>Suchbegriffe</LabelText>
      <StyledInputContainer>
        {searchKeywords &&
          searchKeywords.length > 0 &&
          searchKeywords.map((item, i) => (
            <ItemContainer key={i} onClick={() => handleRemoveItem(i)}>
              {item}
              <img
                src={closeIcon}
                alt="close-icon"
                style={{ width: "10px", height: "10px", borderRadius: "15px", background: "white", marginLeft: "3px" }}
              />
            </ItemContainer>
          ))}
        <StyledTextField
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          onBlur={handleBlur}
        />
      </StyledInputContainer>
    </Flex>
  );
};

export default TagInput;
