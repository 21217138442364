import React from "react";
import styled from "styled-components";
import { Flex } from "@rebass/grid";
import dateFormat from 'dateformat';
import Button from "../../components/elements/button";
import MainLayout from "../../layouts/main-user-layout";
import { CancellationProps } from "../../../container/user-view/cancellation-container";
import { Heading, PageHeadline, NormalTextBlack } from "../../components/identity/styled-text";

const BoldNormalText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-weight: normal;
  font-size: 22px;
  line-height: 22px;
  color: #000000;
`;

const LargeText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 30px;
  color: #000000;
`;

const Cancellation: React.FC<CancellationProps> = ({
  signupId,
  eventName,
  eventShortDesc,
  eventDate,
  signoffFromEvent,
  signoffSuccessful
}) => {
  return (
    <MainLayout>
      <Flex flexDirection="column">
        {signoffSuccessful ? (
          <PageHeadline style={{ textAlign: "center" }}>Ihre Abmeldung war erfolgreich.</PageHeadline>
        ) : (
          <>
            <PageHeadline>Abmeldung</PageHeadline>
            <LargeText>
              Hier können Sie sich von der u.g. Schulung abmelden. Eine Abmeldung kann nicht rückgängig gemacht werden,
              da die freigewordenen Plätze automatisch an andere Interessenten weitergegeben werden.
            </LargeText>
            <Heading style={{ marginBottom: "5px" }}>Seminar/Schulung:</Heading>
            <BoldNormalText>{eventName}</BoldNormalText>
            <NormalTextBlack style={{ marginTop: "10px" }}>{eventShortDesc}</NormalTextBlack>
            <NormalTextBlack style={{ marginTop: "10px" }}>
              <strong>
              { dateFormat(eventDate ? eventDate.startZeit : "", 'dd.mm.yyyy',  true) }
              </strong>{" "}
              | { dateFormat(eventDate ? eventDate.startZeit : "", 'HH:MM',  true) } -
              { dateFormat(eventDate ? eventDate.endZeit : "", 'HH:MM',  true) }
            </NormalTextBlack>
            <Button buttonText="ABMELDEN" variant="dark" onClick={() => signoffFromEvent(signupId)} />
          </>
        )}
      </Flex>
    </MainLayout>
  );
};
export default Cancellation;
