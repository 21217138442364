import { Event, Filter } from ".";

export const GET_EVENTS = "GET_EVENTS";
export const FILTER_EVENTS = "FILTER_EVENTS";

export interface GetEventAction {
  type: typeof GET_EVENTS;
  events: Event[];
}

export interface GetFilteredEvents {
  type: typeof FILTER_EVENTS;
  filter: Filter;
  events: Event[];
}

export type EventActionTypes = GetEventAction;
export type FilterActionTypes = GetFilteredEvents;

export type AppActions = EventActionTypes | FilterActionTypes;

export function checkForDate(value: string): boolean {
  let parsedDate = Date.parse(value);
  if (!+value && !isNaN(parsedDate)) {
    return true;
  }
  return false;
}
