import React, { ChangeEvent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import styled from "styled-components";
import { Flex } from "@rebass/grid";
import CloseIcon from "../identity/close-icon";

const StyledTextField = withStyles(() => ({
  root: {
    width: "100%",
    "& > div": {
      borderBottom: "1px solid rgba(0, 119, 180, 0.8)",
      borderRadius: 0
    },
    "& .Mui-error": {
      border: "1px solid rgba(247, 96, 96, 1)",
      borderRadius: 0
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: 0
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0
    },
    "& input": {
      borderRadius: 4,
      background: "transparent",
      padding: "0 20px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "19px",
      color: "#0077B4"
    }
  }
}))(TextField);

const Container = styled(Flex)`
  border: 1px solid rgba(0, 119, 180, 0.8);
  border-radius: 4px;
`;

const AddOptionContainer = styled(Flex)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #0077b4;
  cursor: pointer;
`;

export interface SelectOptionsProps {
  setSelectOptions: (values: string[]) => void;
  placeholder?: string;
  readOnly?: boolean;
  values: string[];
}

const SelectOptions: React.FC<SelectOptionsProps> = ({ setSelectOptions, placeholder, readOnly, values }) => {
  const onChange = (index: number, e: ChangeEvent<{ value: unknown }>) => {
    let newOptions = [...values];
    newOptions[index] = e.target.value as string;

    setSelectOptions(newOptions);
  };

  const addOption = () => {
    let newOptions = [...values];
    newOptions.push("");

    setSelectOptions(newOptions);
  };

  const removeOption = (index: number) => {
    let newOptions = [...values];
    newOptions.splice(index, 1);

    setSelectOptions(newOptions);
  };

  return (
    <Container width="100%" flexDirection="column" mt="15px">
      {values.map((value: string, index: number) => (
        <StyledTextField
          key={index}
          InputProps={{
            readOnly,
            endAdornment:
              index >= 2 ? (
                <CloseIcon
                  backgroundColor="#0077B4"
                  hoverColor="#0077B4"
                  top="10px"
                  onClick={() => removeOption(index)}
                />
              ) : (
                ""
              )
          }}
          onChange={(e: ChangeEvent<{ value: unknown }>) => onChange(index, e)}
          placeholder={placeholder}
          value={value}
        />
      ))}
      <AddOptionContainer height="38px" px="20px" alignItems="center" onClick={addOption}>
        + Option hinzufügen
      </AddOptionContainer>
    </Container>
  );
};

export default SelectOptions;
