import { combineReducers } from "redux";
import { configureStore, Action, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { ThunkAction } from "redux-thunk";
import storage from "redux-persist/lib/storage";

import userReducer from "./userState/reducer";
import adminReducer from "./adminState/reducer";
import editEventReducer from "./adminState/edit-event/reducer";
import editParticipantsReducer from "./adminState/edit-participants/reducer";
import createEventReducer from "./adminState/create-event/reducer";
import globalReducer from "./globalState/reducer";

export const rootReducer = combineReducers({
  userState: userReducer,
  adminState: combineReducers({
    events: adminReducer,
    editEventState: editEventReducer,
    editParticipantsState: editParticipantsReducer,
    createEventState: createEventReducer
  }),
  globalState: globalReducer
});

export type AppState = ReturnType<typeof rootReducer>;

const persistConfig = {
  key: "root",
  storage
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, AppState, null, Action<string>>;

export const persistor = persistStore(store);

export default store;
