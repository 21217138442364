import React from "react";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RedirectValue } from "../../store/types";
import { verifyRedirect } from "../../store/userState/actions";
import { selectUserIsAuthenticated } from "../../store/userState/selector";
import RedirectLoginPage from "../../presentational/pages/user-view/redirect-login";
import { getPrevLocation, clearPrevLocation } from "../../services/local-storage";

interface Props {}

export interface RedirectLoginProps {}

const RedirectLogin: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectUserIsAuthenticated);
  const { token } = useParams();
  const redirectValue: RedirectValue = {
    token: token,
  };

  dispatch(verifyRedirect(redirectValue));

  const redirectLoginProps: RedirectLoginProps = {};

  let redirectUrl = "/";
  if (isAuthenticated) {
    redirectUrl = getPrevLocation() ?? "/";
    clearPrevLocation();
  }

  return isAuthenticated ? <Redirect to={redirectUrl} /> : <RedirectLoginPage {...redirectLoginProps} />;
};

export default RedirectLogin;
