import React from "react";
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "../store/adminState/selector";
import { selectUserIsAuthenticated } from "../store/userState/selector";
import { setPrevLocation } from "../services/local-storage";

const PrivateUserRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  //implement conditional check if admin or vpw user is logged in
  const location = useLocation();

  const adminIsAuthenticated = useSelector(selectIsAuthenticated);
  const userIsAuthenticated = useSelector(selectUserIsAuthenticated);

  let authenticated: boolean = false;

  if (adminIsAuthenticated || userIsAuthenticated) {
    authenticated = true;
  }

  if (!authenticated) {
    setPrevLocation(location.pathname);
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        authenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/vpw",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateUserRoute;
