import React, {useState} from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import { Link } from "react-router-dom";

import NavigationLogo from "../../assets/ewe-netz-logo.svg";
import UserIcon from "../../assets/user.svg";
import { HeaderNavigationAdminProps } from "../../../container/admin-view/components/header-navigation-admin-container";
import {  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import Button from "../elements/button";

const HeaderBar = styled(Flex)`
  position: fixed;
  overflow: hidden;
  top: 0;
  z-index: 30;
  background-color: #f2f6f8;
`;

const ContentContainer = styled(Flex)`
  max-width: 1200px;
  height: 100%;
  position: relative;

  @media (max-width: 1200px) {
    width: 95%;
  }
`;

const Version = styled(Box)`
  margin-left: 100px;
  background: #0077B4;
  border: none;
  color: #fff;
  outline: none;
  border-radius: 20px;
  background:#0077B4;
  border: none;
  padding: 5px 10px;
  font-size: 12px;
`;

const StyledLogoutText = styled.span`
  font-size: 14px;
  cursor: pointer;
`;


const HeaderText = styled.div`
  font-family: Open Sans;
  font-style: italic;
  font-weight: bold;
  font-size: 13px;
  line-height: 120%;
  text-transform: uppercase;
  color: #0077b4;
  margin-left: 20px;
`;

const HeaderNavigation: React.FC<HeaderNavigationAdminProps> = ({ firstName, lastName, role, isAuthenticated, onLogoutClick }) => {
  const [isLogoutOpenend, setIsLogoutOpenend] = useState(false);

  const userDetails = isAuthenticated ? (
    <Flex>
      <Box>
      <img src={UserIcon} alt="user-icon" style={{ marginRight: "10px" }} /> 
      </Box>
      <Box>{firstName}, {lastName} {role}<br />
      <StyledLogoutText onClick={() => setIsLogoutOpenend(true)}>Logout</StyledLogoutText>
      </Box>
    </Flex>
  ) : (
    <Box />
  );
  return (
    <>
    <HeaderBar alignItems="center" justifyContent="center" height="95px" width="100%">
      <ContentContainer alignItems="center" width="80%" justifyContent="space-between">
        <Flex height="100%" alignItems="center">
          <Link to={{ pathname: "/event-overview" }}>
            <img src={NavigationLogo} alt="ewe-logo" height="30px" width="165px" />
          </Link>
          <HeaderText>
            SEMINARE &<br />
            Schulungen
          </HeaderText>
          <Version>
          {process.env.REACT_APP_VERSION}
        </Version>
        </Flex>
       
        {userDetails}
      </ContentContainer>

    </HeaderBar>
    <Dialog
        open={isLogoutOpenend}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Logout</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Wollen Sie sich wirklich abmelden?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Flex justifyContent="space-between" width="100%">
            <Button buttonText="Nein" variant="dark" onClick={() => {setIsLogoutOpenend(false)}} />
            <Button variant="dark" buttonText="Ja" onClick={onLogoutClick} />
          </Flex>
        </DialogActions>
      </Dialog></>  
  );
};

export default HeaderNavigation;
