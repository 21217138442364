import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Flex } from "@rebass/grid";
import { Link } from "react-router-dom";

import NavigationLogo from "../../assets/ewe-netz-logo.svg";
import Button from "../elements/button";
import { clearToken } from "./../../../services/local-storage";
import { LEGAL } from "../../../config";

const HeaderBar = styled(Flex)`
  position: fixed;
  overflow: visible;
  top: 0;
  z-index: 1;
  background-color: white;
`;

const NavOption = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0077b4;
  padding: 20px 10px 30px 10px;
  cursor: pointer;
`;

const NavLink = styled.a`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  width: max-content;
  color: #0077b4;
  margin: 10px 0 8px 0;
  padding-bottom: 2px;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 0.3s ease-out;
  text-decoration: none;

  :hover {
    border-bottom-color: #0077b4;
  }
`;

const StyledLink = styled(Link)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  width: max-content;
  color: #0077b4;
  margin: 10px 0 8px 0;
  padding-bottom: 2px;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 0.3s ease-out;
  text-decoration: none;

  :hover {
    border-bottom-color: #0077b4;
  }
`;

const NestedMenu = styled.div`
  width: 100%;
  background-color: white;
  z-index: 5;
  position: fixed;
  left: 0;
  top: 110px;
  box-shadow: 0px 12px 10px 0 rgba(0, 0, 0, 0.25);
  height: 0;
  display: block;
  opacity: 0;
  transition: all 0.3s ease-out;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 0;
  opacity: 0;
  width: max-content;
  position: absolute;
  top: 50px;
  margin: 10px;
  z-index: 10;
  transition: all 0.5s ease-out;
`;

const HeaderNavigation: React.FC = () => {
  const [open, setOpen] = useState(true);
  const [selectedNavItem, setSelectedNavItem] = useState("");
  const [hoveringNestedMenu, setHoveringNestedMenu] = useState(false);
  const [hoveringMenu, setHoveringMenu] = useState(false);

  useEffect(() => {
    if (open) {
      if (!hoveringMenu && !hoveringNestedMenu) {
        setOpen(false);
      }
    } else {
      if (hoveringMenu || hoveringNestedMenu) {
        setOpen(true);
      }
    }
  }, [selectedNavItem, hoveringNestedMenu, hoveringMenu, open]);

  return (
    <HeaderBar flexDirection="column" alignItems="center" justifyContent="flex-start" height="120px" width="100%">
      <Flex justifyContent="flex-end" alignItems="flex-start" width="60%">
        <a href="https://www.ewe-netz.de/auth/logout">
          <Button
            buttonText="LOGOUT"
            onClick={clearToken}
            variant="dark"
            style={{ borderRadius: "0 0 4px 4px", height: "42px" }}
          />
        </a>
      </Flex>
      <Flex alignItems="baseline" width="80%" height="100%" justifyContent="space-between">
        <Flex>
          <img src={NavigationLogo} alt="ewe-logo" height="42px" width="230px" />
        </Flex>
        <div style={{ width: "100%" }}>
          <Flex
            justifyContent="center"
            width="100%"
            onMouseEnter={() => setHoveringMenu(true)}
            onMouseLeave={() => setHoveringMenu(false)}
          >
            <Flex style={{ position: "relative" }}>
              <NavOption onMouseEnter={() => setSelectedNavItem("STROM")}>Strom</NavOption>
              <Content
                style={{
                  height: selectedNavItem === "STROM" && open ? "100%" : "0",
                  opacity: selectedNavItem === "STROM" && open ? "1" : "0",
                }}
              >
                {open && selectedNavItem === "STROM" && (
                  <>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/strom/anweisungswerke">
                      Anweisungswerke
                    </NavLink>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/strom/formulare">Formulare</NavLink>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/strom/archiv">Archiv</NavLink>
                  </>
                )}
              </Content>
            </Flex>
            <Flex style={{ position: "relative" }}>
              <NavOption onMouseEnter={() => setSelectedNavItem("GAS")}>Gas</NavOption>
              <Content
                style={{
                  height: selectedNavItem === "GAS" ? "100%" : "0",
                  opacity: selectedNavItem === "GAS" ? "1" : "0",
                }}
              >
                {open && selectedNavItem === "GAS" && (
                  <>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/telekommunikation/anweisungswerke">
                      Anweisungswerke
                    </NavLink>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/telekommunikation/formulare">
                      Formulare
                    </NavLink>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/telekommunikation/archiv">Archiv</NavLink>
                  </>
                )}
              </Content>
            </Flex>
            <Flex style={{ position: "relative" }}>
              <NavOption onMouseEnter={() => setSelectedNavItem("WASSER")}>Wasser</NavOption>
              <Content
                style={{
                  height: selectedNavItem === "WASSER" ? "100%" : "0",
                  opacity: selectedNavItem === "WASSER" ? "1" : "0",
                }}
              >
                {open && selectedNavItem === "WASSER" && (
                  <>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/wasser/anweisungswerke">
                      Anweisungswerke
                    </NavLink>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/wasser/formulare">Formulare</NavLink>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/wasser/archiv">Archiv</NavLink>
                  </>
                )}
              </Content>
            </Flex>
            <Flex style={{ position: "relative" }}>
              <NavOption onMouseEnter={() => setSelectedNavItem("TELEKOMMUNIKATION")}>Telekommunikation</NavOption>
              <Content
                style={{
                  height: selectedNavItem === "TELEKOMMUNIKATION" ? "100%" : "0",
                  opacity: selectedNavItem === "TELEKOMMUNIKATION" ? "1" : "0",
                }}
              >
                {open && selectedNavItem === "TELEKOMMUNIKATION" && (
                  <>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/telekommunikation/anweisungswerke">
                      Anweisungswerke
                    </NavLink>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/telekommunikation/formulare">
                      Formulare
                    </NavLink>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/telekommunikation/archiv">Archiv</NavLink>
                  </>
                )}
              </Content>
            </Flex>
            <Flex style={{ position: "relative" }}>
              <NavOption onMouseEnter={() => setSelectedNavItem("SERVICE")}>Service</NavOption>
              <Content
                style={{
                  height: selectedNavItem === "SERVICE" ? "100%" : "0",
                  opacity: selectedNavItem === "SERVICE" ? "1" : "0",
                }}
              >
                {open && selectedNavItem === "SERVICE" && (
                  <>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/service/ausschreibungskalender">
                      Ausschreibungskalender
                    </NavLink>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/service/material">Material</NavLink>
                    <NavLink href={LEGAL.privacy}>Datenschutz</NavLink>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/service/handbuecher">Handbücher</NavLink>
                    <StyledLink to={{ pathname: "/" }}>Seminare / Schulungen</StyledLink>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/service/hse-arbeitssicherheit">
                      HSE / Arbeitssicherheit
                    </NavLink>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/service/glossar">Glossar</NavLink>
                  </>
                )}
              </Content>
            </Flex>
            <Flex style={{ position: "relative" }}>
              <NavOption onMouseEnter={() => setSelectedNavItem("MEHR")}>Mehr</NavOption>
              <Content
                style={{
                  height: selectedNavItem === "MEHR" ? "100%" : "0",
                  opacity: selectedNavItem === "MEHR" ? "1" : "0",
                }}
              >
                {open && selectedNavItem === "MEHR" && (
                  <>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/mehr/anweisungswerke">
                      Anweisungswerke
                    </NavLink>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/mehr/formulare">Formulare</NavLink>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/mehr/leistungsverzeichnisse">
                      Leistungsverzeichnisse
                    </NavLink>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/mehr/bauleiterinformationen">
                      Bauleiterinformationen
                    </NavLink>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/mehr/flaechen-der-preiszone-a">
                      Flächen der Preiszone A
                    </NavLink>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/mehr/archiv">Archiv</NavLink>
                    <NavLink href="https://www.ewe-netz.de/vertragspartner/mehr/mehr">Mehr</NavLink>
                  </>
                )}
              </Content>
            </Flex>
            <NestedMenu
              style={{ opacity: open ? 1 : 0, height: open ? "310px" : "0" }}
              onMouseEnter={() => setHoveringNestedMenu(true)}
              onMouseLeave={() => setHoveringNestedMenu(false)}
            />
          </Flex>
        </div>
      </Flex>
    </HeaderBar>
  );
};

export default HeaderNavigation;
