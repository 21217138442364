import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { FormikErrors } from "formik";
import { ErrorText } from "../identity/styled-text";

export interface MultipleSelectProps {
  label?: string;
  children: any;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
}

const MultipleSelect: React.FC<MultipleSelectProps> = ({ label, children, error }) => {
  return (
    <FormControl style={{ width: "100%" }}>
      {label && <FormLabel>{label}</FormLabel>}
      <FormGroup>{children}</FormGroup>
      {error && <ErrorText>{error}</ErrorText>}
    </FormControl>
  );
};

export default MultipleSelect;
