import React, { ChangeEvent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import Description from "../../../presentational/components/admin-view/create-event/description";
import { setDescriptionValues, setDescription, setImage } from "../../../store/adminState/create-event/actions";
import { selectNewEventsImage, selectNewEventsDescription } from "../../../store/adminState/create-event/selector";
import { DescriptionValues, ProcessedImage } from "../../../store/types";
import { FormikErrors } from "formik";

interface Props {
  values: DescriptionValues;
  onChange: (eventOrPath: string | ChangeEvent<any>) => void | ((eventOrTextValue: string | ChangeEvent<any>) => void);
  setFieldValue: (fieldName: string, value: string) => void;
  errors?: FormikErrors<DescriptionValues>;
}

export interface DescriptionProps extends Props {
  setDescriptionValues: (values: DescriptionValues) => void;
  setDescription: (values: string) => void;
  setImage: (values: ProcessedImage[]) => void;
  image: ProcessedImage[];
  description: string;
}

const DescriptionContainer: React.FC<Props> = ({ values, onChange, errors, setFieldValue }) => {
  const dispatch = useDispatch();

  const descriptionProps: DescriptionProps = {
    setDescriptionValues: (values: DescriptionValues) => dispatch(setDescriptionValues(values)),
    setDescription: (values: string) => dispatch(setDescription(values)),
    setImage: useCallback((values: ProcessedImage[]) => dispatch(setImage(values)), [dispatch]),
    values,
    onChange,
    image: useSelector(selectNewEventsImage),
    description: useSelector(selectNewEventsDescription),
    errors,
    setFieldValue
  };

  return <Description {...descriptionProps} />;
};

export default DescriptionContainer;
