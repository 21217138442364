import React, { useState, useEffect } from "react";
import { Flex, Box } from "@rebass/grid";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editor.css";

import Input from "../../elements/input";
import FileUpload from "../file-upload";

import BoldIcon from "../../../assets/bold.svg";
import ItalicIcon from "../../../assets/italic.svg";
import TextAlignLeftIcon from "../../../assets/text-align-left.svg";
import TextAlignCenterIcon from "../../../assets/text-align-center.svg";
import TextAlignRightIcon from "../../../assets/text-align-right.svg";
import TextAlignJustifyIcon from "../../../assets/text-align-justify.svg";
import OrderedListIcon from "../../../assets/ordered-list.svg";
import UnorderedListIcon from "../../../assets/unordered-list.svg";
import ImageIcon from "../../../assets/image.svg";
import LinkIcon from "../../../assets/link.svg";
import { DescriptionProps } from "../../../../container/admin-view/components/description-container";
import { Heading, LabelText } from "../../identity/styled-text";

const getFileBase64 = (file: Blob, callback: (data: string | ArrayBuffer | null) => void) => {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => callback(reader.result);
  reader.onerror = error => console.log(error);
};

const imageUploadCallback = (file: Blob) =>
  new Promise((resolve, reject) =>
    getFileBase64(file, (data: string | ArrayBuffer | null) => resolve({ data: { link: data } }))
  );

const Description: React.FC<DescriptionProps> = ({
  values,
  onChange,
  setDescriptionValues,
  setDescription,
  setImage,
  image,
  description,
  errors,
  setFieldValue
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    const htmlBlocks = convertFromHTML(description || "");
    const newContent = ContentState.createFromBlockArray(htmlBlocks.contentBlocks, htmlBlocks.entityMap);

    setEditorState(EditorState.createWithContent(newContent));
  }, [description]);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  return (
    <Flex width="100%" backgroundColor="#F2F6F8" padding="20px 30px" flexDirection="column" style={{ borderRadius: 4 }}>
      <Heading>Beschreibung</Heading>
      <Flex width="100%" mt="25px" justifyContent="space-between">
        <Flex width="60%" flexDirection="column">
          <Flex justifyContent="space-between" mb="25px">
            <Box width="47.5%">
              <Input
                name="descriptionValues.name"
                value={values.name}
                onChange={onChange}
                onBlur={() => setDescriptionValues(values)}
                label="Name*"
                error={errors?.name}
              />
            </Box>
            <Box width="47.5%">
              <Input
                name="descriptionValues.speaker"
                value={values.speaker}
                onChange={onChange}
                onBlur={() => setDescriptionValues(values)}
                label="Referent*"
                error={errors?.speaker}
              />
            </Box>
          </Flex>
          <Input
            name="descriptionValues.shortDescription"
            value={values.shortDescription}
            onChange={onChange}
            onBlur={() => setDescriptionValues(values)}
            label="Kurze Beschreibung*"
            error={errors?.shortDescription}
          />
        </Flex>
        <Box width="35%">
          <FileUpload setImage={setImage} image={image} setFieldValue={setFieldValue} error={errors?.image}/>
        </Box>
      </Flex>
      <Box width="100%" mt="25px" height="200px">
        <LabelText>Ausführliche Beschreibung</LabelText>
        <Editor
          toolbarClassName="toolbar"
          wrapperClassName="wrapper"
          editorClassName="editor"
          localization={{ locale: "de" }}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          onBlur={() => {
            setDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
          }}
          toolbar={{
            options: ["inline", "textAlign", "list", "image", "link"],
            inline: {
              className: "inline-styles",
              options: ["bold", "italic"],
              bold: { icon: BoldIcon, className: "bold-icon" },
              italic: { icon: ItalicIcon, className: "italic-icon" }
            },
            textAlign: {
              className: "text-align-styles",
              left: { icon: TextAlignLeftIcon, className: "text-align-left-icon" },
              center: { icon: TextAlignCenterIcon, className: "text-align-center-icon" },
              right: { icon: TextAlignRightIcon, className: "text-align-right-icon" },
              justify: { icon: TextAlignJustifyIcon, className: "text-align-justify-icon" }
            },
            list: {
              className: "list-styles",
              options: ["ordered", "unordered"],
              ordered: { icon: OrderedListIcon, className: "ordered-list-icon" },
              unordered: { icon: UnorderedListIcon, className: "unordered-list-icon" }
            },
            image: {
              icon: ImageIcon,
              className: "image-icon",
              uploadCallback: imageUploadCallback,
              previewImage: true
            },
            link: {
              className: "link-styles",
              options: ["link"],
              link: { icon: LinkIcon, className: "link-icon" }
            }
          }}
        />
      </Box>
    </Flex>
  );
};

export default Description;
