import axios from "axios";
import { REACT_APP_BASE_URL } from "./config";
import rootReducer from "../store/configureStore";
import { logout } from "../store/adminState/actions";
import { removeUserToken } from "../store/userState/actions";
import { clearToken } from "./local-storage";
import { toast } from "react-toastify";

const instance = axios.create({
  headers: {
    "content-type": "application/json",
  },
  responseType: "json",
  baseURL: REACT_APP_BASE_URL,
  timeout: 10000,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        toast.error("Sie wurden automatisch ausgeloggt. Alle nicht gespeicherten Änderungen sind verloren.", {
          autoClose: false,
          closeButton: true
        });
        clearToken();
        rootReducer.dispatch(logout());
        rootReducer.dispatch(removeUserToken());
      }
    }

    return Promise.reject(error.response.data.message ?? error.message);
  }
);

export default instance;
