import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider, DatePicker as MuiDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
//import { parse } from "date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Flex } from "@rebass/grid";
import { FormikErrors } from "formik";
import deLocale from "date-fns/locale/de";
import { ErrorText, LabelText } from "../identity/styled-text";

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      marginTop: 0,
      marginBottom: 0,
      width: "100%",
      "& input": {
        color: "#0077B4",
      },
      "& div": {
        border: "1px solid rgba(0, 119, 180, 0.8)",
        borderRadius: 4,
        height: "48px",
        padding: "0 8px 0 20px",
        backgroundColor: "#ffffff",
        "&::before": {
          border: "none",
        },
        "& div": {
          border: "none",
          padding: "0",
        },
        "& svg": {
          color: "#0077B4",
        },
        "&::after": {
          borderBottom: "none",
        },
      },
    },
  })
);

export interface DatePickerProps {
  onChange: (value: MaterialUiPickersDate) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onClose?: () => void;
  label?: string;
  value?: string | null;
  maxDate?: string;
  disablePast?: boolean;
  name?: string;
  readOnly?: boolean;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  disableToolbar?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = ({
  label,
  onChange,
  onClose,
  onBlur,
  value,
  maxDate,
  disablePast,
  name,
  readOnly,
  error,
  disableToolbar = true,
}) => {
  const classes = useStyles();
  return (
    <Flex width="100%" flexDirection="column">
      {label && <LabelText>{label}</LabelText>}
      <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
        <MuiDatePicker
          maxDate={maxDate}
          helperText={false}
          disablePast={disablePast}
          name={name}
          onClose={onClose}
          onBlur={onBlur}
          disableToolbar={disableToolbar}
          variant="inline"
          format="dd.MM.yyyy"
          margin="normal"
          value={value}
          onChange={onChange}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            disableUnderline: true,
            style: { border: error ? "1px solid rgb(247,96,96)" : "" },
            readOnly: readOnly,
          }}
          className={classes.textField}
        />
      </MuiPickersUtilsProvider>
      {error && <ErrorText>{error}</ErrorText>}
    </Flex>
  );
};

export default DatePicker;
