import React from "react";
import { Flex, Box } from "@rebass/grid";

import Input from "../../elements/input";
import { EventLocationProps } from "../../../../container/admin-view/components/event-location-container";
import { Heading } from "../../identity/styled-text";

const EventLocation: React.FC<EventLocationProps> = ({ values, onChange, setLocationData, errors }) => (
  <Flex
    width="100%"
    backgroundColor="#F2F6F8"
    padding="20px 30px"
    flexDirection="column"
    mt="15px"
    style={{ borderRadius: 4 }}
  >
    <Heading>Veranstaltungsort</Heading>
    <Flex width="100%" mt="25px" flexDirection="column">
      <Flex justifyContent="space-between" mb="25px">
        <Box width="47.5%">
          <Input
            name="eventLocationValues.name"
            value={values.name}
            onChange={onChange}
            onBlur={() => setLocationData(values)}
            label="Name/Firma*"
            error={errors?.name}
          />
        </Box>
        <Box width="47.5%">
          <Input
            name="eventLocationValues.zip"
            value={values.zip}
            onChange={onChange}
            onBlur={() => setLocationData(values)}
            label="Postleitzahl*"
            error={errors?.zip}
          />
        </Box>
      </Flex>
      <Flex justifyContent="space-between" mb="25px">
        <Box width="47.5%">
          <Input
            name="eventLocationValues.street"
            value={values.street}
            onChange={onChange}
            onBlur={() => setLocationData(values)}
            label="Straße*"
            error={errors?.street}
          />
        </Box>
        <Box width="47.5%">
          <Input
            name="eventLocationValues.place"
            value={values.place}
            onChange={onChange}
            onBlur={() => setLocationData(values)}
            label="Ort*"
            error={errors?.place}
          />
        </Box>
      </Flex>

      <Flex justifyContent="space-between" mb="25px">
        <Box width="100%">
          <Input
            name="eventLocationValues.additionalInfo"
            value={values.additionalInfo}
            onChange={onChange}
            onBlur={() => setLocationData(values)}
            label="Zusatzinformation (z.B. Raum)"
            error={errors?.additionalInfo}
          />
        </Box>
      </Flex>
    </Flex>
  </Flex>
);

export default EventLocation;
