import { Flex } from "@rebass/grid";
import React from "react";
import { Heading, NormalTextBlack, PageHeadline } from "../../components/identity/styled-text";
import MainLayout from "../../layouts/main-user-layout";
import ProminentLink from "../../../presentational/components/user-view/prominent-link";
import styled from "styled-components";

export const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const StyledListItem = styled.li`
  margin-bottom: 20px;
`;

const NotFound: React.FC = () => {
  return (
    <MainLayout>
      <Flex flexDirection="column">
        <PageHeadline>Seite nicht gefunden</PageHeadline>
        <Heading>Fehler 404</Heading>
        <NormalTextBlack>
          <p>Bitte entschuldigen Sie, die gewünschte Seite wurde leider nicht gefunden.</p>
          <p>Die von Ihnen aufgerufene Adresse/URL ist auf unserem Server nicht bzw. nicht mehr vorhanden.</p>
          <p>
            Möglicherweise haben Sie einen veralteten Link bzw. ein altes Bookmark verwendet. Versuchen Sie bitte, den
            von Ihnen gewünschten Inhalt über unsere Internetseite bzw. über das Suchfeld oben rechts auf dieser
            Webseite zu finden.
          </p>
          <p>
            Wenn Sie einem Link auf unserer Website gefolgt sind, versuchen Sie es bitte in einigen Minuten noch einmal.
          </p>
        </NormalTextBlack>
        <StyledList>
          <StyledListItem>
            <ProminentLink title="Startseite" linkTo="https://www.ewe-netz.de/" />
          </StyledListItem>
          <StyledListItem>
            <ProminentLink title="Privatkunden" linkTo="https://www.ewe-netz.de/privatkunden" />
          </StyledListItem>
          <StyledListItem>
            <ProminentLink title="Geschäftskunden" linkTo="https://www.ewe-netz.de/geschaeftskunden" />
          </StyledListItem>
          <StyledListItem>
            <ProminentLink title="Einspeiser" linkTo="https://www.ewe-netz.de/einspeiser" />
          </StyledListItem>
          <StyledListItem>
            <ProminentLink title="Marktpartner" linkTo="https://www.ewe-netz.de/marktpartner" />
          </StyledListItem>
          <StyledListItem>
            <ProminentLink title="Kommunen" linkTo="https://www.ewe-netz.de/kommunen" />
          </StyledListItem>
        </StyledList>
      </Flex>
    </MainLayout>
  );
};

export default NotFound;
