import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../configureStore";

export const selectEvents = (state: AppState) => state?.userState?.availableEvents;
export const selectUserLoading = (state: AppState) => state?.userState?.loading;
export const selectFilters = (state: AppState) => state?.userState?.filters;
export const selectPage = (state: AppState) => state?.userState?.availableEvents.currentPage;
export const selectPageSize = (state: AppState) => state?.userState?.availableEvents.size;
export const selectFiltersCategory = (state: AppState) => state?.userState?.filters?.categories;
export const selectFiltersSpeaker = (state: AppState) => state?.userState?.filters?.speaker;
export const selectFiltersPlace = (state: AppState) => state?.userState?.filters?.place;
export const selectFiltersPeriod = (state: AppState) => state?.userState?.filters?.period;
export const selectCurrentId = (state: AppState) => state?.userState?.currentId;
export const selectCurrentForm = (state: AppState) => state?.userState?.currentForm;
export const selectSignupSuccessfull = (state: AppState) => state?.userState.signupSuccessfull;
export const selectSignoffSuccessful = (state: AppState) => state?.userState.signoffSuccessfull;
export const selectEventTypes = (state: AppState) => state?.userState?.eventTypes;
export const selectPlaces = (state: AppState) => state?.userState?.places;

export const selectNumOfAvailableEvents = createSelector(selectEvents, (events) => {
  let numOfAvailableEvents = 0;
  events?.content?.map((event) => {
    if (event.sichtbarkeit === "ONLINE") {
      return (numOfAvailableEvents += 1);
    }
    return null;
  });

  return numOfAvailableEvents;
});

export const selectEventById = createSelector(selectEvents, selectCurrentId, (events, eventId) => {
  return events.content.find((event) => event.veranstaltungId === eventId);
});

export const selectCurrentEvent = (state: AppState) => state.userState.currentEvent;

export const selectToken = (state: AppState) => state.userState?.token;
export const selectUserIsAuthenticated = createSelector(selectToken, (token) => {
  return token ? true : false;
});
