import * as Yup from "yup";
import { FormField } from "../presentational/components/admin-view/create-event/form-preview";
import { FormValues } from "../store/types";

export const REQUIRED = "Pflichtfeld";
export const VALID_EMAIL = "Bitte geben Sie eine gültige E-Mail ein.";
export const ONLY_NUMBERS = "Bitte geben Sie eine Nummer an.";

export const signUpValidationSchema = (values: FormField[], withImageUpload: boolean = false, inAdminView: boolean = false) => {
  const schema: FormValues = {
    policy: Yup.bool().test("is-true", REQUIRED, value => (value === true ? true : false)),
    remainderMail: Yup.bool(),
    bild: withImageUpload && !inAdminView ? Yup.object().required(REQUIRED).typeError(REQUIRED) : Yup.object().notRequired(),
  };
  values.forEach((value: FormField) => {
    if (
      value.typ !== "NUMMER" &&
      value.typ !== "KONTROLLKAESTCHEN" &&
      value.name !== "E-Mail" &&
      value.typ !== "EINZELNE_AUSWAHL" &&
      value.typ !== "MEHRERE_AUSWAHLEN"
    ) {
      schema[value.name] = value.pflichtfeld ? Yup.string().required(REQUIRED) : Yup.string();
    } else if (value.typ === "NUMMER") {
      schema[value.name] =
        value.pflichtfeld === true
          ? Yup.string()
              .matches(/^\d+$/, ONLY_NUMBERS)
              .required(REQUIRED)
          : Yup.string().matches(/^\d+$/, ONLY_NUMBERS);
    } else if (value.typ === "KONTROLLKAESTCHEN") {
      schema[value.name] = value.pflichtfeld
        ? Yup.bool().test("is-true", REQUIRED, value => (value === true ? true : false))
        : Yup.bool();
    } else if (value.name === "E-Mail") {
      schema[value.name] = value.pflichtfeld
        ? Yup.string()
            .email(VALID_EMAIL)
            .required(REQUIRED)
        : Yup.string().email(VALID_EMAIL);
    } else if (value.typ === "MEHRERE_AUSWAHLEN") {
      schema[value.name] = Yup.array().test("is-one-true", REQUIRED, val =>
        val?.some((arrayValues: any) => Object.values(arrayValues)[0] === true) ? true : false
      );
    } else if (value.typ === "EINZELNE_AUSWAHL") {
      schema[value.name] = value.pflichtfeld
        ? Yup.string()
            .test("is-not-default", REQUIRED, value => (value === "BITTE_WÄHLEN" ? false : true))
            .required(REQUIRED)
        : Yup.string();
    } else {
      return;
    }
  });

  return Yup.object().shape(schema);
};
