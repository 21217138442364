import React from "react";
import styled from "styled-components";
import { Flex } from "@rebass/grid";

import HeaderNavigationAdminContainer from "../../container/admin-view/components/header-navigation-admin-container";
import Footer from "../components/footer";

const Root = styled.div`
  height: 100%;
`;

const Container = styled(Flex)`
  width: 80%;
  max-width: 1200px;
  margin-top: 95px;

  @media (max-width: 1200px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 0 20px;
  }
`;

interface Props {
  children: JSX.Element;
}

const MainLayout: React.FC<Props> = ({ children }) => {
  return (
    <Root>
      <HeaderNavigationAdminContainer />
      <Container flexDirection="column" justifyContent="center" margin="0 auto">
        {children}
      </Container>
      <Footer />
    </Root>
  );
};

export default MainLayout;
