import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import Popover from "@material-ui/core/Popover";
import { withStyles } from "@material-ui/core/styles";
import { MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";

import MainLayout from "../../layouts/main-admin-layout";
import EventCard from "../../../container/admin-view/components/event-card-container";
import { Event } from "../../../store/types";

import FilterComponent from "../../../container/admin-view/components/filter-container";
import Checkbox from "../../components/elements/checkbox";
import Button from "../../components/elements/button";
import SelectField from "../../components/elements/select";
import SmallButton from "../../components/elements/small-buttom";
import {
  EventOverviewProps,
  EventSorting,
  SortingOptions,
  SortingDirection,
} from "../../../container/admin-view/page-event-overview-container";
import { PageHeadline } from "../../components/identity/styled-text";
import SortButtons from "../../components/admin-view/sort-buttons";
import { toast } from "react-toastify";

const SmallText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #0077b4;
`;

const StyledPopover = withStyles(() => ({
  root: {
    "& .MuiPaper-root": {
      boxShadow: "none",
      backgroundColor: "transparent",
      overflow: "visible",
    },
    backgroundColor: "rgba(0, 0, 0, 0.51)",
  },
}))(Popover);

const EventOverview: React.FC<EventOverviewProps> = ({
  availableEvents,
  eventActionController,
  selectedEvents,
  sortEvents,
  showOnlyArchived,
  toggleViewArchived,
}) => {
  const [action, setAction] = useState<string>("ACTION");
  const [selectAllEvents, setSelectAllEvents] = useState<boolean>(false);
  const [sorting, setSorting] = useState<EventSorting | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClickSearchClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSearch = () => {
    setAnchorEl(null);
  };

  const filteredEvents = showOnlyArchived
    ? availableEvents.content.filter((event) => event.sichtbarkeit === "ARCHIVIERT")
    : availableEvents.content.filter((event) => event.sichtbarkeit !== "ARCHIVIERT");

  const events =
    filteredEvents &&
    filteredEvents.map((event: Event) => (
      <EventCard eventValues={event} allEventsSelected={selectAllEvents} key={event.veranstaltungId} />
    ));

  useEffect(() => {
    if (sorting) {
      sortEvents(sorting.sortBy, sorting.sortDirection);
    }
  }, [sorting, sortEvents]);

  return (
    <MainLayout>
      <Flex flexDirection="column" width="100%">
        <Flex margin="35px 0 60px 0" justifyContent="space-between">
          <PageHeadline>Veranstaltungsliste</PageHeadline>
          <Flex>
            <Box mr="20px">
              <Button
                buttonText={showOnlyArchived ? "Aktuelle Veranstaltungen" : "Archivierte Veranstaltungen"}
                variant="dark"
                onClick={toggleViewArchived}
              />
            </Box>
            <Box mr="20px">
              <Link to={{ pathname: "/" }} target="blank">
                <Button buttonText="Vertragspartnerweb" variant="dark" />
              </Link>
            </Box>
            <Box mr="20px">
              <SmallButton variant="dark" searchIcon onClick={handleClickSearchClick} />
              <StyledPopover
                id="searche-menu"
                open={open}
                anchorEl={anchorEl}
                className="popover_class"
                onClose={handleCloseSearch}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <FilterComponent onClose={handleCloseSearch} />
              </StyledPopover>
            </Box>
            <Link to={{ pathname: `/create-event` }}>
              <Button buttonText="Neue Veranstaltung" variant="dark" plusIcon iconLeft />
            </Link>
          </Flex>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" padding="0 30px 15px 10px" width="100%">
          <Flex alignItems="center">
            <Checkbox
              style={{ width: 20, height: 18, marginRight: "10px" }}
              checked={selectAllEvents}
              onClick={() => setSelectAllEvents(!selectAllEvents)}
            />
            <SmallText style={{ width: "144px" }}>Bild</SmallText>
            <SmallText style={{ width: "230px", marginLeft: "30px" }}>
              <Flex alignItems="center">
                Titel, Ort
                <SortButtons
                  activeButton={
                    sorting?.sortBy === SortingOptions.TITLE ? sorting?.sortDirection : SortingDirection.NONE
                  }
                  onClickUp={() =>
                    setSorting({
                      sortBy: SortingOptions.TITLE,
                      sortDirection: SortingDirection.ASCENDING,
                    })
                  }
                  onClickDown={() =>
                    setSorting({
                      sortBy: SortingOptions.TITLE,
                      sortDirection: SortingDirection.DESCENDING,
                    })
                  }
                  resetSorting={() =>
                    setSorting({
                      sortBy: SortingOptions.NONE,
                      sortDirection: SortingDirection.NONE,
                    })
                  }
                />
              </Flex>
            </SmallText>
          </Flex>
          <SmallText style={{ width: "150px", marginLeft: "17px" }}>
            <Flex alignItems="center">
              Datum, Typ, Autor
              <SortButtons
                activeButton={sorting?.sortBy === SortingOptions.DATE ? sorting?.sortDirection : SortingDirection.NONE}
                onClickUp={() =>
                  setSorting({
                    sortBy: SortingOptions.DATE,
                    sortDirection: SortingDirection.ASCENDING,
                  })
                }
                onClickDown={() =>
                  setSorting({
                    sortBy: SortingOptions.DATE,
                    sortDirection: SortingDirection.DESCENDING,
                  })
                }
                resetSorting={() =>
                  setSorting({
                    sortBy: SortingOptions.NONE,
                    sortDirection: SortingDirection.NONE,
                  })
                }
              />
            </Flex>
          </SmallText>
          <Flex width="30%" justifyContent="space-between">
            <SmallText style={{ width: "155px" }}>Teilnehmer / Max / Warten</SmallText>
            <SmallText
              style={{
                width: "65px",
                textAlign: "center",
                marginLeft: "5px",
              }}
            >
              Status
            </SmallText>
            <SmallText>Aktion</SmallText>
          </Flex>
        </Flex>
        {events}
        <Flex padding="30px 30px 15px 10px" width="50%" alignItems="center">
          <Checkbox
            style={{ width: 20, height: 18, marginRight: "10px" }}
            checked={selectAllEvents}
            onClick={() => setSelectAllEvents(!selectAllEvents)}
          />
          <Box width="180px" mr="30px">
            <SelectField
              value={action}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => setAction(event.target.value as string)}
            >
              <MenuItem value="ACTION">Aktion</MenuItem>
              <MenuItem value="DUPLICATE">Duplizieren</MenuItem>
              <MenuItem value="ARCHIVE">Archivieren</MenuItem>
              <MenuItem value="CHANGE_STATUS">Status ändern</MenuItem>
              <MenuItem value="DELETE">Löschen</MenuItem>
            </SelectField>
          </Box>
          <Button
            buttonText="Ausführen"
            variant="dark"
            onClick={() => {
              if (selectedEvents.length) {
                eventActionController(selectedEvents, action);
              } else {
                toast.warning("Wählen Sie mindestens eine Veranstaltung aus.");
              }
            }}
          />
        </Flex>
      </Flex>
    </MainLayout>
  );
};

export default EventOverview;
