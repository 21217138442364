import React from "react";
import { useDispatch, useSelector } from "react-redux";

import FilterComponent from "../../../presentational/components/admin-view/filter";
import { setFilterValues } from "../../../store/adminState/actions";
import { selectFilters } from "../../../store/adminState/selector";
import { FilterValuesAdmin } from "../../../store/types";

interface Props {
  onClose: () => void;
}

export interface FilterPropsAdmin extends Props {
  setFilter: (values: FilterValuesAdmin) => void;
  filterValues: FilterValuesAdmin;
}

const FilterContainer: React.FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();
  const filter = useSelector(selectFilters);

  const filterProps: FilterPropsAdmin = {
    setFilter: (values: FilterValuesAdmin) => dispatch(setFilterValues(values)),
    filterValues: filter,
    onClose: onClose
  };

  return <FilterComponent {...filterProps} />;
};

export default FilterContainer;
