import React from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import { MenuItem } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Checkbox from "../../elements/checkbox";
import Button from "../../elements/button";
import MultipleSelect from "../../elements/multiple-selection";
import DatePicker from "../../elements/date-picker";
import Input from "../../elements/input";
import Select from "../../elements/select";
import InputMultiLine from "../../elements/input-multi-line";
import { Formular } from "../../../../store/types";
import closeIcon from "../../../assets/close.svg";
import { NormalTextBlack, Heading, LabelText } from "../../identity/styled-text";
import { LEGAL } from "../../../../config";

const FormContainer = styled(Flex)`
  background: #f2f6f8;
  padding: 15px 30px;
  margin: 30px 25px 25px 25px;
`;

const StyledFormControl = styled(FormControlLabel)`
  & .MuiButtonBase-root {
    width: 22px !important;
    height: 20px !important;
  }
`;

const StyledButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  right: 30px;
`;

export interface FormPreviewProps {
  felder: Formular[];
  onClose: () => void;
}

export interface FormField {
  name: string;
  pflichtfeld: boolean;
  typ: string;
  args?: [];
}

export interface CheckboxValues {
  reminderMail: boolean;
  policy: boolean;
}

const FormPreview: React.FC<FormPreviewProps> = ({ felder, onClose }) => {
  return (
    <Flex alignItems="center" justifyContent="center" height="100vh">
      <Flex flexDirection="column" style={{ background: "white", maxWidth: "800px", borderRadius: "4px" }}>
        <Flex
          alignItems="center"
          justifyContent="center"
          height="70px"
          style={{
            background: "#f2f6f8",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            position: "relative",
          }}
        >
          <Heading>Formular Vorschau</Heading>
          <StyledButton onClick={onClose}>
            <img src={closeIcon} alt="close-icon" />
          </StyledButton>
        </Flex>
        <FormContainer flexDirection="column">
          <Heading>Anmeldung</Heading>
          <NormalTextBlack style={{ margin: "15px 0 20px 0" }}>
            Danke für Ihr Interesse an dieser Schulung von EWE NETZ GmbH. Nach der Anmeldung erhalten Sie eine
            Bestätigungsmail, welche Ihre Anmeldebestätigung enthält und die Sie bitte ausgedruckt zur Veranstaltung
            mitbringen.
          </NormalTextBlack>
          {felder &&
            felder.map((field: FormField, index: number) => {
              switch (field.typ) {
                case "TEXTLINIE":
                  return (
                    <Flex key={index} margin="5px 0" alignItems="center" justifyContent="space-between" width="100%">
                      <NormalTextBlack style={{ width: "40%" }}>
                        {field.name === "Strasse" ? "Straße" : field.name}
                        {field.pflichtfeld ? "*" : ""}
                      </NormalTextBlack>
                      <Input readOnly />
                    </Flex>
                  );
                case "NUMMER":
                  return (
                    <Flex key={index} margin="5px 0" alignItems="center" justifyContent="space-between" width="100%">
                      <NormalTextBlack style={{ width: "40%" }}>
                        {field.name}
                        {field.pflichtfeld ? "*" : ""}
                      </NormalTextBlack>
                      <Input readOnly />
                    </Flex>
                  );
                case "EINZELNE_AUSWAHL":
                  return (
                    <Flex key={index} margin="5px 0" alignItems="center" justifyContent="space-between" width="100%">
                      <NormalTextBlack style={{ width: "40%" }}>{field.name}</NormalTextBlack>
                      <Select readOnly>
                        <MenuItem value="BITTE_WÄHLEN">Bitte wählen</MenuItem>
                        {field.args &&
                          field.args.map((args: string, index: number) => (
                            <MenuItem key={index} value={args}>
                              {args.charAt(0) + args.toLowerCase().slice(1)}
                            </MenuItem>
                          ))}
                      </Select>
                    </Flex>
                  );
                case "MEHRERE_AUSWAHLEN":
                  return (
                    <Flex
                      key={index}
                      margin="5px 0"
                      alignItems="flex-start"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <NormalTextBlack style={{ width: "40%" }}>
                        {field.name}
                        {field.pflichtfeld ? "*" : ""}
                      </NormalTextBlack>
                      <MultipleSelect>
                        <Flex width="100%" flexWrap="wrap">
                          {field.args &&
                            field.args.map((optionName: string, index: number) => (
                              <Box key={index} width="50%" pr="10px">
                                <StyledFormControl
                                  control={<Checkbox style={{ width: 30, height: 28 }} />}
                                  label={<LabelText style={{ marginBottom: "0px" }}>{optionName}</LabelText>}
                                />
                              </Box>
                            ))}
                        </Flex>
                      </MultipleSelect>
                    </Flex>
                  );
                case "TEXTFELD":
                  return (
                    <Flex
                      key={index}
                      margin="5px 0"
                      alignItems="flex-start"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <NormalTextBlack style={{ width: "40%" }}>
                        {field.name}
                        {field.pflichtfeld ? "*" : ""}
                      </NormalTextBlack>
                      <InputMultiLine readOnly />
                    </Flex>
                  );
                case "KONTROLLKAESTCHEN":
                  return (
                    <Flex key={index} margin="5px 0" alignItems="center" justifyContent="space-between" width="100%">
                      <FormControlLabel
                        disabled
                        control={<Checkbox readOnly style={{ width: 30, height: 28 }} />}
                        label={<LabelText style={{ marginBottom: "0px" }}>{field.name}</LabelText>}
                      />
                    </Flex>
                  );
                case "DATUM":
                  return (
                    <Flex key={index} margin="5px 0" alignItems="center" justifyContent="space-between" width="100%">
                      <NormalTextBlack style={{ width: "40%" }}>
                        {field.name}
                        {field.pflichtfeld ? "*" : ""}
                      </NormalTextBlack>
                      <DatePicker readOnly onChange={(value) => value} />
                    </Flex>
                  );
                default:
                  return null;
              }
            })}
          <Box marginTop="5px">
            <FormControlLabel
              control={<Checkbox style={{ width: 30, height: 28 }} />}
              label={
                <LabelText style={{ marginBottom: "0px" }}>Erinnerungs E-Mail 5 Tage vor der Veranstaltung</LabelText>
              }
            />
          </Box>
          <Box marginTop="5px">
            <FormControlLabel
              disabled
              control={<Checkbox style={{ width: 30, height: 28 }} />}
              label={
                <LabelText style={{ marginBottom: "0px" }}>
                  Ich habe die Erklärung von EWE NETZ zum{" "}
                  <a href={LEGAL.privacy} target="blank">
                    Datenschutz
                  </a>{" "}
                  gelesen und bin damit einverstanden.*
                </LabelText>
              }
            />
          </Box>
          <Box width="180px" alignSelf="flex-end" marginTop="25px">
            <Button buttonText="ANMELDEN" variant="dark" arrow disabled />
          </Box>
        </FormContainer>
        <Box width="125px" alignSelf="center" mb="25px">
          <Button buttonText="Schließen" variant="light" onClick={onClose} />
        </Box>
      </Flex>
    </Flex>
  );
};
export default FormPreview;
