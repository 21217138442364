import { createSlice } from "@reduxjs/toolkit";
import { UserState } from "../types/index";

const initialState: UserState = {
  availableEvents: {
    content: [],
    currentPage: 1,
    size: 10,
    total: 0,
    totalPages: 0
  },
  currentId: "",
  currentForm: {},
  filters: {
    categories: "ALL_CATEGORIES",
    place: "ALL_PLACES",
    speaker: "",
    period: { from: "", to: "" }
  },
  eventTypes: [],
  places: [],
  signupSuccessfull: false,
  signoffSuccessfull: false,
  loading: false,
  token: ""
};

export const userSlice = createSlice({
  name: "userState",
  initialState,
  reducers: {
    getEventSuccess(state) {
      state.loading = false;
    },
    setEventTypes(state, action) {
      state.eventTypes = action.payload;
    },
    setPlaces(state, action) {
      state.places = action.payload;
    },
    getAllEventsSuccess(state, action) {
      state.availableEvents = action.payload;
      state.loading = false;
    },
    getAllEventsError(state) {
      state.availableEvents = { content: [], currentPage: 1, size: 10, total: 0, totalPages: 0 };
      state.loading = false;
    },
    setFilter(state, action) {
      state.filters = action.payload;
    },
    setCategory(state, action) {
      state.filters.categories = action.payload;
    },
    setPlace(state, action) {
      state.filters.place = action.payload;
    },
    setSpeaker(state, action) {
      state.filters.speaker = action.payload;
    },
    setPeriodStart(state, action) {
      state.filters.period.from = action.payload;
    },
    setPeriodEnd(state, action) {
      state.filters.period.to = action.payload;
    },
    resetFilter(state) {
      state.filters = {
        categories: "ALL_CATEGORIES",
        place: "ALL_PLACES",
        speaker: "",
        period: { from: "", to: "" }
      };
    },
    setCurrentId(state, action) {
      state.currentId = action.payload;
    },
    setCurrentForm(state, action) {
      state.currentForm = action.payload;
    },
    signupForEventSuccess(state) {
      state.signupSuccessfull = true;
    },
    signupForEventError(state) {
      state.signupSuccessfull = false;
    },
    setSignupSuccessfull(state, action) {
      state.signupSuccessfull = action.payload;
    },
    redirectStart(state) {
      state.loading = true;
    },
    redirectSuccess(state, action) {
      state.token = action.payload;
      state.loading = false;
    },
    redirectError(state) {
      state.loading = false;
    },
    setCurrentEvent(state, action) {
      state.currentEvent = action.payload;
    },
    signoffFromEventSuccess(state, action) {
      state.signoffSuccessfull = action.payload;
    },
    reset() {
      return initialState;
    }
  }
});

const { reducer } = userSlice;

export default reducer;
