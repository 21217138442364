import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import isAfter from "date-fns/isAfter";

import ManageParticipants from "../../presentational/pages/admin-view/manage-participants";
import {
  getEventParticipants,
  deleteParticipant,
  reassignParticipants,
  downloadParticipantImages,
  getParticipantExcel,
  searchParticipant,
  clearSelectedParticipants,
  clearSelectedWaitingParticipants,
} from "../../store/adminState/edit-participants/actions";
import {
  selectSelectedParticipants,
  selectSelectedFromWaitingList,
  selectRegisteredParticipants,
  selectWaitingParticipants,
} from "../../store/adminState/edit-participants/selector";
import { selectEventById } from "../../store/adminState/edit-event/selector";
import { Participant, Event, ReassignParticipantsProps } from "../../store/types";
import { AppState } from "../../store/configureStore";
import { selectEvents } from "../../store/adminState/selector";

interface Props {}
export interface ManageParticipantsProps {
  registeredParticipants?: Participant[];
  waitingParticipants?: Participant[];
  currentEvent: Event | undefined;
  deleteParticipant: (participantIds: string[], deleteReason: string) => void;
  downloadParticipantPictures: (participantIds: string[]) => void;
  clearSelectedParticipants: () => void;
  clearSelectedParticipantsFromWaitingList: () => void;
  selectedParticipants: string[];
  selectedFromWaitingList: string[];
  getParticipantExcel: (eventId: string) => void;
  searchParticipant: (eventId: string, name: string) => void;
  reassignParticipants: ({ participantIds, eventNew, eventCurrent }: ReassignParticipantsProps) => void;
  availableEvents: Event[];
}

const ManageParticipantsContainer: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { eventId } = useParams<{ eventId: string }>();

  useEffect(() => {
    dispatch(getEventParticipants(eventId));
  }, [dispatch, eventId]);

  const currentEvent = useSelector((state: AppState) => selectEventById(state, eventId));

  const manageParticipantsProps: ManageParticipantsProps = {
    registeredParticipants: useSelector(selectRegisteredParticipants),
    waitingParticipants: useSelector(selectWaitingParticipants),
    currentEvent,
    deleteParticipant: (participantIds: string[], deleteReason: string) =>
      dispatch(deleteParticipant(participantIds, currentEvent?.veranstaltungId || "", deleteReason)),
    downloadParticipantPictures: (participantIds: string[]) => {
      dispatch(downloadParticipantImages(participantIds));
    },
    clearSelectedParticipants: useCallback(() => dispatch(clearSelectedParticipants()), [dispatch]),
    clearSelectedParticipantsFromWaitingList: useCallback(
      () => dispatch(clearSelectedWaitingParticipants()),
      [dispatch]
    ),
    selectedParticipants: useSelector(selectSelectedParticipants),
    selectedFromWaitingList: useSelector(selectSelectedFromWaitingList),
    getParticipantExcel: (eventId: string) => dispatch(getParticipantExcel(eventId)),
    searchParticipant: (eventId: string, name: string) => dispatch(searchParticipant(eventId, name)),
    reassignParticipants: ({ participantIds, eventNew, eventCurrent }) =>
      dispatch(reassignParticipants({ participantIds, eventNew, eventCurrent })),
    availableEvents:
      useSelector(selectEvents).content.filter(
        (event) =>
          isAfter(new Date(event.startTagStartZeit), new Date()) &&
          event.veranstaltungId !== currentEvent?.veranstaltungId
      ) || [],
  };

  return <ManageParticipants {...manageParticipantsProps} />;
};

export default ManageParticipantsContainer;
