import axios from "./axios-client";
import { REACT_APP_BASE_URL } from "./config";
import { Form, FormValues, EventTypes, CreateEventParams } from "../store/types";
import { getToken } from "./local-storage";
import { ReassignParticipantsProps } from "../store/types";

export interface EventsAllParams {
  page: number;
  size: number;
  speaker?: string;
  category?: string;
  startDatum?: string;
  endDatum?: string;
  referent?: string;
  place?: string;
  city?: string;
  name?: string;
  zip?: string;
  searchKeywords?: string;
}

const events = {
  all: (params: EventsAllParams) => {
    return axios.get(`${REACT_APP_BASE_URL}/veranstaltungen`, {
      method: "GET",
      headers: { Authorization: getToken() },
      params: {
        page: params.page,
        size: 300,
        referent: params.speaker,
        kategorie: params.category,
        startDatum: params.startDatum,
        endDatum: params.endDatum,
        name: params.name,
        ort: params.place,
        plz: params.zip,
        stadt: params.city,
        suchbegriff: params.searchKeywords,
      },
    });
  },
  one: (eventId: string) => {
    return axios.get(`${REACT_APP_BASE_URL}/veranstaltungen/${eventId}`, {
      method: "GET",
      headers: { Authorization: getToken() },
    });
  },
  signUp: (eventId: string, params: FormValues) => {
    return axios.post(
      `${REACT_APP_BASE_URL}/veranstaltungen/${eventId}/anmeldungen`,
      {
        ...params,
      },
      { headers: { Authorization: getToken() } }
    );
  },
  signOff: (signupId: string) => {
    return axios.put(`${REACT_APP_BASE_URL}/abmeldungen/${signupId}`, {}, { headers: { Authorization: getToken() } });
  },
  duplicate: (eventId: string) => {
    return axios.post(
      `${REACT_APP_BASE_URL}/veranstaltungen/${eventId}`,
      {},
      { headers: { Authorization: getToken() } }
    );
  },
  changeStatus: (eventId: string, newStatus: string) => {
    return axios.put(
      `${REACT_APP_BASE_URL}/veranstaltungen/${eventId}/sichtbarkeiten`,
      {
        sichtbarkeit: newStatus,
      },
      { headers: { Authorization: getToken() } }
    );
  },
  delete: (eventId: string) => {
    return axios.delete(`${REACT_APP_BASE_URL}/veranstaltungen/${eventId}`, { headers: { Authorization: getToken() } });
  },
  createNewEvent: (params: CreateEventParams) => {
    return axios.post(
      `${REACT_APP_BASE_URL}/veranstaltungen`,
      {
        ...params,
      },
      { headers: { Authorization: getToken() } }
    );
  },
  getAllEventRegistrations: (eventId: string) => {
    return axios.get(`${REACT_APP_BASE_URL}/veranstaltungen/${eventId}/anmeldungen`, {
      method: "GET",
      headers: { Authorization: getToken() },
    });
  },
  editEventsForm: (eventId: string, params: Form) => {
    return axios.put(
      `${REACT_APP_BASE_URL}/veranstaltungen/${eventId}`,
      {
        ...params,
      },
      { headers: { Authorization: getToken() } }
    );
  },
  editEvent: (eventId: string, params: CreateEventParams) => {
    return axios.put(
      `${REACT_APP_BASE_URL}/veranstaltungen/${eventId}`,
      {
        ...params,
      },
      { headers: { Authorization: getToken() } }
    );
  },
  editParticipant: (participantId: string, params: FormValues) => {
    return axios.put(
      `${REACT_APP_BASE_URL}/anmeldungen/${participantId}`,
      {
        ...params,
      },
      { headers: { Authorization: getToken() } }
    );
  },
  deleteParticipant: (participantId: string, deleteReason: string) => {
    return axios.put(
      `${REACT_APP_BASE_URL}/abmeldungen/${participantId}`,
      {
        abmeldegrund: deleteReason
      },
      { headers: { Authorization: getToken() } }
    );
  },
  downloadParticipantImage: (participantId: string) => {
    return axios.get(`${REACT_APP_BASE_URL}/anmeldungen/${participantId}/bild`, {
      headers: { Authorization: getToken() },
    });
  },
  getParticipantExcel: (eventId: string) => {
    return axios.get(`${REACT_APP_BASE_URL}/veranstaltungen/${eventId}/anmeldungen_xlsx`, {
      headers: { Authorization: getToken() },
    });
  },
  reassignParticipants: ({
    participantIds,
    eventNew,
  }: {
    participantIds: ReassignParticipantsProps["participantIds"];
    eventNew: ReassignParticipantsProps["eventNew"];
  }) => {
    return axios.patch(
      `${REACT_APP_BASE_URL}/anmeldungen`,
      {
        veranstaltungId: eventNew,
        anmeldungIds: participantIds,
      },
      { headers: { Authorization: getToken() } }
    );
  },
  getPlaces: () => {
    return axios.get(`${REACT_APP_BASE_URL}/veranstaltungen/orte?term=`, {
      headers: { Authorization: getToken() },
    });
  },
  getEventTypes: () => {
    return axios.get(`${REACT_APP_BASE_URL}/anmeldeformulare`, {
      headers: { Authorization: getToken() },
    });
  },
  addEventType: (eventType: EventTypes) => {
    return axios.post(
      `${REACT_APP_BASE_URL}/anmeldeformulare`,
      {
        ...eventType,
      },
      {
        headers: { Authorization: getToken() },
      }
    );
  },
  deleteEventType: (nameOfEventType: string) => {
    return axios.delete(`${REACT_APP_BASE_URL}/anmeldeformulare/${nameOfEventType}`, {
      headers: { Authorization: getToken() },
    });
  },
  searchParticipant: (eventId: string, name: string) => {
    return axios.get(`${REACT_APP_BASE_URL}/veranstaltungen/${eventId}/anmeldenamen?name=${name}`, {
      headers: { Authorization: getToken() },
    });
  },
};

export default events;
