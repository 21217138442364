import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login, resetPassword } from "../../store/adminState/actions";
import { selectIsAuthenticated, selectIsReset, selectLoading } from "../../store/adminState/selector";
// import { selectPreviousLocation } from "../../store/globalState/selector";
import LoginPage from "../../presentational/pages/admin-view/login";
import { LoginValues } from "../../store/types";
import { togglePasswordReset } from "../../store/adminState/actions";

interface Props {}

export interface LoginProps {
  initialValues: LoginValues;
  onSubmit(value: LoginValues): void;
  onPasswordReset(username: string): void;
  togglePasswordReset(): void;
  loading: boolean;
  isReset: boolean;
}

const Login: React.FC<Props> = () => {
  const initialValues: LoginValues = { username: "", password: "" };
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const loading = useSelector(selectLoading);
  const isReset = useSelector(selectIsReset);

  const togglePasswordResetHandler = () => {
    dispatch(togglePasswordReset());
  };

  // const previousRoute = useSelector(selectPreviousLocation);

  //let redirectPath = previousRoute === "" ? "/event-overview" : previousRoute;

  const loginProps: LoginProps = {
    initialValues,
    onSubmit: (values) => dispatch(login(values)),
    onPasswordReset: (username) => dispatch(resetPassword(username)),
    loading: loading,
    isReset: isReset,
    togglePasswordReset: togglePasswordResetHandler,
  };

  return isAuthenticated ? <Redirect to="/event-overview" /> : <LoginPage {...loginProps} />;
};

export default Login;
