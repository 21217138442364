import React from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

import Button from "../elements/button";
import { Event } from "../../../store/types/index";

const CardContainer = styled(Flex)`
  width: 100%;
  border-bottom: 1px solid rgba(0, 119, 180, 0.5);
  min-height: 320px;

  @media (max-width: 799px) {
    flex-direction: column;
    min-width: 300px;
    min-height: 550px;
  }
`;

const RightHalfContainer = styled(Flex)`
  @media (max-width: 799px) {
    width: 85%;
    margin-left: 0;
    margin-top: 20px;
  }
`;
const ButtonContainer = styled(Flex)`
  margin-top: 40px;
  @media (max-width: 799px) {
    flex-direction: column;
    width: 50%;

    & > button:last-child {
      margin-top: 20px;
    }
    & > button:first-child {
      margin-top: 40px;
    }
  }
`;

const Image = styled.div`
  width: 255px;
  height: 170px;
`;

const SmallText = styled(Box)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
`;

const MediumText = styled(Box)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
`;

const getFreePlaces = (
  capacity: number,
  participants: number
): number | string => {
  if (capacity === undefined) {
    return "unbegrenzt";
  }

  return capacity - participants;
};

const EventCard: React.FC<Event> = ({
  bildLink,
  veranstaltungId,
  name,
  kurzbeschreibung,
  ort,
  startTagStartZeit,
  startTagEndZeit,
  referent,
  teilnehmerkapazitaet,
  teilnehmerzahl,
  zusaetzlicheTage
}) => (
  <CardContainer padding="30px 0">
    <Flex flexDirection="column" width="255px" justifyContent="space-between">
      <Image>
        <img
          src={bildLink}
          alt="eventImage"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Image>
      <MediumText margin="10px 10px 0 10px">
        <strong>{dateFormat(startTagStartZeit, "dd.mm.yyyy", true)}</strong> |{" "}
        {dateFormat(startTagStartZeit, "HH:MM", true)} -{" "}
        {dateFormat(startTagEndZeit, "HH:MM", true)}
      </MediumText>
      {zusaetzlicheTage &&
        zusaetzlicheTage.length > 0 &&
        zusaetzlicheTage.map((day, index) => (
          <MediumText
            key={day.startZeit}
            margin={`5px 10px ${
              zusaetzlicheTage.length === index + 1 ? "5px" : "0"
            } 10px`}
          >
            <strong>{dateFormat(day.startZeit, "dd.mm.yyyy", true)}</strong> |{" "}
            {dateFormat(day.startZeit, "HH:MM", true)} -{" "}
            {dateFormat(day.endZeit, "HH:MM", true)}
          </MediumText>
        ))}
      <SmallText margin="0 10px">
        {ort.gebaeudename}
        <br />
        {ort.postleitzahl}, {ort.ortsname}
      </SmallText>
    </Flex>
    <RightHalfContainer
      flexDirection="column"
      width="65%"
      marginLeft="30px"
      justifyContent="space-between"
    >
      <Box>
        <MediumText width="80%">
          <strong>{name}</strong>
        </MediumText>
        <SmallText width="100%" marginTop="10px">
          {kurzbeschreibung}
        </SmallText>
        <SmallText marginTop="20px">
          <strong>Referent: </strong>
          {referent}
        </SmallText>
        <SmallText marginTop="20px">
          <strong>Freie Plätze:</strong>{" "}
          {getFreePlaces(teilnehmerkapazitaet, teilnehmerzahl)}
        </SmallText>
      </Box>
      <ButtonContainer justifyContent="space-between">
        <Link to={{ pathname: `/events/${veranstaltungId}`, state: "toTop" }}>
          <Button buttonText="DETAILS" variant="light" arrow />
        </Link>
        <Link
          to={{ pathname: `/events/${veranstaltungId}`, state: "toSignup" }}
        >
          <Button buttonText="ANMELDEN" variant="dark" arrow />
        </Link>
      </ButtonContainer>
    </RightHalfContainer>
  </CardContainer>
);

export default EventCard;
