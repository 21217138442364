export const ACCESS_TOKEN = "token";
export const PREV_LOCATION = "location";

export const setToken = function (token: string) {
  localStorage.setItem(ACCESS_TOKEN, token);
};

export const getToken = function () {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const clearToken = function () {
  window.localStorage.removeItem(ACCESS_TOKEN);
};

export const setPrevLocation = function (location: string) {
  localStorage.setItem(PREV_LOCATION, location);
};

export const getPrevLocation = function () {
  return localStorage.getItem(PREV_LOCATION);
};

export const clearPrevLocation = function () {
  window.localStorage.removeItem(PREV_LOCATION);
};

const localStorageFunc = {
  setToken,
  getToken,
  clearToken,
  setPrevLocation,
  getPrevLocation,
  clearPrevLocation
};

export default localStorageFunc;
