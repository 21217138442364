import React from "react";
import styled from "styled-components";
import { Flex } from "@rebass/grid";

import MainLayout from "../../layouts/main-user-layout";
import FilterComponent from "../../../container/user-view/filter-container";
import EventCard from "../../components/user-view/event-card";
import { Event } from "../../../store/types";
import dateFormat from 'dateformat';

import PrintIcon from "../../assets/print.svg";
import { EventProps } from "../../../container/user-view/page-events";
import { PageHeadline, NormalTextBlack } from "../../components/identity/styled-text";

const TextWithBorder = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0077b4;
  border-bottom: 1px solid rgba(200, 212, 0, 0.5);
  padding-bottom: 10px;
`;

const PrintIconContainer = styled.div`
  align-self: flex-end;
  margin-top: 60px;
`;

const EventOverview: React.FC<EventProps> = ({ availableEvents, eventPeriod }) => {
  const events =
    availableEvents &&
    availableEvents.content.length !== 0 &&
    availableEvents.content.map((event: Event) =>
      event.sichtbarkeit === "ONLINE" ? <EventCard {...event} key={event.veranstaltungId} /> : ""
    );

  const printPage = () => {
    window.print();
  };

  return (
    <MainLayout>
      <Flex flexDirection="column">
        <PageHeadline>Seminare / Schulungen</PageHeadline>
        <NormalTextBlack>
          Hier finden Sie aktuelle Termine und Unterlagen für Schulungen und Seminare in allen Sparten.
        </NormalTextBlack>
        <FilterComponent />
        <TextWithBorder>
          Veranstaltungen vom { dateFormat(eventPeriod.from || "", 'dd.mm.yyyy') } bis{" "}
          { dateFormat(eventPeriod.to || "", 'dd.mm.yyyy') }
        </TextWithBorder>
        {events}
        <PrintIconContainer>
          <img
            style={{ cursor: "pointer" }}
            onClick={printPage}
            src={PrintIcon}
            alt="print-icon"
            height="33px"
            width="26px"
          />
        </PrintIconContainer>
      </Flex>
    </MainLayout>
  );
};

export default EventOverview;
