import React, { useEffect, useCallback } from "react";
import {
  getEvent,
  setCurrentId,
  signupForEvent
} from "../../store/userState/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import EventDetails from "../../presentational/pages/user-view/event-details";
import {
  selectCurrentEvent,
  selectSignupSuccessfull
} from "../../store/userState/selector";
import { setSignupSuccessfull } from "../../store/userState/actions";

import { EventDay, Formular, FormValues } from "../../store/types/index";

interface UrlParams {
  id: string;
}

interface Props {}

export interface EventDetailsProps {
  veranstaltungId: string;
  name: string;
  intern: boolean;
  bildLink: string | undefined;
  kurzbeschreibung: string;
  beschreibung: string;
  verfuegbarePlaetze: number | undefined;
  wartelistenzahl: number;
  veranstaltungDummyIndex: number;
  referent: string;
  sichtbarkeit: string;
  ort: {
    gebaeudename: string;
    postleitzahl: string;
    strasse: string;
    ortsname: string;
  };
  startTagStartZeit: string;
  startTagEndZeit: string;
  zusaetzlicheTage?: EventDay[];
  anmeldeSchlussZeitpunkt: string;
  status: string;
  formular: {
    felder: Formular[];
  };
  signupForEvent: (formValues: FormValues, eventId: string) => void;
  setSignupSuccessfull: (value: boolean) => void;
  reloadCurrentEvent: () => void;
  signupSuccessfull: boolean;
  eventFullyBooked: boolean;
  teilnehmerkapazitaet: number | undefined;
  withImageUpload: boolean | undefined;
}

const EventsDetailsContainer: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { id } = useParams<UrlParams>();

  useEffect(() => {
    if (id) {
      dispatch(getEvent(id));
    }

    return () => {
      dispatch(setCurrentId(""));
    };
  }, [id, dispatch]);

  const event = useSelector(selectCurrentEvent);
  const signupSuccessfull = useSelector(selectSignupSuccessfull);

  const eventDetailsProps: EventDetailsProps = {
    veranstaltungId: event?.veranstaltungId!,
    intern: event?.intern!,
    name: event?.name!,
    bildLink: event?.bildLink,
    kurzbeschreibung: event?.kurzbeschreibung!,
    beschreibung: event?.beschreibung!,
    eventFullyBooked: event?.teilnehmerkapazitaet === event?.teilnehmerzahl,
    verfuegbarePlaetze: event?.teilnehmerkapazitaet!
      ? event?.teilnehmerkapazitaet! - event?.teilnehmerzahl!
      : undefined,
    teilnehmerkapazitaet: event?.teilnehmerkapazitaet,
    wartelistenzahl: event?.wartelistenzahl!,
    veranstaltungDummyIndex: event?.veranstaltungDummyIndex!,
    startTagStartZeit: event?.startTagStartZeit!,
    startTagEndZeit: event?.startTagEndZeit!,
    zusaetzlicheTage: event?.zusaetzlicheTage,
    anmeldeSchlussZeitpunkt: event?.anmeldeschluss!,
    referent: event?.referent!,
    formular: event?.formular!,
    ort: event?.ort!,
    sichtbarkeit: event?.sichtbarkeit!,
    status: event?.status!,
    signupForEvent: (formValues: FormValues, eventId: string) =>
      dispatch(signupForEvent(formValues, eventId)),
    setSignupSuccessfull: useCallback(
      (value: boolean) => dispatch(setSignupSuccessfull(value)),
      [dispatch]
    ),
    reloadCurrentEvent: () => dispatch(getEvent(event?.veranstaltungId!)),
    signupSuccessfull: signupSuccessfull,
    withImageUpload: event?.mitFotoUpload
  };

  return <EventDetails {...eventDetailsProps} />;
};

export default EventsDetailsContainer;
