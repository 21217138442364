import { get } from "lodash";
import { ProcessedImage } from "../store/types";
import { FileWithPath } from "react-dropzone";

interface HandleFileSelect {
  (files: FileWithPath[]): Promise<ProcessedImage[]>;
}

const handleFileSelect: HandleFileSelect = files =>
  Promise.all(
    files.map(
      (file: FileWithPath) =>
        new Promise<ProcessedImage>((resolve, reject) => {
          try {
            const reader = new window.FileReader();
            reader.onload = (() => (e: ProgressEvent) => {
              const binaryData = get(e, "target.result", "");
              const base64String = window.btoa(binaryData);
              const size = get(e, "total", get(file, "size", 0));

              resolve({
                size,
                fileName: get(file, "name", "NONE"),
                type: get(file, "type", "NONE")
                  .replace("application/", "")
                  .replace("image/", "")
                  .toLowerCase(),
                base64Content: base64String,
                preview: URL.createObjectURL(file)
              });
            })();
            reader.readAsBinaryString(file);
          } catch (error) {
            reject(error);
          }
        })
    )
  );

export default handleFileSelect;
