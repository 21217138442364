import React from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import Modal from "@material-ui/core/Modal";
import Button from "../elements/button";

const StyledModal = styled(Modal)`
  overflow: auto;
`;

const ModalBody = styled(Box)`
  position: absolute;
  background: #ffffff;
  padding: 30px;
  border-radius: 4px;
  width: 100%;
  max-width: 900px;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
`;

const ModalTitle = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: #0077b4;
`;

interface ModalComponentProps {
  children: React.ReactChild;
  open: boolean;
  onClose(): void;
  title?: string;
  onConfirm?(): void;
  onConfirmLoading?: boolean;
}

const ModalComponent: React.FC<ModalComponentProps> = ({
  children,
  title,
  open = false,
  onClose,
  onConfirm,
  onConfirmLoading = false,
}) => {
  const body = (
    <ModalBody>
      {title && <ModalTitle>{title}</ModalTitle>}
      {children}
      <Flex mt="30px" justifyContent="flex-end">
        <Button buttonText="Abbrechen" variant="light" onClick={() => (!onConfirmLoading ? onClose() : () => {})} />
        {onConfirm && (
          <Box ml="10px">
            <Button
              disabled={onConfirmLoading}
              isLoading={onConfirmLoading}
              buttonText="Bestätigen"
              variant="dark"
              onClick={() => onConfirm()}
            />
          </Box>
        )}
      </Flex>
    </ModalBody>
  );

  return (
    <StyledModal
      open={open}
      onClose={!onConfirmLoading ? onClose : () => {}}
      aria-labelledby="modal"
      aria-describedby="modal-content"
    >
      {body}
    </StyledModal>
  );
};

export default ModalComponent;
