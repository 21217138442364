import React, { useEffect } from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import dateFormat from "dateformat";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import MoreIcon from "../identity/more-icon";
import Checkbox from "../elements/checkbox";
import editIcon from "../../assets/edit.svg";
import editFormIcon from "../../assets/edit-form.svg";
import duplicateIcon from "../../assets/duplicate.svg";
import archiveIcon from "../../assets/archive.svg";
import manageParticipantsIcon from "../../assets/manage-participants.svg";
import changeStatusIcon from "../../assets/change-status.svg";
import { EventCardProps } from "../../../container/admin-view/components/event-card-container";

const CardContainer = styled(Flex)`
  background: rgba(242, 246, 248, 1);
  margin-bottom: 10px;
  min-height: 150px;
  transition: background 0.1s linear;

  &:hover {
    background: rgba(242, 246, 248, 0.5);
    cursor: pointer;
  }
`;

const MenuContainer = styled(Box)`
  border-radius: 4px;
  &:before {
    content: "";
    position: absolute;
    right: -15px;
    top: 50%;
    margin-top: -15px;
    border-top: 15px solid transparent;
    border-right: none;
    border-left: 15px solid #0077b4;
    border-bottom: 15px solid transparent;
  }
`;

const MenuIcon = styled.img`
  margin-right: 15px;
`;

const StyledIconButton = withStyles(() => ({
  root: {
    padding: "10px 18px",
    "&:hover": {
      backgroundColor: "#00487A",
    },
    "&:hover > span > svg > circle": {
      fill: "white",
    },
  },
}))(IconButton);

const StyledPopover = withStyles(() => ({
  root: {
    "& .MuiPaper-root": {
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.16)",
      overflow: "visible",
    },
  },
}))(Popover);

const SmallText = styled(Box)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  max-width: 275px;
`;

const MenuText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 30px;
  color: #ffffff;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const EventCard: React.FC<EventCardProps> = ({
  eventValues,
  isEventSelected,
  addToSelectedEvents,
  removeFromSelectedEvents,
  allEventsSelected,
  eventActionController,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (allEventsSelected) {
      addToSelectedEvents(eventValues.veranstaltungId);
    } else if (!allEventsSelected) {
      removeFromSelectedEvents(eventValues.veranstaltungId);
    }
  }, [allEventsSelected, addToSelectedEvents, removeFromSelectedEvents, eventValues.veranstaltungId]);

  return (
    <CardContainer
      padding="20px 30px 20px 10px"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      onClick={() => {
        if (!open) {
          isEventSelected
            ? removeFromSelectedEvents(eventValues.veranstaltungId)
            : addToSelectedEvents(eventValues.veranstaltungId);
        }
      }}
    >
      <Flex alignItems="center">
        <Box mr="10px">
          <Checkbox style={{ width: 20, height: 18 }} checked={isEventSelected} />
        </Box>
        <img
          src={eventValues.bildLink}
          style={{ width: "144px", height: "96px", objectFit: "cover" }}
          alt="eventImage"
        />
        <Flex flexDirection="column" ml="30px" width="230px">
          <SmallText>
            <strong>{eventValues.name}</strong>
          </SmallText>
          <SmallText>{eventValues.ort.gebaeudename}</SmallText>
          <SmallText>
            {eventValues.ort.postleitzahl}, {eventValues.ort.ortsname}
          </SmallText>
        </Flex>
      </Flex>
      <Flex flexDirection="column" ml="20px" width="150px">
        <SmallText>
          <strong>{dateFormat(eventValues.startTagStartZeit, "dd.mm.yyyy", true)} -</strong>
        </SmallText>
        <SmallText>
          <strong>
            {dateFormat(
              eventValues?.zusaetzlicheTage?.length > 0
                ? eventValues.zusaetzlicheTage[eventValues.zusaetzlicheTage.length - 1].endZeit
                : eventValues.startTagEndZeit,
              "dd.mm.yyyy",
              true
            )}
          </strong>
        </SmallText>
        <SmallText>{eventValues.typ}</SmallText>
        <SmallText>{eventValues.referent}</SmallText>
      </Flex>
      <Flex width="30%" justifyContent="space-between">
        <Flex padding="7px 0" backgroundColor="white" style={{ borderRadius: "2px" }}>
          <Box width="55px" style={{ textAlign: "center" }}>
            <SmallText>{eventValues.teilnehmerzahl}</SmallText>
          </Box>
          <Box
            width="55px"
            style={{
              borderLeft: "1px solid rgba(0, 119, 180, 0.4)",
              borderRight: "1px solid rgba(0, 119, 180, 0.4)",
              minWidth: "50px",
              textAlign: "center",
            }}
          >
            <SmallText>{eventValues.teilnehmerkapazitaet || "-"}</SmallText>
          </Box>
          <Box width="55px" style={{ textAlign: "center" }}>
            <SmallText>{eventValues.wartelistenzahl}</SmallText>
          </Box>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          backgroundColor={eventValues.sichtbarkeit === "ONLINE" ? "#00B200" : "#E51717"}
          width="65px"
          height="35px"
          style={{ borderRadius: "2px" }}
        >
          <SmallText style={{ color: "white" }}>
            {eventValues.sichtbarkeit ? eventValues.sichtbarkeit.toLowerCase() : ""}
          </SmallText>
        </Flex>
        <Box height="20px">
          <StyledIconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
            <MoreIcon />
          </StyledIconButton>
          <StyledPopover
            id="popover-menu"
            open={open}
            anchorEl={anchorEl}
            className="popover_class"
            onClose={handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
          >
            <MenuContainer width="515px" height="120px" backgroundColor="#0077B4" padding="10px 30px">
              <Flex width="70%" height="100%" justifyContent="space-between" alignItems="center">
                <Flex flexDirection="column">
                  <Flex>
                    <MenuIcon src={editIcon} alt="edit-icon" />
                    <StyledLink
                      to={{
                        pathname: `/${eventValues.veranstaltungId}/edit-event`,
                      }}
                    >
                      <MenuText>Bearbeiten</MenuText>
                    </StyledLink>
                  </Flex>
                  <Flex>
                    <MenuIcon src={manageParticipantsIcon} alt="edit-icon" />
                    <StyledLink
                      to={{
                        pathname: `/${eventValues.veranstaltungId}/manage-participants`,
                      }}
                    >
                      <MenuText>Teilnehmer verwalten</MenuText>
                    </StyledLink>
                  </Flex>
                  <Flex>
                    <MenuIcon src={editFormIcon} alt="edit-icon" />
                    <StyledLink
                      to={{
                        pathname: `/${eventValues.veranstaltungId}/edit-registration-form`,
                      }}
                    >
                      <MenuText>Anmeldeformular ändern</MenuText>
                    </StyledLink>
                  </Flex>
                </Flex>
                <Flex flexDirection="column">
                  <Flex>
                    <MenuIcon src={duplicateIcon} alt="edit-icon" />
                    <MenuText
                      onClick={() => {
                        eventActionController([eventValues.veranstaltungId], "DUPLICATE");
                        handleClose();
                      }}
                    >
                      Duplizieren
                    </MenuText>
                  </Flex>
                  <Flex>
                    <MenuIcon src={changeStatusIcon} alt="edit-icon" />
                    <MenuText
                      onClick={() => {
                        eventActionController([eventValues.veranstaltungId], "CHANGE_STATUS");
                        handleClose();
                      }}
                    >
                      Status ändern
                    </MenuText>
                  </Flex>
                  <Flex>
                    <MenuIcon src={archiveIcon} alt="edit-icon" />
                    <MenuText
                      onClick={() => {
                        eventActionController([eventValues.veranstaltungId], "ARCHIVE");
                        handleClose();
                      }}
                    >
                      Archivieren
                    </MenuText>
                  </Flex>
                </Flex>
              </Flex>
            </MenuContainer>
          </StyledPopover>
        </Box>
      </Flex>
    </CardContainer>
  );
};

export default EventCard;
