import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import EventOverview from "../../presentational/pages/admin-view/event-overview";
import { getAllEvents, sortEvents, toggleViewArchived } from "../../store/adminState/actions";
import { eventActionController } from "../../store/adminState/edit-event/actions";
import { reset } from "../../store/adminState/create-event/actions";
import { selectEvents, selectFilters, viewOnlyArchived } from "../../store/adminState/selector";
import { selectSelectedEvents } from "../../store/adminState/edit-event/selector";
import { Event } from "../../store/types";

export interface EventSorting {
  sortBy: SortingOptions;
  sortDirection: SortingDirection;
}
export enum SortingOptions {
  NONE = "",
  TITLE = "name",
  DATE = "startTagStartZeit",
}
export enum SortingDirection {
  NONE = "",
  DESCENDING = "DESCENDING",
  ASCENDING = "ASCENDING",
}

interface Props {}

export interface EventOverviewProps {
  availableEvents: {
    content: Event[];
    currentPage: number;
    size: number;
    total: number;
    totalPages: number;
  };
  selectedEvents: string[];
  eventActionController: (eventIds: string[], action: string) => void;
  sortEvents: (sortBy: SortingOptions, sortDirection: SortingDirection) => void;
  showOnlyArchived: boolean;
  toggleViewArchived: () => void;
}

const EventsContainer: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const filter = useSelector(selectFilters);
  const availableEvents = useSelector(selectEvents);
  const selectedEvents = useSelector(selectSelectedEvents);
  const showOnlyArchived = useSelector(viewOnlyArchived);

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllEvents());
  }, [filter, dispatch]);

  const toggleViewArchived2 = () => {
    dispatch(toggleViewArchived());
  };

  const eventOverviewProps: EventOverviewProps = {
    availableEvents,
    selectedEvents,
    eventActionController: useCallback(
      (eventIds: string[], action: string) => dispatch(eventActionController(eventIds, action)),
      [dispatch]
    ),
    sortEvents: useCallback(
      (sortBy: SortingOptions, sortDirection: SortingDirection) => dispatch(sortEvents(sortBy, sortDirection)),
      [dispatch]
    ),
    showOnlyArchived,
    toggleViewArchived: toggleViewArchived2,
  };

  return <EventOverview {...eventOverviewProps} />;
};

export default EventsContainer;
