import { createSlice } from "@reduxjs/toolkit";
import { GlobalState } from "../types/index";

const initialState: GlobalState = {
  previousLocation: "",
};

export const globalSlice = createSlice({
  name: "globalState",
  initialState,
  reducers: {
    logPreviousLocation(state, action) {
      state.previousLocation = action.payload;
    },
    reset() {
      return { ...initialState };
    },
  },
});

const { reducer } = globalSlice;

export default reducer;
