import React, { useState, ChangeEvent, useEffect } from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import { Box as MatBox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import dateFormat from "dateformat";
import { MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import { toast } from "react-toastify";

import MainLayout from "../../layouts/main-admin-layout";
import { ManageParticipantsProps } from "../../../container/admin-view/page-manage-participants";
import Checkbox from "../../components/elements/checkbox";
import Radio from "../../components/elements/radio";
import Button from "../../components/elements/button";
import InputMultiLine from "../../components/elements/input-multi-line";
import SmallButton from "../../components/elements/small-buttom";
import SerachIcon from "../../components/identity/search-icon";
import RegistrationCard from "../../../container/admin-view/components/registration-card-container";
import { Participant } from "../../../store/types";
import SelectField from "../../components/elements/select";
import { Heading, SmallTextBlue, PageHeadline, NormalTextBlack } from "../../components/identity/styled-text";
import {
  addToSelectedFromWaitingList,
  addToSelectedParticipants,
  removeFromSelectedParticipants,
  removeSelectedFromWaitingList,
} from "../../../store/adminState/edit-participants/actions";
import { useDispatch } from "react-redux";

import Modal from "../../components/admin-view/modal";

const StyledFormattedDate = styled(MatBox)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #0077b4;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ModalSubHeadline = styled.div`
  margin: 20px 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
`;

const EventItemContainer = styled(Box)`
  border-top: 1px solid #3392c3;
  border-bottom: 1px solid #3392c3;
  padding: 20px 0;
`;

const EventItem = styled(Flex)`
  background: rgba(242, 246, 248, 1);
  margin-bottom: 10px;
  transition: background 0.1s linear;
  padding: 15px;
  align-items: center;

  &:hover {
    background: rgba(242, 246, 248, 0.5);
    cursor: pointer;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const EventItemLabel = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
`;

const StyledTextField = withStyles(() => ({
  root: {
    width: "100%",
    "& > div": {
      border: "1px solid #E0E4E6;",
      borderRadius: 4,
    },
    "& .Mui-error": {
      border: "1px solid rgba(247, 96, 96, 1)",
      borderRadius: 4,
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& input": {
      borderRadius: 4,
      background: "white",
      padding: "0 20px",
      height: "46px",
      display: "flex",
      alignItems: "center",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
}))(TextField);

const ManageParticipants: React.FC<ManageParticipantsProps> = ({
  registeredParticipants,
  waitingParticipants,
  currentEvent,
  deleteParticipant,
  downloadParticipantPictures,
  selectedParticipants,
  clearSelectedParticipants,
  clearSelectedParticipantsFromWaitingList,
  selectedFromWaitingList,
  getParticipantExcel,
  searchParticipant,
  reassignParticipants,
  availableEvents,
}) => {
  const [isDeleteParticipantsModalOpen, setIsDeleteParticipantsModalOpen] = useState(false);
  const [isDeleteParticipantsLoading, setIsDeleteParticipantsLoading] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [isReassignParticipantsModalOpen, setIsReassignParticipantsModalOpen] = useState(false);
  const [isReassignParticipantsLoading, setIsReassignParticipantsLoading] = useState(false);
  const [selectedEventNew, setSelectedEventNew] = useState("");
  const [action, setAction] = useState("ACTION");
  const [selectAllParticipants, setSelectAllParticipants] = useState(false);
  const dispatch = useDispatch();

  const handleOnSelectAll = () => {
    if (!selectAllParticipants) {
      registeredParticipants?.forEach((item) => {
        dispatch(addToSelectedParticipants(item.anmeldungId));
      });
      waitingParticipants?.forEach((item) => {
        dispatch(addToSelectedFromWaitingList(item.anmeldungId));
      });
    } else {
      registeredParticipants?.forEach((item) => {
        dispatch(removeFromSelectedParticipants(item.anmeldungId));
      });
      waitingParticipants?.forEach((item) => {
        dispatch(removeSelectedFromWaitingList(item.anmeldungId));
      });
    }
    setSelectAllParticipants(!selectAllParticipants);
  };

  const combinedSelectedParticipants = selectedParticipants.concat(selectedFromWaitingList);

  const registrations =
    registeredParticipants &&
    registeredParticipants
      .sort(
        (a: Participant, b: Participant) =>
          new Date(b.anmeldeZeitpunkt).getTime() - new Date(a.anmeldeZeitpunkt).getTime()
      )
      .map((participant: Participant) => (
        <RegistrationCard
          participant={participant}
          selected={selectedParticipants.includes(participant.anmeldungId)}
          key={participant.anmeldungId}
          onDeleteParticipant={() => setIsDeleteParticipantsModalOpen(true)}
        />
      ));

  const waiting =
    waitingParticipants &&
    waitingParticipants
      .sort(
        (a: Participant, b: Participant) =>
          new Date(b.anmeldeZeitpunkt).getTime() - new Date(a.anmeldeZeitpunkt).getTime()
      )
      .map((participant: Participant) => (
        <RegistrationCard
          participant={participant}
          selected={selectedFromWaitingList.includes(participant.anmeldungId)}
          onWaitingList
          key={participant.anmeldungId}
          onDeleteParticipant={() => setIsDeleteParticipantsModalOpen(true)}
        />
      ));

  const handleSearchChange = debounce(
    (value: string) => searchParticipant(currentEvent?.veranstaltungId || "", value),
    500
  );

  const handleSelectedEventNewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedEventNew(event.target.value);
  };

  useEffect(() => {
    const allRegisteredParticipantsCount = (registeredParticipants?.length || 0) + (waitingParticipants?.length || 0);
    const allSelectedParticipantsCount = selectedParticipants.length + selectedFromWaitingList.length;

    setSelectAllParticipants(allRegisteredParticipantsCount === allSelectedParticipantsCount);
  }, [selectedParticipants, registeredParticipants, waitingParticipants, selectedFromWaitingList]);

  useEffect(() => {
    clearSelectedParticipants();
    clearSelectedParticipantsFromWaitingList();
  }, [clearSelectedParticipants, clearSelectedParticipantsFromWaitingList]);

  const DeleteModal = (
    <Modal
      title="Teilnehmer mit Abmeldegrund löschen"
      open={isDeleteParticipantsModalOpen}
      onConfirmLoading={isDeleteParticipantsLoading}
      onConfirm={async () => {
        if (deleteReason !== "") {
          setIsDeleteParticipantsLoading(true);
          await deleteParticipant(combinedSelectedParticipants, deleteReason);
          setIsDeleteParticipantsLoading(false);
          setIsDeleteParticipantsModalOpen(false);
          setDeleteReason("");
        } else {
          toast.warning("Geben Sie bitte einen Abmeldegrund an.");
        }
      }}
      onClose={() => {
        setIsDeleteParticipantsModalOpen(false);
        clearSelectedParticipants();
        clearSelectedParticipantsFromWaitingList();
      }}
    >
      <Box my="20px">
        <InputMultiLine
          placeholder="Abmeldegrund"
          name="deleteReason"
          value={deleteReason}
          onChange={(e) => setDeleteReason(e.target.value as string)}
        />
      </Box>
    </Modal>
  );

  const ReassignModal = (
    <Modal
      title="Teilnehmer einer anderer Veranstaltung zuordnen"
      open={isReassignParticipantsModalOpen}
      onConfirmLoading={isReassignParticipantsLoading}
      onConfirm={async () => {
        if (selectedEventNew !== "") {
          setIsReassignParticipantsLoading(true);
          await reassignParticipants({
            participantIds: combinedSelectedParticipants,
            eventNew: selectedEventNew,
            eventCurrent: currentEvent?.veranstaltungId || "",
          });
          setIsReassignParticipantsLoading(false);
          setIsReassignParticipantsModalOpen(false);
          setSelectedEventNew("");
          clearSelectedParticipants();
          clearSelectedParticipantsFromWaitingList();
        } else {
          toast.warning("Wählen Sie eine Veranstaltung aus.");
        }
      }}
      onClose={() => setIsReassignParticipantsModalOpen(false)}
    >
      <>
        <ModalSubHeadline>{combinedSelectedParticipants.length} Teilnehmer ausgewählt</ModalSubHeadline>

        <EventItemContainer>
          <Flex alignItems="center" padding="0 30px 15px 10px">
            <Box pl="55px" width="50%">
              <SmallTextBlue>Titel</SmallTextBlue>
            </Box>
            <Box pl="10px" width="25%">
              <SmallTextBlue>Datum</SmallTextBlue>
            </Box>
            <Box pl="12px" width="25%">
              <SmallTextBlue>Teilnehmer / Max / Warten</SmallTextBlue>
            </Box>
          </Flex>

          {availableEvents.map((event) => {
            return (
              <EventItem key={event.veranstaltungId} onClick={() => setSelectedEventNew(event.veranstaltungId)}>
                <Flex width="50%" alignItems="center">
                  <Box width="30px">
                    <Radio
                      style={{ width: 20, height: 18 }}
                      onChange={handleSelectedEventNewChange}
                      checked={event.veranstaltungId === selectedEventNew}
                      value={event.veranstaltungId}
                      name="selected-event-new"
                      inputProps={{ "aria-label": event.veranstaltungId }}
                    />
                  </Box>

                  <Flex flex={1} flexDirection="column" ml="20px" mr="40px">
                    <EventItemLabel>
                      <strong>{event.name}</strong>
                    </EventItemLabel>
                  </Flex>
                </Flex>
                <Flex width="25%">
                  <EventItemLabel>
                    <strong>{dateFormat(event.startTagStartZeit, "dd.mm.yyyy", true)}&nbsp;-&nbsp;</strong>
                  </EventItemLabel>

                  <EventItemLabel>
                    <strong>
                      {dateFormat(
                        event?.zusaetzlicheTage?.length > 0
                          ? event.zusaetzlicheTage[event.zusaetzlicheTage.length - 1].endZeit
                          : event.startTagEndZeit,
                        "dd.mm.yyyy",
                        true
                      )}
                    </strong>
                  </EventItemLabel>
                </Flex>

                <Flex width="25%">
                  <Flex padding="7px 0" backgroundColor="white" style={{ borderRadius: "2px" }}>
                    <Box width="55px" style={{ textAlign: "center" }}>
                      <EventItemLabel>{event.teilnehmerzahl}</EventItemLabel>
                    </Box>
                    <Box
                      width="55px"
                      style={{
                        borderLeft: "1px solid rgba(0, 119, 180, 0.4)",
                        borderRight: "1px solid rgba(0, 119, 180, 0.4)",
                        minWidth: "50px",
                        textAlign: "center",
                      }}
                    >
                      <EventItemLabel>{event.teilnehmerkapazitaet || "-"}</EventItemLabel>
                    </Box>
                    <Box width="55px" style={{ textAlign: "center" }}>
                      <EventItemLabel>{event.wartelistenzahl}</EventItemLabel>
                    </Box>
                  </Flex>
                </Flex>
              </EventItem>
            );
          })}
        </EventItemContainer>
      </>
    </Modal>
  );

  return (
    <>
      {ReassignModal}
      {DeleteModal}
      <MainLayout>
        <Flex flexDirection="column" width="100%">
          <Flex mt="30px" alignItems="center" justifyContent="space-between">
            <PageHeadline>Teilnehmer verwalten</PageHeadline>
            <Flex>
              <Box mr="20px">
                <StyledLink to={{ pathname: `/event-overview` }}>
                  <SmallButton variant="light" arrowIcon left />
                </StyledLink>
              </Box>
              <Box mr="20px">
                <StyledTextField
                  onChange={(e: ChangeEvent<{ value: string }>) => handleSearchChange(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "15px",
                        }}
                      >
                        <SerachIcon color="#BDBDBD" />
                      </div>
                    ),
                  }}
                />
              </Box>
              <Box mr="20px">
                <SmallButton
                  variant="dark"
                  fileIcon
                  onClick={() => getParticipantExcel(currentEvent?.veranstaltungId || "")}
                />
              </Box>
              <StyledLink
                to={{
                  pathname: `/${currentEvent?.veranstaltungId}/manage-participants/add-paricipant`,
                }}
              >
                <Button buttonText="Teilnehmer hinzufügen" variant="dark" addUserIcon iconLeft />
              </StyledLink>
            </Flex>
          </Flex>
          <NormalTextBlack style={{ marginTop: "45px" }}>{currentEvent?.name}</NormalTextBlack>
          <Flex>
            <StyledFormattedDate>
              {dateFormat(currentEvent?.startTagStartZeit, "dd.mm.yyyy", true)} -{" "}
              {dateFormat(currentEvent?.startTagEndZeit, "dd.mm.yyyy", true)}
            </StyledFormattedDate>
          </Flex>
          <Heading style={{ marginTop: "30px" }}>Anmeldungen</Heading>
          <Flex justifyContent="space-between" alignItems="center" padding="0 30px 15px 10px" mt="20px" width="100%">
            <Flex alignItems="center">
              <Checkbox
                style={{ width: 20, height: 18, marginRight: "10px" }}
                checked={selectAllParticipants}
                onClick={handleOnSelectAll}
              />
              <SmallTextBlue style={{ width: "150px" }}>Vorname, Nachname</SmallTextBlue>
              <SmallTextBlue style={{ width: "150px", marginLeft: "30px" }}>Firma</SmallTextBlue>
              <SmallTextBlue style={{ width: "150px", marginLeft: "30px" }}>Kontakt</SmallTextBlue>
            </Flex>
            <Flex width="15%" justifyContent="space-between">
              <SmallTextBlue style={{ width: "65px", textAlign: "center", marginRight: "2px" }}>Status</SmallTextBlue>
              <SmallTextBlue>Aktion</SmallTextBlue>
            </Flex>
          </Flex>
          {registrations}

          <div
            style={{
              height: "1px",
              width: "100%",
              background: "rgba(0, 119, 180, 0.5)",
              margin: "30px 0",
            }}
          />
          <PageHeadline>Warteliste</PageHeadline>
          <Flex justifyContent="space-between" alignItems="center" padding="0 30px 15px 10px" mt="20px" width="100%">
            <Flex alignItems="center">
              <SmallTextBlue
                style={{
                  width: 20,
                  height: 18,
                  marginRight: "10px",
                  padding: "9px",
                }}
              >
                {" "}
              </SmallTextBlue>
              <SmallTextBlue style={{ width: "150px" }}>Vorname, Nachname</SmallTextBlue>
              <SmallTextBlue style={{ width: "150px", marginLeft: "30px" }}>Firma</SmallTextBlue>
              <SmallTextBlue style={{ width: "150px", marginLeft: "30px" }}>Kontakt</SmallTextBlue>
            </Flex>
            <Flex width="15%" justifyContent="space-between">
              <SmallTextBlue style={{ width: "65px", textAlign: "center", marginRight: "2px" }}>Status</SmallTextBlue>
              <SmallTextBlue>Aktion</SmallTextBlue>
            </Flex>
          </Flex>
          {waiting}

          <Flex padding="30px 30px 15px 10px" width="50%" alignItems="center">
            <Checkbox
              style={{ width: 20, height: 18, marginRight: "10px" }}
              checked={selectAllParticipants}
              onClick={handleOnSelectAll}
            />
            <Box width="240px" mr="30px">
              <SelectField
                value={action}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => setAction(event.target.value as string)}
              >
                <MenuItem value="ACTION">Aktion</MenuItem>
                <MenuItem value="DELETE">Löschen</MenuItem>
                <MenuItem value="DOWNLOAD_PICTURES">Bilder herunterladen</MenuItem>
                <MenuItem value="REASSIGN">Umbuchung</MenuItem>
              </SelectField>
            </Box>
            <Button
              buttonText="Ausführen"
              variant="dark"
              onClick={() => {
                if (combinedSelectedParticipants.length) {
                  switch (action) {
                    case "DELETE":
                      setIsDeleteParticipantsModalOpen(true);
                      break;
                    case "DOWNLOAD_PICTURES":
                      downloadParticipantPictures(combinedSelectedParticipants);
                      break;
                    case "REASSIGN":
                      setIsReassignParticipantsModalOpen(true);
                      break;
                  }
                } else {
                  toast.warning("Wählen Sie mindestens einen Teilnehmer aus.");
                }
              }}
            />
          </Flex>
        </Flex>
      </MainLayout>
    </>
  );
};

export default ManageParticipants;
