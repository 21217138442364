import axios from "axios";

import { REACT_APP_BASE_URL } from "./config";
import { setToken, getToken } from "./local-storage";

export interface AuthPostLogin {
  username: string;
  password: string;
}

const auth = {
  login: async (data: AuthPostLogin) => {
    const response = await axios.post(`${REACT_APP_BASE_URL}/benutzer/login`, {
      ...data,
    });
    setToken(response.data);
    return response;
  },
  resetPassword: async (username: string) => {
    const response = await axios.post(`${REACT_APP_BASE_URL}/benutzer/reset`, {
      username: username,
    });
    return response;
  },
  getUserDetails: async (token: string) => {
    const response = await axios.get(`${REACT_APP_BASE_URL}/benutzer`, {
      method: "GET",
      headers: { Authorization: getToken() },
    });
    return response;
  },
  verifyRedirect: async (token: string) => {
    const response = await axios.get(`${REACT_APP_BASE_URL}/benutzer/` + token);
    setToken(response.data);
    return response;
  },
};

export default auth;
