import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Events from "../../presentational/pages/user-view/events";
import { getAllEvents } from "../../store/userState/actions";
import { selectEvents, selectFilters } from "../../store/userState/selector";
import { Event } from "../../store/types/index";

interface Props {}

export interface EventProps {
  availableEvents: {
    currentPage: number;
    size: number;
    total: number;
    totalPages: number;
    content: Event[];
  };
  eventPeriod: {
    from: string | null;
    to: string | null;
  };
}

const EventsContainer: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const filter = useSelector(selectFilters);

  useEffect(() => {
    dispatch(getAllEvents());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllEvents());
  }, [filter, dispatch]);

  const eventProps: EventProps = {
    availableEvents: useSelector(selectEvents),
    eventPeriod: filter.period
  };

  return <Events {...eventProps} />;
};

export default EventsContainer;
