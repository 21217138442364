import React from "react";
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectIsAuthenticated } from "../store/adminState/selector";
import { setPreviousLocation } from "../store/globalState/actions";

const PrivateAdminRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isAuthenticated = useSelector(selectIsAuthenticated);

  if (!isAuthenticated) {
    dispatch(setPreviousLocation(location.pathname));
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateAdminRoute;
