import * as Yup from "yup";

export const REQUIRED = "Pflichtfeld";
export const VALID_EMAIL = "Bitte geben Sie eine gültige E-Mail ein.";
export const ZIP = "Bitte geben Sie eine gültige Postleitzahl an.";
export const NUMBER = "Bitte geben Sie eine gültige Zahl an.";

export default Yup.object().shape({
  descriptionValues: Yup.object().shape({
    name: Yup.string().required(REQUIRED),
    speaker: Yup.string().required(REQUIRED),
    shortDescription: Yup.string().required(REQUIRED),
    image: Yup.string().required(REQUIRED)
  }),
  eventLocationValues: Yup.object().shape({
    name: Yup.string().required(REQUIRED),
    place: Yup.string().required(REQUIRED),
    street: Yup.string().required(REQUIRED),
    zip: Yup.string()
      .matches(/^[0-9]{5}$/, ZIP)
      .required(REQUIRED),
    additionalInfo: Yup.string()
  }),
  dateDetailsValues: Yup.object().shape({
    eventDay: Yup.object().shape({
      datum: Yup.string().required(REQUIRED),
      endZeit: Yup.string()
        .test("is-not-default", REQUIRED, value => (value === "BITTE_WÄHLEN" ? false : true))
        .required(REQUIRED),
      startZeit: Yup.string()
        .test("is-not-default", REQUIRED, value => (value === "BITTE_WÄHLEN" ? false : true))
        .required(REQUIRED)
    }),
    eventType: Yup.string()
      .test("is-not-default", REQUIRED, value => (value === "BITTE_WÄHLEN" ? false : true))
      .required(REQUIRED),
    registrationDeadline: Yup.string().required(REQUIRED),
    maxNumberOfParticipants: Yup.number().typeError(NUMBER)
  })
});
