import React, { ChangeEvent } from "react";
import { useDispatch } from "react-redux";

import EventLocation from "../../../presentational/components/admin-view/create-event/event-location";
import { setLocationData } from "../../../store/adminState/create-event/actions";
import { LocationData } from "../../../store/types";
import { FormikErrors } from "formik";

interface Props {
  values: {
    name: string;
    zip: string;
    street: string;
    place: string;
    additionalInfo?: string;
  };
  onChange: (eventOrPath: string | ChangeEvent<any>) => void | ((eventOrTextValue: string | ChangeEvent<any>) => void);
  errors?: FormikErrors<LocationData>;
}

export interface EventLocationProps extends Props {
  setLocationData: (values: LocationData) => void;
}

const EventLocationContainer: React.FC<Props> = ({ values, onChange, errors }) => {
  const dispatch = useDispatch();

  const eventLocationProps: EventLocationProps = {
    values,
    onChange,
    setLocationData: (values: LocationData) => dispatch(setLocationData(values)),
    errors,
  };

  return <EventLocation {...eventLocationProps} />;
};

export default EventLocationContainer;
