import eventService from "../../../services/events";
import { AppThunk } from "../../configureStore";

import { selectEventAdditionalDays, selectNewEventValues } from "./selector";
import { createEventSlice } from "./reducer";
import {
  ProcessedImage,
  EventTypes,
  CreateEventParams,
  EventDayValues
} from "../../types";
import { toast } from "react-toastify";

import getEndOfDayTime from "../../../utils/get-end-of-day-time";

const { actions } = createEventSlice;

export const {
  setDescriptionValues,
  setDescription,
  setNewEventImage,
  setLocationData,
  setDateDetailsData,
  setNewEventDateDetailsSearchKeywords,
  addNewEventDay,
  removeEventDay,
  setNewEventsFormFields,
  syncNewEventValues,
  setCreateEventSuccessfull,
  setEventTypes,
  setNewEventsEventDay,
  setNewEventsAdditionalDays,
  setNewEventsRegistrationDeadline,
  reset
} = actions;

export const editAdditionalDays = (value: string, index: number): AppThunk => (
  dispatch,
  getState
) => {
  try {
    const currentEventDays = selectEventAdditionalDays(getState());
    let newEventDays: EventDayValues[] = [];

    currentEventDays.map((day, i) => {
      if (i === index) {
        return (newEventDays[i] = {
          datum: value,
          startZeit: day.startZeit,
          endZeit: day.endZeit
        });
      } else {
        return (newEventDays[i] = day);
      }
    });

    dispatch(setNewEventsAdditionalDays(newEventDays));
  } catch (err) {
    console.log(err);
  }
};

export const setImage = (value: ProcessedImage[]): AppThunk => dispatch => {
  try {
    if (value === undefined) {
      dispatch(
        setNewEventImage([
          { size: 0, fileName: "", type: "", base64Content: "", preview: "" }
        ])
      );
    } else {
      dispatch(setNewEventImage(value));
    }
  } catch (err) {
    return toast.error(
      "Das Bild konnte nicht hochgeladen werden, bitte versuchen Sie es erneut."
    );
  }
};

export const addEventDay = (): AppThunk => (dispatch, getState) => {
  try {
    const currentEventDays = selectEventAdditionalDays(getState());
    const newEventDays = [
      ...currentEventDays,
      { datum: "", startZeit: "BITTE_WÄHLEN", endZeit: "BITTE_WÄHLEN" }
    ];

    dispatch(addNewEventDay(newEventDays));
  } catch (err) {
    return toast.error(
      "Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut."
    );
  }
};

export const removeLastEventDay = (): AppThunk => (dispatch, getState) => {
  try {
    const currentEventDays = selectEventAdditionalDays(getState());
    const newEventDays = [...currentEventDays];
    newEventDays.pop();

    dispatch(removeEventDay(newEventDays));
  } catch (err) {
    return toast.error(
      "Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut."
    );
  }
};

export const createNewEvent = (): AppThunk => async (dispatch, getState) => {
  try {
    const newEventValues = selectNewEventValues(getState());
    const replaceTimeRegex = /\d\d:[0-9]\d:[0-9]\d(?:\.\d+)?/gm;

    const startTagStartZeit = new Date(newEventValues.veranstaltungsTag.datum)
      .toISOString()
      .replace(replaceTimeRegex, newEventValues.veranstaltungsTag.startZeit);

    const startTagEndZeit = new Date(newEventValues.veranstaltungsTag.datum)
      .toISOString()
      .replace(replaceTimeRegex, newEventValues.veranstaltungsTag.endZeit);

    const zusaetzlicheTage = [...newEventValues.zusaetzlicheTage];
    zusaetzlicheTage.map((val: EventDayValues, index: number) => {
      return (zusaetzlicheTage[index] = {
        startZeit: new Date(val.datum || "")
          .toISOString()
          .replace(replaceTimeRegex, val.startZeit || ""),
        endZeit: new Date(val.datum || "")
          .toISOString()
          .replace(replaceTimeRegex, val.endZeit || "")
      });
    });    

    const newEvent: CreateEventParams = {
      ort: {
        gebaeudename: newEventValues.ort.gebaeudename,
        strasse: newEventValues.ort.strasse,
        ortsname: newEventValues.ort.ortsname,
        postleitzahl: Number(newEventValues.ort.postleitzahl),
        zusatzinfo: newEventValues.ort.zusatzinfo,
      },
      bild: newEventValues.bild[0],
      suchbegriffe: newEventValues.suchbegriffe,
      intern: true,
      referent: newEventValues.referent,
      teilnehmerkapazitaet: Number(newEventValues.maxTeilnehmerAnzahl),
      startTagStartZeit,
      startTagEndZeit,
      formular: newEventValues.anmeldeformular ? newEventValues.formular : null,
      sichtbarkeit: "OFFLINE",
      typ: newEventValues.typ,
      anmeldeschluss: getEndOfDayTime(newEventValues.anmeldeschluss),
      zusaetzlicheTage,
      name: newEventValues.name,
      kurzbeschreibung: newEventValues.kurzbeschreibung,
      mitFotoUpload: newEventValues.mitFotoUpload
    };

    if (newEventValues.beschreibung !== "") {
      newEvent.beschreibung = newEventValues.beschreibung;
    }

    if (newEventValues.ort.zusatzinfo === "") {
      delete newEvent.ort.zusatzinfo;
    }

    await eventService.createNewEvent(newEvent);

    dispatch(reset());
    dispatch(setCreateEventSuccessfull(true));
  } catch (err) {
    return toast.error(`Ein Fehler ist aufgetreten: ${err}`);
  }
};

export const getEventTypes = (): AppThunk => async dispatch => {
  try {
    const { data } = await eventService.getEventTypes();

    return dispatch(setEventTypes(data));
  } catch (err) {
    return toast.error(
      `Beim laden der Veranstaltungstypen ist ein Fehler aufgetreten: ${err}`
    );
  }
};

export const addEventType = (
  eventType: EventTypes
): AppThunk => async dispatch => {
  try {
    await eventService.addEventType(eventType).then(() => {
      dispatch(getEventTypes());
    });
  } catch (err) {
    return toast.error(
      `Der Veranstaltungstyp konnte nicht hinzugefügt werden: ${err}`
    );
  }
};

export const deleteEventType = (
  nameOfEventType: string
): AppThunk => async dispatch => {
  try {
    await eventService.deleteEventType(nameOfEventType).then(() => {
      dispatch(getEventTypes());
    });
  } catch (err) {
    return toast.error(
      `Der Veranstaltungstyp konnte nicht gelöscht werden: ${err}`
    );
  }
};
