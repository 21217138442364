import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";

const Root = styled.div`
  .root {
    min-height: 0;
    border-radius: 2px;
  }
  .body {
    font-size: 14px;
  }
  .progress {
    height: 2px;
  }

  .Toastify__toast--info {
    background: #2e2e2e;
  }
  .Toastify__toast--success {
    background: "#66ab1a";
  }
  .Toastify__toast--warning {
    background: "#F7CD00";
  }
  .Toastify__toast--error {
    background: "#f76060";
  }
`;

const ProcessToast: React.FC = () => (
  <Root>
    <ToastContainer toastClassName="root" closeButton={false} bodyClassName="body" progressClassName="progress" />
  </Root>
);

export default ProcessToast;
