import { createSlice } from "@reduxjs/toolkit";
import { EditEventState } from "../../types/index";

const initialState: EditEventState = {
  currentId: "",
  selectedEvents: [],
  changedFormSuccessfull: false
};

export const editSlice = createSlice({
  name: "editEvent",
  initialState,
  reducers: {
    setCurrentId(state, action) {
      state.currentId = action.payload;
    },
    editSelectedEvents(state, action) {
      state.selectedEvents = action.payload;
    },
    setChangedFormSuccessfull(state, action) {
      state.changedFormSuccessfull = action.payload;
    },
    reset() {
      return initialState;
    }
  }
});

const { reducer } = editSlice;

export default reducer;
