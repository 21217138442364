import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import { MenuItem } from "@material-ui/core";
import { debounce } from "lodash";

import { getTodaysDate } from "../../../helpers/get-todays.date";
import { addMonths } from "date-fns";
import SelectField from "../elements/select";
import InputField from "../elements/input";
import DatePicker from "../elements/date-picker";
import { FilterProps } from "../../../container/user-view/filter-container";
import { EventTypes, Place } from "../../../store/types";
import { NormalText } from "../identity/styled-text";

const FilterContainer = styled(Flex)`
  width: 100%;

  @media (max-width: 799px) {
    width: 85%;
    min-width: 300px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

const FormGroupContainer = styled(Flex)`
  @media (max-width: 799px) {
    flex-direction: column;

    & > div:last-child {
      margin-top: 20px;
    }
  }
`;
const FormFieldContainer = styled(Box)`
  @media (max-width: 799px) {
    width: 100%;
  }
`;

const FilterComponent: React.FC<FilterProps> = ({
  setCategory,
  setPlace,
  setSpeaker,
  setPeriodStart,
  setPeriodEnd,
  filterValues,
  numOfAvailableEvents,
  categories,
  places
}) => {
  const [speaker, setFiltersSpeaker] = useState(filterValues.speaker);

  useEffect(() => {
    if (!filterValues.period.from) {
      setPeriodStart(new Date(getTodaysDate()).toString());
      setPeriodEnd(addMonths(new Date(getTodaysDate()), 3).toString());
    }
  }, [filterValues.period.from, setPeriodStart, setPeriodEnd]);

  const handleSpeakerChange = debounce(
    (value: string) => setSpeaker(value),
    500
  );

  const handleStartDateChange = (date: Date) => {
    setPeriodStart(date ? date.toString() : "");
  };
  const handleEndDateChange = (date: Date) => {
    setPeriodEnd(date ? date.toString() : "");
  };

  return (
    <FilterContainer
      flexDirection="column"
      padding="30px"
      margin="40px 0"
      backgroundColor="#F2F6F8"
    >
      <FormGroupContainer width="100%" justifyContent="space-between">
        <FormFieldContainer width="47.5%">
          <SelectField
            value={filterValues.categories}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
              setCategory(event.target.value as string)
            }
          >
            <MenuItem value="ALL_CATEGORIES">Alle Kategorien</MenuItem>
            {categories &&
              categories.length > 0 &&
              categories.map((type: EventTypes, index: number) => (
                <MenuItem key={index} value={`${type.veranstaltungsTyp}`}>
                  {type.veranstaltungsTyp.charAt(0).toUpperCase() +
                    type.veranstaltungsTyp.toLowerCase().slice(1)}
                </MenuItem>
              ))}
          </SelectField>
        </FormFieldContainer>
        <FormFieldContainer width="47.5%">
          <SelectField
            value={filterValues.place}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
              setPlace(event.target.value as string)
            }
          >
            <MenuItem value="ALL_PLACES">Alle Veranstaltungsorte</MenuItem>
            {places &&
              places.length > 0 &&
              places.map((place: Place, index: number) => (
                <MenuItem key={index} value={`${place.gebaeudename}`}>
                  {place.gebaeudename.charAt(0).toUpperCase() +
                    place.gebaeudename.toLowerCase().slice(1)}
                </MenuItem>
              ))}
          </SelectField>
        </FormFieldContainer>
      </FormGroupContainer>
      <Box width="100%" marginTop="20px">
        <InputField
          label="Referent"
          value={speaker}
          placeholder="Suche nach Referenten"
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setFiltersSpeaker(event.target.value as string);
            handleSpeakerChange(event.target.value as string);
          }}
        />
      </Box>
      <FormGroupContainer justifyContent="space-between" marginTop="20px">
        <FormFieldContainer width="47.5%">
          <DatePicker
            maxDate={addMonths(new Date(getTodaysDate()), 12).toString()}
            label="vom:"
            value={filterValues.period.from || new Date().toString()}
            onChange={value => handleStartDateChange(value as Date)}
            disablePast
          />
        </FormFieldContainer>
        <FormFieldContainer width="47.5%">
          <DatePicker
            maxDate={addMonths(new Date(getTodaysDate()), 12).toString()}
            label="bis:"
            value={filterValues.period.to || new Date().toString()}
            onChange={value => handleEndDateChange(value as Date)}
            disablePast
          />
        </FormFieldContainer>
      </FormGroupContainer>
      <Box marginTop="20px">
        <NormalText style={{ fontWeight: "normal" }}>
          {numOfAvailableEvents} Veranstaltung(en) gefunden
        </NormalText>
      </Box>
    </FilterContainer>
  );
};

export default FilterComponent;
