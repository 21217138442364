import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import { MenuItem } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useFormik } from "formik";
import { format, parse } from "date-fns";

import Checkbox from "../elements/checkbox";
import Button from "../elements/button";
import DatePicker from "../elements/date-picker";
import Input from "../elements/input";
import Select from "../elements/select";
import MultipleSelect from "../elements/multiple-selection";
import InputMultiLine from "../elements/input-multi-line";
import { Formular, FormValues, Participant, Field, ProcessedImage } from "../../../store/types";
import { FormField } from "../admin-view/create-event/form-preview";
import { signUpValidationSchema } from "../../../utils/signup-form-validation";
import getObjectPath from "../../../utils/get-object-path";
import { LabelText } from "../identity/styled-text";
import FileUpload from "./file-upload";
import { LEGAL } from "../../../config";
import deLocale from "date-fns/locale/de";

const StyledFlex = styled(Flex)`
  background: rgba(242, 246, 248, 0.5);
  margin-top: 50px;
  padding: 15px 30px;
`;
const FormHeadline = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: #0077b4;
`;

const NormalText = styled(Box)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;

const StyledFormControl = styled(FormControlLabel)`
  & .MuiButtonBase-root {
    width: 22px !important;
    height: 20px !important;
  }
`;

export interface SignupFormProps {
  availableSeats?: number;
  felder: Formular[];
  signupForEvent: (formValues: FormValues, eventId: string) => void;
  eventId: string;
  isRegistrable: boolean;
  inAdminView?: boolean;
  editParticipant?: boolean;
  participant?: Participant;
  editCurrentParticipant?: (participantId: string, values: FormValues) => void;
  withImageUpload?: boolean;
}

export interface CheckboxValues {
  reminderMail: boolean;
  policy: boolean;
}

const SignupFormComponent: React.FC<SignupFormProps> = ({
  availableSeats,
  felder,
  signupForEvent,
  inAdminView,
  eventId,
  isRegistrable,
  editParticipant,
  participant,
  editCurrentParticipant,
  withImageUpload,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState<FormValues>({});

  let participantFields: Field[] = Array.from(participant?.zusaetzlicheFelder || []);
  useEffect(() => {
    if (participant && participantFields.length < felder.length) {
      participantFields?.push({
        name: "Vorname",
        information: participant?.vorname || "",
      });
      participantFields?.push({
        name: "Nachname",
        information: participant?.nachname || "",
      });
      participantFields?.push({
        name: "E-Mail",
        information: participant?.email || "",
      });
    }
  }, [participant, initialValues, participantFields, felder.length]);

  useEffect(() => {
    const initValues: FormValues = {
      bild: "",
    };

    initValues.policy = false;
    initValues.reminderMail = false;

    felder.map((field: FormField) => {
      const fieldValue = participantFields.find((value: Field) => value.name === field.name);

      if (fieldValue !== undefined) {
        return (initValues[field.name] = fieldValue?.information);
      } else {
        if (field.typ === "EINZELNE_AUSWAHL") {
          return (initValues[field.name] = "BITTE_WÄHLEN");
        } else if (field.typ === "MEHRERE_AUSWAHLEN") {
          initValues[field.name] = [];

          if (field.args) {
            field.args.map((option: string, index: number) => {
              return (initValues[field.name][index] = { [option]: false });
            });
          }
        } else if (field.typ !== "EINZELNE_AUSWAHL" && field.typ !== "MEHRERE_AUSWAHLEN") {
          return (initValues[field.name] = "");
        }
      }

      return null;
    });
    if (JSON.stringify(initValues) !== JSON.stringify(initialValues)) {
      return setInitialValues(initValues);
    }
  }, [felder, initialValues, editParticipant, participantFields]);

  const onSubmit = (data: FormValues) => {
    if (data.Anrede) {
      if (data.Anrede.HERR === true) {
        data.Anrede = "HERR";
      }

      if (data.Anrede.FRAU === true) {
        data.Anrede = "FRAU";
      }

      if (data.Anrede.DIVERS === true) {
        data.Anrede = "DIVERS";
      }
    }
    if (!isSubmitting && !editParticipant) {
      signupForEvent(data, eventId);
    } else if (!isSubmitting && editParticipant && editCurrentParticipant) {
      editCurrentParticipant(participant?.anmeldungId || "", data);
    }
    setIsSubmitting(true);
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: signUpValidationSchema(felder, withImageUpload, inAdminView),
    onSubmit,
  });

  if (!inAdminView && !isRegistrable) {
    return null;
  }

  return (
    <>
      <StyledFlex flexDirection="column">
        {inAdminView ? (
          <FormHeadline style={{ marginBottom: 30 }}>Anmeldeformular</FormHeadline>
        ) : (
          <>
            <FormHeadline>
              {availableSeats! > 0 || availableSeats === undefined ? "Anmeldung" : "Warteliste"}
            </FormHeadline>
            <NormalText marginTop="15px" marginBottom="20px">
              {availableSeats! > 0 || availableSeats === undefined
                ? "Danke für Ihr Interesse an dieser Schulung von EWE NETZ GmbH. Nach der Anmeldung erhalten Sie eine Bestätigungsmail, welche Ihre Anmeldebestätigung enthält und die Sie bitte ausgedruckt zur Veranstaltung mitbringen."
                : "Danke für Ihr Interesse an dieser Schulung von EWE NETZ GmbH. Aktuell ist die Schulung leider ausgebucht. Sie haben aber die Möglichkeit, sich hier auf eine Warteliste setzen zu lassen. Wenn andere Teilnehmer ihre Anmeldung stornieren, werden die freigewordenen Plätze an Interessenten der Warteliste vergeben. Sollten Sie auf diese Weise einen Platz auf der Teilnehmerliste bekommen, informieren wir Sie per E-Mail."}
            </NormalText>
          </>
        )}
        {felder &&
          felder.map((field: FormField, index: number) => {
            switch (field.typ) {
              case "TEXTLINIE":
                return (
                  <Flex key={index} margin="5px 0" alignItems="center" justifyContent="space-between" width="100%">
                    <NormalText width="40%">
                      {field.name === "Strasse" ? "Straße" : field.name}
                      {field.pflichtfeld && "*"}
                    </NormalText>
                    <Input
                      name={field.name}
                      onChange={formik.handleChange}
                      value={formik.values[field.name] || ""}
                      onBlur={formik.handleBlur}
                      error={formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : ""}
                    />
                  </Flex>
                );
              case "NUMMER":
                return (
                  <Flex key={index} margin="5px 0" alignItems="center" justifyContent="space-between" width="100%">
                    <NormalText width="40%">
                      {field.name}
                      {field.pflichtfeld && "*"}
                    </NormalText>
                    <Input
                      name={field.name}
                      onChange={formik.handleChange}
                      value={formik.values[field.name] || ""}
                      error={formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : ""}
                    />
                  </Flex>
                );
              case "EINZELNE_AUSWAHL":
                return (
                  <Flex key={index} margin="5px 0" alignItems="center" justifyContent="space-between" width="100%">
                    <NormalText width="40%">
                      {field.name}
                      {field.pflichtfeld && "*"}
                    </NormalText>
                    <Select
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      name={field.name}
                      error={formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : ""}
                    >
                      <MenuItem value="BITTE_WÄHLEN">Bitte wählen</MenuItem>
                      {field.args &&
                        field.args.map((fieldName: string, index: number) => (
                          <MenuItem key={index} value={fieldName}>
                            {fieldName.charAt(0) + fieldName.toLowerCase().slice(1)}
                          </MenuItem>
                        ))}
                    </Select>
                  </Flex>
                );
              case "MEHRERE_AUSWAHLEN":
                return (
                  <Flex key={index} margin="5px 0" alignItems="flex-start" justifyContent="space-between" width="100%">
                    <NormalText width="40%">
                      {field.name}
                      {field.pflichtfeld && "*"}
                    </NormalText>
                    <MultipleSelect>
                      <Flex width="100%" flexWrap="wrap">
                        {field.args &&
                          field.args.map((optionName: string, index: number) => (
                            <Box key={index} width="50%" pr="10px">
                              <StyledFormControl
                                onChange={formik.handleChange}
                                control={
                                  <Checkbox
                                    style={{ width: 30, height: 28 }}
                                    checked={
                                      getObjectPath(formik.values, `${field.name}`)
                                        ? getObjectPath(formik.values, `${field.name}`)[index][optionName] === true
                                        : false
                                    }
                                    value={
                                      getObjectPath(formik.values, `${field.name}`)
                                        ? getObjectPath(formik.values, `${field.name}`)[index][optionName]
                                        : ""
                                    }
                                    onClick={() => formik.setFieldTouched(`${field.name}`)}
                                    onChange={formik.handleChange}
                                    name={`${field.name}[${index}].${optionName}`}
                                    error={
                                      formik.touched[field.name] && formik.errors[field.name]
                                        ? formik.errors[field.name]
                                        : ""
                                    }
                                  />
                                }
                                label={
                                  <LabelText style={{ marginBottom: "0px", wordBreak: "break-all" }}>
                                    {optionName}
                                  </LabelText>
                                }
                              />
                            </Box>
                          ))}
                      </Flex>
                    </MultipleSelect>
                  </Flex>
                );
              case "TEXTFELD":
                return (
                  <Flex key={index} margin="5px 0" alignItems="flex-start" justifyContent="space-between" width="100%">
                    <NormalText width="40%">
                      {field.name}
                      {field.pflichtfeld && "*"}
                    </NormalText>
                    <InputMultiLine
                      onChange={formik.handleChange}
                      name={field.name}
                      value={formik.values[field.name] || ""}
                      onBlur={formik.handleBlur}
                      error={formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : ""}
                    />
                  </Flex>
                );
              case "KONTROLLKAESTCHEN":
                return (
                  <Flex key={index} margin="5px 0" alignItems="center" justifyContent="space-between" width="100%">
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{ width: 30, height: 28 }}
                          checked={formik.values[field.name] === true}
                          error={
                            formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : ""
                          }
                          onClick={() => formik.setFieldTouched(`${field.name}`)}
                          onChange={formik.handleChange}
                          name={field.name}
                          value={formik.values[field.name] || ""}
                        />
                      }
                      label={
                        <LabelText style={{ marginBottom: "0px" }}>
                          {field.name}
                          {field.pflichtfeld && "*"}
                        </LabelText>
                      }
                    />
                  </Flex>
                );
              case "DATUM":
                let fieldDate = null;
                if (formik.values[field.name]) {
                  fieldDate = parse(formik.values[field.name], "dd.MM.yyyy", new Date(), { locale: deLocale });
                }
                return (
                  <Flex key={index} margin="5px 0" alignItems="center" justifyContent="space-between" width="100%">
                    <NormalText width="40%">
                      {field.name}
                      {field.pflichtfeld && "*"}
                    </NormalText>
                    <DatePicker
                      name={field.name}
                      readOnly
                      disableToolbar={false}
                      value={fieldDate !== null ? fieldDate?.toString() : null}
                      error={formik.touched[field.name] && formik.errors[field.name] ? formik.errors[field.name] : ""}
                      onChange={(value) => {
                        formik.setFieldValue(
                          `${field.name}`,
                          value ? format(new Date(value || ""), "dd.MM.yyyy", { locale: deLocale }) : ""
                        );
                      }}
                    />
                  </Flex>
                );
              default:
                return null;
            }
          })}
        {withImageUpload && (
          <Flex key={"_image"} margin="5px 0" alignItems="center" justifyContent="space-between" width="100%">
            <NormalText width="40%">Bild{!inAdminView && <span>&nbsp;*</span>}</NormalText>

            <FileUpload
              setImage={(image: ProcessedImage | null) => {
                formik.setFieldValue("bild", image);
              }}
              image={[{ base64Content: "", fileName: "", preview: "", size: 0, type: "" }]}
              error={formik.errors.bild}
              clearImage={() => {
                formik.setFieldValue("bild", null);
              }}
            />
          </Flex>
        )}
        <Box marginTop="5px">
          <FormControlLabel
            control={
              <Checkbox
                style={{ width: 30, height: 28 }}
                name="reminderMail"
                value={formik.values.reminderMail}
                onChange={formik.handleChange}
                error={formik.values.reminderMail}
              />
            }
            label={
              <LabelText style={{ marginBottom: "0px" }}>Erinnerungs E-Mail 5 Tage vor der Veranstaltung</LabelText>
            }
          />
        </Box>
        <Box marginTop="5px">
          <FormControlLabel
            control={
              <Checkbox
                style={{ width: 30, height: 28 }}
                checked={formik.values.policy === true}
                name="policy"
                error={formik.touched.policy && formik.errors.policy ? formik.errors.policy : ""}
                value={formik.values.policy}
                onClick={() => formik.setFieldTouched("policy")}
                onChange={formik.handleChange}
              />
            }
            label={
              <LabelText style={{ marginBottom: "0px" }}>
                Ich habe die Erklärung von EWE NETZ zum{" "}
                <a href={LEGAL.privacy} target="blank">
                  Datenschutz
                </a>{" "}
                gelesen und bin damit einverstanden.*
              </LabelText>
            }
          />
        </Box>
        <Box width="180px" alignSelf="flex-end" marginTop="25px">
          <Button
            buttonText={
              availableSeats! > 0 || availableSeats === undefined ? "ANMELDEN" : "ANMELDEN AUF DIE WARTELISTE"
            }
            variant="dark"
            isLoading={isSubmitting}
            onClick={() => formik.handleSubmit()}
            arrow
          />
        </Box>
      </StyledFlex>
    </>
  );
};
export default SignupFormComponent;
