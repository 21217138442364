import React from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import { Link as RouterLink } from "react-router-dom";

import ArrowRight from "../../assets/arrow-right.svg";
import { LEGAL } from "../../../config";

const ServiceBoxContainer = styled(Flex)`
  max-width: 350px;
  min-width: 250px;
  border: 1px solid #0077b4;
  box-sizing: border-box;
`;

const ServiceBoxHeading = styled.div`
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #0077b4;
  padding: 10px 0 10px 0;
  border-bottom: 1px solid #c8d400;
`;

const StyledRouterLink = styled(RouterLink)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: #0077b4;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 0.2s ease-out;

  &:hover {
    border-bottom: 1px solid #0077b4;
  }
`;

const Link = styled.a`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: #0077b4;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 0.2s ease-out;

  &:hover {
    border-bottom: 1px solid #0077b4;
  }
`;

const LinkArrow = styled.img`
  margin-right: 10px;
`;

const ContactText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
`;

const Phone = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #0077b4;
  margin-bottom: 20px;
`;

const Mail = styled.a`
  width: max-content;
  align-self: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-decoration: none;
  color: #0077b4;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 0.2s ease-out;

  &:hover {
    border-bottom: 1px solid #0077b4;
  }
`;

const ServiceBox: React.FC = () => {
  return (
    <ServiceBoxContainer flexDirection="column" alignItems="center">
      <Flex width="90%" flexDirection="column">
        <Box margin="20px 0">
          <ServiceBoxHeading>Service</ServiceBoxHeading>
        </Box>
        <Box>
          <LinkArrow src={ArrowRight} alt="arrow-right" />
          <Link href="https://www.ewe-netz.de/vertragspartner/service/ausschreibungskalender">
            Ausschreibungskalender
          </Link>
        </Box>
        <Box>
          <LinkArrow src={ArrowRight} alt="arrow-right" />
          <Link href="https://www.ewe-netz.de/vertragspartner/service/material">Material</Link>
        </Box>
        <Box>
          <LinkArrow src={ArrowRight} alt="arrow-right" />
          <Link href="https://www.ewe-netz.de/vertragspartner/mehr/leistungsverzeichnisse">Leistungsverzeichnisse</Link>
        </Box>
        <Box>
          <LinkArrow src={ArrowRight} alt="arrow-right" />
          <Link href="https://www.ewe-netz.de/vertragspartner/service/handbuecher">Handbücher</Link>
        </Box>
        <Box>
          <LinkArrow src={ArrowRight} alt="arrow-right" />
          <StyledRouterLink to={{ pathname: "/" }}>Seminare / Schulungen</StyledRouterLink>
        </Box>
        <Box>
          <LinkArrow src={ArrowRight} alt="arrow-right" />
          <Link href={LEGAL.privacy}>Datenschutz</Link>
        </Box>
        <Box>
          <LinkArrow src={ArrowRight} alt="arrow-right" />
          <Link href="https://www.ewe-netz.de/vertragspartner/service/hse-arbeitssicherheit">
            HSE / Arbeitssicherheit
          </Link>
        </Box>
        <Box>
          <LinkArrow src={ArrowRight} alt="arrow-right" />
          <Link href="https://www.ewe-netz.de/vertragspartner/service/glossar">Glossar</Link>
        </Box>
        <Flex flexDirection="column" backgroundColor="#F2F6F8" padding="20px" margin="25px 0">
          <Box marginBottom="10px">
            <ServiceBoxHeading>Kontakt</ServiceBoxHeading>
          </Box>
          <ContactText>Bei technischen Fragen und IT-Problemen wählen Sie den kostenlosen Kontakt:</ContactText>
          <Phone>T 0441 4808 5544</Phone>
          <Mail href="mailto:benutzerservice@ewe.de">E-Mail: benutzerservice@ewe.de</Mail>
        </Flex>
      </Flex>
    </ServiceBoxContainer>
  );
};

export default ServiceBox;
