import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { Flex } from "@rebass/grid";
import { FormikErrors } from "formik";

import { ErrorText, LabelText } from "../identity/styled-text";

const StyledTextField = withStyles(() => ({
  root: {
    width: "100%",
    border: "1px solid rgba(0, 119, 180, 0.8)",
    borderRadius: 4,
    background: "white",
    padding: "5px 20px",
    height: "130px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#0077B4",
    "& .MuiInputBase-root": {
      height: "100%",
      "& > textarea": {
        height: "100% !important",
        color: "#0077B4",
        fontSize: "16px",
        fontStyle: "normal",
        fontFamily: "Open Sans",
        fontWeight: "normal",
        lineHeight: "22px",
      },
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
  },
}))(TextField);

export interface InputProps {
  label?: string;
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  placeholder?: string;
  name?: string;
  value?: string;
  onBlur?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  readOnly?: boolean;
}

const InputMultiLine: React.FC<InputProps> = ({
  label,
  onChange,
  name,
  value,
  onBlur,
  readOnly,
  placeholder,
  error,
}) => {
  return (
    <Flex width="100%" flexDirection="row">
      {label && <LabelText>{label}</LabelText>}
      <StyledTextField
        onChange={onChange}
        name={name}
        value={value}
        onBlur={onBlur}
        multiline
        maxRows={6}
        placeholder={placeholder}
        inputProps={{ readOnly }}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </Flex>
  );
};

export default InputMultiLine;
