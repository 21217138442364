import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { FormikErrors } from "formik";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& > span": {
      height: "100%",
    },
  },
  icon: {
    borderRadius: 4,
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: "100%",
      height: "100%",
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
});

export interface CheckboxProps {
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  name?: string;
  value?: boolean;
  checked?: boolean;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  readOnly?: boolean;
  onClick?: (event: React.MouseEvent<{ value: unknown }>) => void;
  style?: any;
}

const StyledCheckbox: React.FC<CheckboxProps> = ({
  onChange,
  error,
  value,
  name,
  readOnly,
  style,
  checked,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Checkbox
      onClick={onClick}
      className={classes.root}
      color="default"
      onChange={onChange}
      name={name}
      readOnly={readOnly}
      checked={checked}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      value={value}
      icon={
        <span
          className={classes.icon}
          style={{ border: error ? "1px solid red" : "1px solid rgba(0, 119, 180, 0.8)" }}
        />
      }
      inputProps={{ "aria-label": "decorative checkbox" }}
      style={style}
    />
  );
};

export default StyledCheckbox;
