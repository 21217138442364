import React from "react";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { PersistGate } from "redux-persist/integration/react";
import { isIE } from "react-device-detect";
import styled from "styled-components";
import { Flex } from "@rebass/grid";
import { useIdleTimer } from "react-idle-timer";
import { toast } from "react-toastify";

import store, { persistor } from "./store/configureStore";
import Events from "./container/user-view/page-events";
import EventOverview from "./container/admin-view/page-event-overview-container";
import Cancellation from "./container/user-view/cancellation-container";
import EventDetailsContainer from "./container/user-view/page-event-details";
import CreateEvent from "./container/admin-view/page-create-event-container";
import ManageParticipants from "./container/admin-view/page-manage-participants";
import AddParticipant from "./container/admin-view/page-add-participant";
import EditRegistrationForm from "./container/admin-view/page-edit-registration-form";
import Login from "./container/admin-view/login";
import ScrollToTop from "./presentational/components/elements/scroll-to-top";
import "./App.css";
import PrivateAdminRoute from "./utils/private-admin-route";
import PrivateUserRoute from "./utils/private-user-route";
import RedirectLogin from "./container/user-view/redirect-login";
import ProcessToast from "./presentational/components/process-toast";
import { REDIRECT_URL } from "./config";
import NotFound from "./presentational/pages/user-view/not-found";
import { clearToken } from "./services/local-storage";
import rootReducer from "./store/configureStore";
import { logout } from "./store/adminState/actions";
import { removeUserToken } from "./store/userState/actions";

export const history = createBrowserHistory();

export const BrowserMessageContainer = styled(Flex)`
  height: 100%;
  padding: 50px;
`;
export const BrowserMessage = styled.div`
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
`;

const App: React.FC = () => {
  // logout automatically after 2 hours when user is logged in
  useIdleTimer({
    timeout: 1000 * 60 * 60 * 2,
    onIdle: () => {
      if (history.location.pathname !== "/login") {
        toast.error("Sie wurden automatisch ausgeloggt. Alle nicht gespeicherten Änderungen sind verloren.", {
          autoClose: false,
          closeButton: true,
        });
        clearToken();
        rootReducer.dispatch(logout());
        rootReducer.dispatch(removeUserToken());
      }
    },
    debounce: 500,
  });

  return (
    <>
      <ProcessToast />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {isIE ? (
            <BrowserMessageContainer alignItems="center" justifyContent="center">
              <BrowserMessage>
                Ihr eingesetzter Browser ist veraltet und wird nicht mehr unterstützt. Bitte verwenden Sie einen
                aktuelleren Browser
              </BrowserMessage>
            </BrowserMessageContainer>
          ) : (
            <Router history={history}>
              <ScrollToTop />
              <Switch>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/token/:token">
                  <RedirectLogin />
                </Route>
                <Route
                  path="/vpw"
                  component={() => {
                    window.location.href = REDIRECT_URL;
                    return null;
                  }}
                />
                <PrivateUserRoute exact path="/">
                  <Events />
                </PrivateUserRoute>
                <PrivateUserRoute path="/events/:id">
                  <EventDetailsContainer />
                </PrivateUserRoute>
                <PrivateUserRoute path="/cancellation/:eventId/:signupId">
                  <Cancellation />
                </PrivateUserRoute>
                <PrivateAdminRoute exact path="/event-overview">
                  <EventOverview />
                </PrivateAdminRoute>
                <PrivateAdminRoute exact path="/create-event">
                  <CreateEvent />
                </PrivateAdminRoute>
                <PrivateAdminRoute exact path="/:id/edit-registration-form">
                  <EditRegistrationForm />
                </PrivateAdminRoute>
                <PrivateAdminRoute exact path="/:id/edit-event">
                  <CreateEvent editEvent />
                </PrivateAdminRoute>
                <PrivateAdminRoute exact path="/:eventId/manage-participants">
                  <ManageParticipants />
                </PrivateAdminRoute>
                <PrivateAdminRoute exact path="/:id/:participantId/edit-participant">
                  <AddParticipant editParticipant />
                </PrivateAdminRoute>
                <PrivateAdminRoute exact path="/:id/manage-participants/add-paricipant">
                  <AddParticipant />
                </PrivateAdminRoute>
                <Route component={NotFound} />
              </Switch>
            </Router>
          )}
        </PersistGate>
      </Provider>
    </>
  );
};

export default App;
