import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Select, InputBase } from "@material-ui/core";
import { Flex } from "@rebass/grid";

import SelectArrow from "../identity/select-arrow";
import { FormikErrors } from "formik";
import { ErrorText, LabelText } from "../identity/styled-text";

const StyledInput = withStyles(() => ({
  root: {
    width: "100%",
    "& .MuiSelect-select:focus": {
      borderRadius: 4,
      background: "white"
    },
    "& .Mui-error": {
      border: "1px solid rgb(247,96,96)",
      color: "green"
    },
    borderRadius: 4
  },
  input: {
    borderRadius: 4,
    background: "white",
    padding: "0 20px",
    height: "46px",
    display: "flex",
    alignItems: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#0077B4"
  }
}))(InputBase);

export interface SelectProps {
  label?: string;
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onBlur?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  children?: any;
  value?: string;
  name?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  readOnly?: boolean;
}

const SelectField: React.FC<SelectProps> = ({ label, onChange, children, value, name, onBlur, error, readOnly }) => {
  return (
    <Flex width="100%" flexDirection="column">
      {label && <LabelText>{label}</LabelText>}
      <Select
        value={value ? value : "BITTE_WÄHLEN"}
        inputProps={{ readOnly }}
        name={name}
        error={!!error}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "200px"
            }
          }
        }}
        onBlur={onBlur}
        onChange={onChange}
        input={<StyledInput style={{ border: error ? "1px solid red" : "1px solid rgba(0, 119, 180, 0.8)" }} />}
        IconComponent={SelectArrow}
      >
        {children}
      </Select>
      {error && <ErrorText>{error}</ErrorText>}
    </Flex>
  );
};

export default SelectField;
