import { AppState } from "../configureStore";
import { createSelector } from "@reduxjs/toolkit";
import { Event } from "../types";

export const selectEvents = (state: AppState) => state?.adminState?.events?.availableEvents;
export const selectFilters = (state: AppState) => state?.adminState?.events?.filters;
export const selectPage = (state: AppState) => state?.adminState?.events?.availableEvents.currentPage;
export const selectPageSize = (state: AppState) => state?.adminState?.events?.availableEvents.size;
export const selectFiltersName = (state: AppState) => state?.adminState?.events?.filters?.name;
export const selectFiltersZip = (state: AppState) => state?.adminState?.events?.filters?.zip;
export const selectFiltersType = (state: AppState) => state?.adminState?.events?.filters?.type;
export const selectFiltersCity = (state: AppState) => state?.adminState?.events?.filters?.city;
export const selectFiltersSearchKeyword = (state: AppState) => state?.adminState?.events?.filters?.searchKeywords;
export const selectFilterValues = (state: AppState) => state?.adminState?.events?.filters;
export const selectFirstName = (state: AppState) => state?.adminState?.events?.firstName;
export const selectLastName = (state: AppState) => state?.adminState?.events?.lastName;
export const selectRole = (state: AppState) => state?.adminState?.events?.role;
export const selectLoading = (state: AppState) => state?.adminState?.events?.loading;
export const selectIsReset = (state: AppState) => state?.adminState?.events?.isReset;
export const viewOnlyArchived = (state: AppState) => state?.adminState?.events?.viewOnlyArchived;

export const selectEventById = (state: AppState, eventId: string) => {
  const events = state?.adminState?.events?.availableEvents;
  return events.content.find((event: Event) => event.veranstaltungId === eventId);
};

export const selectToken = (state: AppState) => state.adminState?.events?.token;
export const selectIsAuthenticated = createSelector(selectToken, (token) => {
  return token ? true : false;
});
